import { IconType } from 'react-icons';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import { MultipleChoiceImage } from '../../positionManagement/PositionType';
import { v4 as uuidv4 } from 'uuid';
import { FormBuilderSchemas } from '../formBuilderSchemas/formBuilder.schema';

export const languages = ['en', 'fr'] as const;
export type Language = typeof languages[number];

export const MAX_FOLLOW_UP_DEPTH = 1;

export enum formBuilderType {
  // basic elements
  text = 'text',
  info = 'info',
  number = 'number',
  date = 'date',
  time = 'time',
  email = 'email',
  address = 'address',
  phone = 'phone',
  yesNo = 'yesNo',
  checkbox = 'checkbox',
  radio = 'radio',
  image = 'image',
  file = 'file',
  link = 'link',
  button = 'button',
  singleCheckbox = 'singleCheckbox',
  gender = 'gender',
  documentReview = 'documentReview',
  bankAccountNumber = 'bankAccountNumber',
  routingNumber = 'routingNumber',
  branchTransitNumber = 'branchTransitNumber',
  financialInstitutionNumber = 'financialInstitutionNumber',
  checkboxDisable = 'checkboxDisable',
  correspondenceLanguage = 'correspondenceLanguage',
  sin = 'sin',
  ssn = 'ssn',
  signature = 'signature',
  // page elements
  section = 'section',
  // advanced elements
  sinAdvanced = 'sinAdvanced',
  ssnAdvanced = 'ssnAdvanced',
  documentPreview = 'documentPreview',
  americanBank = 'americanBank',
  canadianBank = 'canadianBank',
  earliestStartDate = 'earliestStartDate',
  addressValidator = 'addressValidator',
  // confirmation = 'confirmation',
  signatureAdvanced = 'signatureAdvanced',
  emergencyContact = 'emergencyContact',
  miscellaneous = 'miscellaneous',
}

export type FormBuilderTypes = keyof typeof formBuilderType;
export type BasicFormBuilderTypes = Extract<
  FormBuilderTypes,
  | 'text'
  | 'info'
  | 'number'
  | 'date'
  | 'time'
  | 'email'
  | 'address'
  | 'phone'
  | 'yesNo'
  | 'checkbox'
  | 'radio'
  | 'image'
  | 'file'
  | 'link'
  | 'documentReview'
  | 'singleCheckbox'
  | 'button'
  | 'gender'
  | 'bankAccountNumber'
  | 'routingNumber'
  | 'branchTransitNumber'
  | 'financialInstitutionNumber'
  | 'signature'
  // custom elements
  | 'checkboxDisable'
  | 'correspondenceLanguage'
  | 'sin'
  | 'ssn'
>;

export enum Sections {
  canadianBank = 'canadianBank',
  americanBank = 'americanBank',
  emergencyContact = 'emergencyContact',
  documentPreview = 'documentPreview',
  miscellaneous = 'miscellaneous',
  sin = 'sin',
  ssn = 'ssn',
  signature = 'signature',
}

export type AdvancedFormBuilderTypes = Extract<
  FormBuilderTypes,
  | 'sinAdvanced'
  | 'ssnAdvanced'
  | 'documentPreview'
  | 'canadianBank'
  | 'americanBank'
  | 'earliestStartDate'
  | 'addressValidator'
  // | 'confirmation'
  | 'signatureAdvanced'
  | 'emergencyContact'
  | 'miscellaneous'
>;

export type LabelSchema = (Required<Pick<Record<Language, string>, 'en'>> | Required<Pick<Record<Language, string>, 'fr'>>) &
  Partial<Record<Language, string>>;
export interface OptionSchema {
  key: string;
  id: string;
  text: LabelSchema;
  isSelected?: boolean;
  tag?: string | null;
}

export interface DocumentReview {
  description: LabelSchema;
  url: LabelSchema;
  acknowledgeContent: LabelSchema;
  confirmText: LabelSchema;
}
export interface FieldSchema {
  timestamp: number;
  id: string;
  inputType: FormBuilderTypes;
  order: number;
  section?: string;
  label?: LabelSchema;
  required?: boolean;
  text?: LabelSchema;
  validations?: Record<string, any>;
  optionList?: OptionSchema[] | null;
  isFollowUp: boolean;
  followUpQuestionList?: FieldSchema[] | null;
  hintText?: Partial<Record<Language, string>>;
  parent?: string | null;
  optionId?: string | null;
  isInfo?: boolean;
  link?: LabelSchema | null;
  image?: MultipleChoiceImage;
  previewPlaceholders?: { placeholder: string; disableMinMax?: boolean };
  field: string;
  documentReview?: DocumentReview | null;
  disabled?: boolean;
  disabledId?: string;
  isFrontEndOnly?: boolean;
  hideFieldSelector?: boolean;
  min?: number | null;
  max?: number | null;
  enableWorkPermit?: boolean | null;
}

export interface SchemaBuilderProps {
  defaultValue: FieldSchema;
  onChange: (schema: FieldSchema) => void;
  language: Language;
  path: (string | number)[];
  sectionIndex: number;
  fieldIndex: number;
  followUpDragOver?: () => void;
  isAdvanced?: boolean;
  followUpLabel: number;
  labelRightRenderer?: React.ReactNode;
}

export type ComponentPanels = 'basic' | 'advanced' | 'tags' | 'page-element';
export interface FormBuilderSchema {
  type: FormBuilderTypes;
  component: (({ defaultValue, onChange }: SchemaBuilderProps) => JSX.Element) | null;
  props: any;
  defaultValue: FieldSchema | FieldSchema[];
  icon: IconType;
  label: string;
  panel: ComponentPanels;
  section?: string;
  hidden?: boolean;
}

export interface PageElementSchema {
  key: string;
  text: { en: string; fr: string };
  icon: IconType;
  label: string;
}

export interface HrFromTemplate {
  id: string;
  account: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface HrFormTemplateGetAllResponse {
  after: string | null;
  before: string | null;
  hasNext: boolean;
  hasPrevious: boolean;
  limit: number | null;
  templates: HrFromTemplate[];
}

export interface HrFromCreateTemplateResponse {
  data: {
    templateId: string;
  };
  message: string;
}

export interface HrFormUpdateTemplateResponse {
  data: {
    templateId: string;
  };
  message: string;
}
export interface SectionSchema {
  id: string;
  section: string;
  fields: FieldSchema[];
  isAdvanced?: boolean;
}

export function generateFormSchema(sectionSchema: SectionSchema[], language: Language, forValidation = false) {
  const schemas = [...sectionSchema];
  const questionsOmitKeys = [
    'timestamp',
    'hintText',
    'validations',
    'required',
    'previewPlaceholders',
    'disabledId',
    'disabled',
    'isFrontEndOnly',
    'hideFieldSelector',
  ];

  const followUpOmitKeys = [...questionsOmitKeys, 'documentReview', 'enableWorkPermit', 'section'];

  function processOptionList(options?: OptionSchema[]): Omit<OptionSchema, 'key'>[] | null {
    if (!options || options.length === 0) return null;

    return options.map((option) => omit(option, ['key']) as Omit<OptionSchema, 'key'>);
    // .filter((option) => {
    //   const text = option.text;
    //   if (!text) return false; // Remove if `text` is not defined
    //   return !!text.en?.trim() || !!text.fr?.trim(); // Keep if either `en` or `fr` is non-empty
    // });
  }

  function processFollowUpQuestions(questions: FieldSchema[] | undefined, sectionName: string): any[] | null {
    if (!questions || questions.length === 0) return null;
    return questions
      .filter((question) => !(question?.disabled || (question?.isFrontEndOnly && !forValidation)))
      .map((question) => {
        // Recursively handle both follow-up questions and nested option lists
        return {
          ...omit(question, followUpOmitKeys),
          field: question?.field ?? question.inputType,
          isInfo: question?.isInfo ?? false,
          optionList: processOptionList(question?.optionList ?? []), // Handle optionList for follow-up
          followUpQuestionList: processFollowUpQuestions(question?.followUpQuestionList ?? [], sectionName), // Recursive call for nested follow-ups
          section: sectionName,
        };
      });
  }

  return schemas
    .map((section) => {
      return section.fields
        ?.filter((item) => !(item?.disabled || (item?.isFrontEndOnly && !forValidation)))
        ?.map((field) => {
          const newField = {
            ...field,
            field: field?.field ?? field.inputType,
            optionList: processOptionList(field?.optionList ?? []),
            followUpQuestionList: processFollowUpQuestions(field?.followUpQuestionList ?? [], section.section),
          };
          return {
            ...omit(newField, questionsOmitKeys),
            section: section.section,
          };
        });
    })
    .flat()
    .map((item, index) => {
      const temp = { ...omitBy(item, isNil) };
      return {
        ...temp,
        order: index + 1,
      } as FieldSchema;
    });
}

export function mapFormSchema({
  currentTemplate,
  formBuilderSchemas,
}: {
  currentTemplate: FieldSchema[];
  formBuilderSchemas: FormBuilderSchemas;
}) {
  const grouped = currentTemplate
    .reduce<SectionSchema[]>((acc, field, index) => {
      const sectionName = field.section ?? `section${index + 1}`;
      const isAdvancedSection = Object.values(Sections).includes(sectionName as Sections);
      const existingSection = acc.find((group) => group.section === sectionName);
      const defaultField = formBuilderSchemas[field.inputType].defaultValue;
      const newField = {
        ...defaultField,
        ...field,
        optionList: field?.optionList?.map((option, index) => ({
          ...option,
          key: field.inputType === 'yesNo' ? option.text.en! : String.fromCharCode(65 + index).toUpperCase(),
        })),
      };
      if (existingSection) {
        existingSection.fields.push(newField);
      } else {
        acc.push({ section: sectionName, isAdvanced: isAdvancedSection, fields: [newField], id: uuidv4() });
      }

      return acc;
    }, [])
    ?.map((section) => {
      const sectionName = section.section;
      const isCanadianBank = sectionName === Sections.canadianBank;
      const isAmericanBank = sectionName === Sections.americanBank;
      const isSin = sectionName === Sections.sin;
      if (isCanadianBank || isAmericanBank || isSin) {
        const fields = [...section.fields];
        const checkIndex = isCanadianBank ? 4 : isAmericanBank ? 3 : 1;
        const hasBankVoidCheque = section.fields.length > checkIndex;
        const selectedField = isCanadianBank ? Sections.canadianBank : isAmericanBank ? Sections.americanBank : 'sinAdvanced';

        const defaultFields = formBuilderSchemas[selectedField].defaultValue as FieldSchema[];

        defaultFields.forEach((field, index) => {
          if (index === checkIndex) {
            fields.splice(checkIndex, 0, { ...field });
          } else if (index > checkIndex) {
            fields.splice(index, 1, {
              ...(hasBankVoidCheque ? fields[index] : field),
              disabledId: field.disabledId,
              disabled: !hasBankVoidCheque,
            });
          }
        });
        return { ...section, fields: fields };
      }
      return section;
    });
  return grouped;
}

export function addDataTagToSpans(html: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  // Find all span elements in the document
  const spans = doc.querySelectorAll('span');

  // Loop through each span and add data-tag="true" if the content matches the pattern [any]
  spans.forEach((span) => {
    if (/\[.*\]/.test(span.textContent || '')) {
      span.setAttribute('data-tag', 'true');
    }
  });

  // Return the updated HTML as a string
  return doc.body.innerHTML;
}

export const mapField = (field: FieldSchema) => {
  // if (!field?.optionList?.length) return field;
  const optionIdMap = new Map(field?.optionList?.map((option) => [option.id, uuidv4()]));
  const newOptions = field?.optionList?.length
    ? [...field?.optionList!]?.map((option) => ({
        ...option,
        id: optionIdMap.get(option.id)!,
      }))
    : null;
  const newFollowUpQuestionList = field?.followUpQuestionList?.length
    ? field?.followUpQuestionList?.map((question) => {
        return {
          ...question,
          id: uuidv4(),
          optionId: optionIdMap.get(question.optionId!)!,
          timestamp: Date.now(),
        };
      })
    : null;
  const newField = {
    ...field,
    timestamp: Date.now(),
    id: uuidv4(),
    optionList: newOptions,
    followUpQuestionList: newFollowUpQuestionList,
  };
  return newField;
};
