import React from 'react';
import { Stack, Text } from '@chakra-ui/core';
import theme from '../../../../styles/customTheme';
import FormElementTextBuilder from './FormElementTextBuilder';
import { SchemaBuilderProps } from './formBuilderSchema';

export default function SignatureSchemaBuilder({ ...props }: SchemaBuilderProps) {
  return (
    <Stack>
      {/* <LabelSchemaBuilder {...props} /> */}
      <FormElementTextBuilder {...props} />
      <Stack
        align="center"
        justify="center"
        borderRadius={theme.radii.md}
        border="1px solid #A8B1E3"
        backgroundColor="#F3F6F9"
        height="10rem"
      >
        <Text textAlign="center" fontSize="sm" fontWeight="bold">
          Sign here
        </Text>
        <SignatureIcon />
      </Stack>
    </Stack>
  );
}

export function SignatureIcon() {
  return (
    <svg width="210" height="36" viewBox="0 0 210 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.25 31.3819C15.75 31.2153 46.45 25.8819 53.25 5.88195C54.0833 4.21528 53.45 1.88195 44.25 5.88195C32.75 10.8819 33.75 31.8818 51.25 34.3818C65.25 36.3818 73.75 25.2152 76.25 19.3818C76.25 22.5485 77.75 28.5818 83.75 27.3818C89.75 26.1818 92.25 21.5485 92.75 19.3818C92.75 22.7152 94.85 28.3818 103.25 24.3818L108.75 17.8818C110.083 20.5485 114.05 25.8818 119.25 25.8818C124.45 25.8818 127.083 23.5485 127.75 22.3818C130.917 24.0485 138.15 27.0818 141.75 25.8818C146.25 24.3818 144.75 22.8818 149.75 24.3818C154.75 25.8818 154.25 29.3818 166.75 28.3818C176.75 27.5818 186.25 24.0485 189.75 22.3818"
        stroke="#A0AEC0"
        strokeLinecap="round"
      />
      <mask id="mask0_0_1" maskUnits="userSpaceOnUse" x="185" y="0" width="25" height="25">
        <rect x="185.75" y="0.381836" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_0_1)">
        <path
          d="M198.225 17.2068L206.6 8.83184L205.3 7.53184L196.925 15.9068L198.225 17.2068ZM191.7 19.3818C190.033 19.2985 188.792 18.9485 187.975 18.3318C187.158 17.7152 186.75 16.8235 186.75 15.6568C186.75 14.5735 187.196 13.6943 188.087 13.0193C188.979 12.3443 190.217 11.9402 191.8 11.8068C192.45 11.7568 192.938 11.6527 193.263 11.4943C193.588 11.336 193.75 11.1152 193.75 10.8318C193.75 10.3985 193.504 10.0735 193.013 9.85684C192.521 9.64017 191.708 9.48184 190.575 9.38184L190.75 7.38184C192.467 7.51517 193.729 7.861 194.537 8.41934C195.346 8.97767 195.75 9.78184 195.75 10.8318C195.75 11.7152 195.429 12.4068 194.788 12.9068C194.146 13.4068 193.2 13.7068 191.95 13.8068C190.883 13.8902 190.083 14.086 189.55 14.3943C189.017 14.7027 188.75 15.1235 188.75 15.6568C188.75 16.2402 188.983 16.661 189.45 16.9193C189.917 17.1777 190.7 17.3318 191.8 17.3818L191.7 19.3818ZM198.7 19.5568L194.575 15.4318L204.125 5.88184C204.458 5.5485 204.854 5.38184 205.312 5.38184C205.771 5.38184 206.167 5.5485 206.5 5.88184L208.25 7.63184C208.583 7.96517 208.75 8.361 208.75 8.81934C208.75 9.27767 208.583 9.6735 208.25 10.0068L198.7 19.5568ZM194.725 20.3818C194.442 20.4485 194.192 20.3735 193.975 20.1568C193.758 19.9402 193.683 19.6902 193.75 19.4068L194.575 15.4318L198.7 19.5568L194.725 20.3818Z"
          fill="#A0AEC0"
        />
      </g>
    </svg>
  );
}
