import { Box, HStack } from '@chakra-ui/core';
import React from 'react';
import { formBuilderType, SectionSchema } from '../formBuilderSchema';
import SectionDelete from './SectionDelete';
import SectionNameInput from './SectionNameInput';
import { useHireFormContext } from '../../HireForm.context';
import { SortableHandle } from 'react-sortable-hoc';
import theme from '../../../../../styles/customTheme';
import { MdDragIndicator } from 'react-icons/md';
import isEqual from 'lodash/isEqual';

interface SectionActionsProps {
  sectionIndex: number;
  section: SectionSchema;
  isAdvancedSection: boolean;
}

const SectionActions = React.memo(
  function ({ section, sectionIndex, isAdvancedSection }: SectionActionsProps) {
    const { setFormSchema, findIndexById } = useHireFormContext();

    const fieldsWithouSection = section.fields.filter((field) => field.section !== section.section);
    const fieldIndexWithoutSection = fieldsWithouSection.map((field) => findIndexById(field.id))?.[0];

    const SortableAnchor = SortableHandle(() => (
      <Box
        borderRadius="50%"
        cursor="grab"
        color={theme.colors.gray[300]}
        style={{ aspectRatio: '1/1' }}
        padding={1}
        size="sm"
        _groupHover={{ color: theme.colors.gray[500] }}
        data-index={sectionIndex}
      >
        <MdDragIndicator />
      </Box>
    ));

    return (
      <Box width="100%">
        <HStack justify="space-between">
          <SectionNameInput
            name={section.section}
            path={['questions', fieldIndexWithoutSection, 'section']}
            {...{ sectionIndex, setFormSchema, isAdvancedSection }}
          />
          <HStack>
            <SectionDelete sectionIndex={sectionIndex} />
            {section?.fields.some((field) => field.inputType !== formBuilderType.signature) && <SortableAnchor />}
          </HStack>
        </HStack>
      </Box>
    );
  },
  (prev, next) =>
    prev.sectionIndex === next.sectionIndex ||
    prev.isAdvancedSection === next.isAdvancedSection ||
    isEqual(prev.section, next.section),
);

export default SectionActions;
