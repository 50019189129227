import { EasyPeasyConfig, Store } from 'easy-peasy';
import appModel, { AppStoreModel } from '../app/models/appModel';
import { topBarNotificationStoreModel, TopBarNotificationStoreModel } from '../app/topBar/notification/models/topBarNotification';
import { remoteConfigModel, RemoteConfigStoreModel } from '../firebase/remoteConfig';
import httpModel, { HttpStoreModel } from '../http/models/httpModel';
import { administrationStoreModel, AdministrationStoreModel } from '../modules/administration/models/administrationModel';
import {
  businessManagementStoreModel,
  BusinessManagementStoreModel,
} from '../modules/administration/models/businessManagmentModel';
import {
  positionManagementStoreModel,
  PositionManagementStoreModel,
} from '../modules/administration/models/positionManagmentModel';
import {
  RecruiterManagementStoreModel,
  recruiterManagementStoreModel,
} from '../modules/administration/models/recruiterManagementModel';
import { VideoManagementStoreModel, videoManagementStoreModel } from '../modules/administration/models/videoManagementModel';
import authModel, { AuthStoreModel } from '../modules/auth/models/authModel';
import { calendarStoreModel, CalendarStoreModel } from '../modules/calendar/models/calendarModel';
import {
  inviteCollaboratorsStoreModel,
  InviteCollaboratorsStoreModel,
} from '../modules/calendar/models/InviteCollaboratorsModal';
import { emailInvitationStoreModel, EmailInvitationStoreModel } from '../modules/emailInvitation/models/emailInvitationModel';
import { meetStoreModel, MeetStoreModel } from '../modules/meet/models/meetModel';
import { accountStoreModel, AccountStoreModel } from '../modules/messaging/models/accountModel';
import { candidateModel, CandidateStoreModel } from '../modules/messaging/models/candidateModel';
import { messagingModel, MessagingStoreModel } from '../modules/messaging/models/messagingModel';
import { userStoreModel, UserStoreModel } from '../modules/messaging/models/userModel';
import { ProfileManagementStoreModel, profileManagementStoreModel } from '../modules/profile/models/profileManagmentModel';
import { AccountLevelConfigurationStoreModel, accountLevelConfigurationStoreModel } from './accountLevelConfigurationModel';
import { interviewStoreModel, InterViewStoreModel } from './interviewModel';
import { modernHireManagementStoreModel, ModernHireStoreModel } from './modernHireModel';
import { notificationsModel, NotificationsStoreModel } from './notificationsModel';
import { offerAndHireProcessStoreModel, OfferAndHireProcessStoreModel } from './offerAndHireProcessModel';
import { ToDoListStoreModel, toDoStoreModel } from './toDoListModel';
import { RequisitionStoreModel, requisitionModel } from './requisitionModel';
import { ManagementOverviewStoreModel, managementOverviewModel } from './managementOverviewModel';
import { unsubscribeManagementStoreModel, UnSubscribeModelManagementStoreModel } from './unSubscribeModel';
import { productStoreModel, ProductStoreModel } from '../modules/tokens/models/productModel';
import { imageGalleryStoreModel, ImageGalleryStoreModel } from '../modules/administration/models/imageGalleryModel';
import { hrFormTemplateStoreModel, HrFormTemplateStoreModel } from '../modules/administration/models/HRFomTemplateModel';
import {
  languageEvaluationModel,
  LanguageEvaluationStoreModel,
} from '../modules/administration/languageEvaluation/models/languageEvaluationModel';

export type AppStore = Store<StoreModel, EasyPeasyConfig<Record<string, unknown>>>;

export type StoreModel = AuthStoreModel &
  AppStoreModel &
  HttpStoreModel &
  MessagingStoreModel &
  RemoteConfigStoreModel &
  NotificationsStoreModel &
  AccountStoreModel &
  UserStoreModel &
  CalendarStoreModel &
  MeetStoreModel &
  InterViewStoreModel &
  CandidateStoreModel &
  EmailInvitationStoreModel &
  AdministrationStoreModel &
  PositionManagementStoreModel &
  VideoManagementStoreModel &
  BusinessManagementStoreModel &
  RecruiterManagementStoreModel &
  TopBarNotificationStoreModel &
  ProfileManagementStoreModel &
  UnSubscribeModelManagementStoreModel &
  ModernHireStoreModel &
  InviteCollaboratorsStoreModel &
  AccountLevelConfigurationStoreModel &
  ToDoListStoreModel &
  RequisitionStoreModel &
  ManagementOverviewStoreModel &
  OfferAndHireProcessStoreModel &
  ProductStoreModel &
  ImageGalleryStoreModel &
  HrFormTemplateStoreModel &
  LanguageEvaluationStoreModel;

const storeModel: StoreModel = {
  remoteConfig: remoteConfigModel,
  http: httpModel,
  auth: authModel,
  app: appModel,
  messaging: messagingModel,
  notifications: notificationsModel,
  account: accountStoreModel,
  user: userStoreModel,
  calendar: calendarStoreModel,
  meet: meetStoreModel,
  interview: interviewStoreModel,
  candidate: candidateModel,
  emailInvitation: emailInvitationStoreModel,
  administration: administrationStoreModel,
  positionManagement: positionManagementStoreModel,
  videoManagement: videoManagementStoreModel,
  topBarNotification: topBarNotificationStoreModel,
  businessManagement: businessManagementStoreModel,
  recruiterManagement: recruiterManagementStoreModel,
  profileManagement: profileManagementStoreModel,
  accountLevelConfiguration: accountLevelConfigurationStoreModel,
  unSubscribeModel: unsubscribeManagementStoreModel,
  modernHireModal: modernHireManagementStoreModel,
  inviteCollaborators: inviteCollaboratorsStoreModel,
  toDoList: toDoStoreModel,
  requisition: requisitionModel,
  managementOverview: managementOverviewModel,
  offerAndHireProcess: offerAndHireProcessStoreModel,
  product: productStoreModel,
  imageGallery: imageGalleryStoreModel,
  hrFormTemplate: hrFormTemplateStoreModel,
  languageEvaluationTemplate: languageEvaluationModel,
};

export default storeModel;
