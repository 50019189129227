import React from 'react';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import Joi from 'joi';
import isEqual from 'lodash/isEqual';
import { Language, languages } from './formElements/formBuilderSchema';

export enum validationType {
  LINK = 'link',
  TEXT = 'text',
  LABEL = 'label',
  SECTION_NAME = 'section_name',
  TEMPLATE_NAME = 'template_name',
  DOCUMENT_PREVIEW_CONFIRM_TEXT = 'document_preview_confirm_text',
  DOCUMENT_PREVIEW_FILE = 'document_preview_file',
  SECTION = 'section',
}
export type ValidationType =
  | 'link'
  | 'template_name'
  | 'label'
  | 'text'
  | 'document_preview_confirm_text'
  | 'document_preview_file'
  | 'section_name';

type ValueType =
  | string
  | {
      file: {
        mimetype: string;
        size: number;
      };
    };

export interface ErrorWrapperProps {
  children: React.ReactNode;
  hasError: boolean;
  error: string;
}

interface useErrorHandlingProps<T> {
  path: (string | number)[];
  valdationFor: ValidationType;
  onValidation: (value: ValueType) => T;
  onFocus?: () => void;
}

export default function useErrorHandling<T>({ path, valdationFor, onValidation, onFocus }: useErrorHandlingProps<T>) {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const { errors } = useStoreState((state) => state.hrFormTemplate);
  const { setErrors } = useStoreActions((state) => state.hrFormTemplate);
  const [currentErrors, setCurrentErrors] = React.useState<Joi.ValidationErrorItem>();

  const serverError = errors?.find((error) => isEqual(error.path, path));
  const hasError = React.useMemo(() => !!serverError || !!currentErrors, [serverError, currentErrors]);

  React.useEffect(() => {
    if (hasError) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
      onFocus?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError, errors, currentErrors]);

  const schema = validationSchemas(languages, valdationFor);

  function validateError(
    value: ValueType,
    onSuccess?: (value: ValueType) => void,
    onError?: (error: Joi.ValidationErrorItem) => void,
  ) {
    const { error } = schema.validate(onValidation(value), { abortEarly: false });
    const index = errors?.findIndex((error) => isEqual(error.path, path));
    if (error) {
      const validationError = { ...error?.details?.[0], path };
      onError?.(validationError);
      setCurrentErrors(validationError);
      // const newErrors = [...errors];
      // if (index !== -1) {
      //   // newErrors.splice(index, 1, validationError);
      //   setCurrentErrors(validationError);
      // } else {
      //   setCurrentErrors([...newErrors, validationError]);
      // }
    } else {
      onSuccess?.(value);
      setCurrentErrors(undefined);
      const newErrors = [...errors];
      if (newErrors.length > 0) {
        if (index !== -1) {
          newErrors.splice(index, 1);
          setErrors(newErrors);
        } else {
          setErrors([]);
        }
      }
    }
  }

  return { validateError, error: currentErrors || serverError, hasError, inputRef };
}

const validationSchemas = (languages: readonly Language[], valdationFor: ValidationType) => {
  const schemas = {
    [validationType.LINK]: Joi.alternatives().try(
      Joi.object().pattern(
        Joi.string().valid(...languages),
        Joi.string().max(100).allow('', null).optional().messages({
          'string.max': `"Link" must not exceed {#limit} characters.`,
        }),
      ),
    ),
    [validationType.TEMPLATE_NAME]: Joi.alternatives().try(
      Joi.object().pattern(
        Joi.string().valid(...languages),
        Joi.string().max(50).allow('', null).optional().messages({
          'string.max': `"name" must not exceed {#limit} characters.`,
        }),
      ),
    ),
    [validationType.LABEL]: Joi.string().max(50).allow('', null).messages({
      'string.max': `"label" must not exceed {#limit} characters.`,
    }),
    [validationType.TEXT]: Joi.alternatives().try(
      Joi.object().pattern(
        Joi.string().valid(...languages),
        Joi.string().max(1000).allow('', null).optional().messages({
          'string.max': `"text" must not exceed {#limit} characters.`,
        }),
      ),
    ),
    [validationType.DOCUMENT_PREVIEW_CONFIRM_TEXT]: Joi.string().max(30).messages({
      'string.max': `"Confirm Text" must not exceed {#limit} characters.`,
      'string.empty': `"Confirm Text" is required`,
    }),
    [validationType.DOCUMENT_PREVIEW_FILE]: Joi.object({
      file: Joi.object({
        mimetype: Joi.string().valid('application/pdf').required().messages({
          'any.only': 'Only PDF are allowed.',
          'any.required': 'File is required.',
        }),
        size: Joi.number()
          .max(2 * 1024 * 1024) // 2MB in bytes
          .required()
          .messages({
            'number.max': 'File size must not exceed 2MB.',
            'any.required': 'File size is required.',
          }),
      })
        .required()
        .messages({
          'any.required': 'File object is required.',
        }),
    }),
    [validationType.SECTION_NAME]: Joi.string().max(50).required().messages({
      'string.max': `"section" must not exceed {#limit} characters.`,
      'string.empty': `"section" is required`,
      'any.required': `"section" is required`,
    }),
  };

  return schemas[valdationFor];
};
