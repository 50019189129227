import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  Text,
  HStack,
  Stack,
  useToast,
} from '@chakra-ui/core';
import React from 'react';
import { IoOptions } from 'react-icons/io5';
import { useHireFormContext } from '../../HireForm.context';
import { MdDragIndicator } from 'react-icons/md';
import theme from '../../../../../styles/customTheme';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { formBuilderType, SectionSchema } from '../formBuilderSchema';
import update from 'immutability-helper';
import { useTranslation } from 'react-i18next';

const SectionSortableDrawer = React.memo(() => {
  const { t } = useTranslation('hrFormTemplate');
  const btnRef = React.useRef<HTMLButtonElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { formSchema, setFormSchema } = useHireFormContext();
  const toast = useToast();
  const [sections, setSections] = React.useState<SectionSchema[]>(formSchema);

  React.useEffect(() => {
    setSections(formSchema);
  }, [formSchema]);

  function onSortEnd({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) {
    const signatureIndex = sections.findIndex((section) =>
      section.fields.some((field) => field.inputType === formBuilderType.signature),
    );
    if (newIndex === signatureIndex) {
      toast({
        status: 'warning',
        description: t('formBuilder.signatureSwapError'),
      });
      return;
    }

    const updatedList = update(sections, {
      $splice: [
        [oldIndex, 1],
        [newIndex, 0, sections[oldIndex]],
      ],
    });
    setSections(updatedList);
  }

  function handleSave() {
    onClose();
    setFormSchema(sections);
  }

  return (
    <>
      <Button variant="outline" ref={btnRef} size="sm" onClick={onOpen}>
        <IoOptions size="1.5em" />
      </Button>
      <Drawer isFullHeight isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={btnRef} size="md" autoFocus={false}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>{t('formBuilder.sectionSortDrawer.title')}</DrawerHeader>

          <DrawerBody>
            <SectionListContainer
              sections={sections}
              onSortEnd={onSortEnd}
              axis="y"
              lockAxis="y"
              hideSortableGhost={true}
              lockToContainerEdges={true}
              helperClass="dragging-section-item"
            />
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              {t('formBuilder.sectionSortDrawer.cancel')}
            </Button>
            <Button colorScheme="blue" onClick={handleSave}>
              {t('formBuilder.sectionSortDrawer.save')}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
});

const SectionElement = SortableElement(function ({ section }: { section: SectionSchema }) {
  const formatLabel = (field: string): string => {
    const words = field?.match(/[A-Z]?[a-z]+|[0-9]+/g);
    return (
      words
        ?.map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word.toLowerCase()))
        .join(' ') ?? ''
    );
  };
  return (
    <Box
      px={2}
      py={1}
      borderRadius="md"
      border="1px solid"
      borderColor={theme.colors.gray[100]}
      _hover={{ bg: theme.colors.gray[50] }}
      width="100%"
      cursor="grab"
      zIndex={1000}
      backgroundColor={theme.colors.white}
    >
      <HStack justify="space-between" width="100%">
        <Text fontSize="sm">{formatLabel(section.section)}</Text>
        <Button size="xs" variant="ghost" _focus={{ outline: 'none' }} _active={{ outline: 'none' }}>
          <MdDragIndicator />
        </Button>
      </HStack>
    </Box>
  );
});

const SectionListContainer = SortableContainer(function ({ sections }: { sections: SectionSchema[] }) {
  return (
    <Stack spacing={2} flexGrow={1}>
      {sections?.map((section, index) => {
        const isSignature = section.fields.some((field) => field.inputType === formBuilderType.signature);

        return <SectionElement key={section.id} section={section} index={index} disabled={isSignature} />;
      })}
    </Stack>
  );
});

export default SectionSortableDrawer;
