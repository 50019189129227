import { Box, FormControl, FormErrorMessage, HStack, Text } from '@chakra-ui/core';
import React from 'react';
import { addDataTagToSpans, LabelSchema, Language } from './formBuilderSchema';
import DOMPurify from 'dompurify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import theme from '../../../../styles/customTheme';
import Quill from 'quill';
import useErrorHandling from '../useErrorHandling';
import { useStoreState } from '../../../../models/hooks';

// const MAX_LENGTH = 500;

const Inline = Quill.import('blots/inline');

class BoldTag extends Inline {
  static blotName = 'tag';
  static tagName = 'span';

  static create(value: string) {
    const node = super.create();
    node.setAttribute('data-tag', value);
    return node;
  }

  static formats(node: HTMLElement) {
    return node.getAttribute('data-tag');
  }
}

Quill.register(BoldTag);

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
  ],
  keyboard: { bindings: { enter: { key: 13, handler: () => false } } },
};

type LabelType = LabelSchema[keyof LabelSchema];
interface TextSchemaBuilderProps extends React.ComponentProps<typeof Box> {
  value?: LabelSchema;
  label: LabelType;
  onLabelChange: (label: string) => void;
  startWithEditView?: boolean;
  language: Language;
  path?: (string | number)[];
  hideTextCounter?: boolean;
}

/**
 * A component to render and edit a label schema for a form builder.
 *
 * This component renders a ReactQuill editor when in editing mode, and a static
 * label or placeholder when not in editing mode. It handles clicks outside the
 * editor to toggle editing mode, and sanitizes the changed value before passing
 * it to the `onLabelChange` handler.
 *
 * @prop {LabelSchema[keyof LabelSchema]} label - The current label value.
 * @prop {(label: LabelSchema[keyof LabelSchema]) => void} onLabelChange - The
 *   handler to call when the label value changes.
 * @prop {string} [placeholder='Enter text here...'] - The placeholder text to
 *   display when the label is empty.
 * @prop {boolean} [startWithEditView=true] - Whether to start in editing mode.
 * @prop {Language} language - The current language.
 * @prop {React.ComponentProps<typeof Box>} ...props - Additional props to
 *   pass to the Box container.
 * @returns A JSX element.
 */
const TextSchemaBuilder = ({
  value,
  label,
  onLabelChange,
  placeholder = 'Enter text here...',
  startWithEditView = false,
  language,
  path,
  hideTextCounter = false,
  ...props
}: TextSchemaBuilderProps) => {
  // References for the ReactQuill editor and the Box container
  const quillRef = React.useRef<ReactQuill>(null);
  const boxRef = React.useRef<HTMLDivElement>(null);
  const { tags } = useStoreState((state) => state.hrFormTemplate);

  const [input, setInput] = React.useState<string>(label ?? '');

  // State to track if the editor is in editing mode
  const [isEditing, setIsEditing] = React.useState<boolean>(startWithEditView);
  // const [inputValue, setInputValue] = React.useState(label);

  React.useEffect(() => {
    setInput(addDataTagToSpans(label ?? ''));
  }, [label]);

  const { validateError, error, hasError } = useErrorHandling({
    path: path!,
    valdationFor: 'text',
    onValidation: (val) => {
      return { ...value!, [language]: val };
    },
    onFocus: () => {
      setIsEditing(true);
    },
  });

  // Reference to track if the label has been clicked
  const hasClickedRef = React.useRef<boolean>(!!input);

  // Function to focus the cursor at the end of the text in the editor
  const focusCursorToEnd = React.useCallback(() => {
    if (isEditing) {
      if (quillRef.current) {
        const editor = quillRef.current.getEditor();
        const length = editor.getLength();
        editor.setSelection(length, length);
      }
    } else {
      const sanitizedValue = DOMPurify.sanitize(input, {
        ALLOWED_ATTR: ['data-tag', 'class'],
      });
      // Check if the sanitized value is different from the current label/input
      if (sanitizedValue !== label) {
        onLabelChange(sanitizedValue); // Call onLabelChange only if value has changed
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  // Effect to ensure cursor is focused at the end when component mounts or editing mode changes
  React.useEffect(() => {
    focusCursorToEnd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusCursorToEnd]);

  const handleOutsideClick = (event: MouseEvent) => {
    // Check if the click is outside the boxRef
    if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
      setIsEditing(false); // Disable editing if unclicked
      if (!hasClickedRef.current) {
      } else {
        hasClickedRef.current = false; // Reset click state
      }
    }
  };
  // Effect to handle clicks outside the editor, toggling editing mode
  React.useEffect(() => {
    if (isEditing) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    // Add event listener for clicks

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isEditing]);

  // const cleanContent = (content: string) => {
  //   return content.replace(/<p><br><\/p>/g, '').trim();
  // };
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevents a new line from being added
      if (quillRef.current) {
        quillRef.current.blur(); // Blur the editor
      }
    }
  };
  const handleChange = (val: string, _delta: any, source: string, editor: any) => {
    const quill = quillRef.current?.getEditor();
    // const text = (editor.getText() as string).substring(0, MAX_LENGTH); // Get plain text
    const text = editor.getText();
    const sanitizedValue = DOMPurify.sanitize(val, {
      ALLOWED_ATTR: ['data-tag', 'class'],
    });

    setInput(sanitizedValue);

    validateError(editor.getText());

    if (source === 'user') {
      const regex = /\[([^\]]+)\]/g; // Matches "[text]" format
      const matches = [];
      let match;
      while ((match = regex.exec(text)) !== null) {
        if (!tags.includes(match[1])) continue;
        matches.push({ match: match[1], index: match.index, length: match[0].length });
      }

      if (quill) {
        // Save the current selection
        const currentSelection = quill.getSelection();

        // Clear bold formatting for the entire content
        quill.formatText(0, text.length, 'tag', false);

        // Apply bold formatting only to matched tags
        matches.forEach(({ index, length }) => {
          quill.formatText(index, length, 'tag', true); // Apply the boldTag format
        });

        // Restore the selection to maintain focus
        if (currentSelection) {
          quill.setSelection(currentSelection);
        } else {
          quill.focus(); // Ensure the editor is focused if there was no selection
        }
      }
    }
  };

  return (
    <Box w="100%" {...props} ref={boxRef} position="relative" zIndex={1}>
      <HStack>
        <FormControl isInvalid={hasError}>
          {!isEditing && (
            <Box
              dangerouslySetInnerHTML={{ __html: input?.length ? input : placeholder }} // Render content as HTML
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                setIsEditing(true); // Enter editing mode on click
              }}
              sx={{
                '& ol, & ul': {
                  margin: 'revert',
                  padding: 'revert',
                },
                '&:hover ~ .text-counter': {
                  top: 1.2,
                },
              }}
              color={input?.length ? theme.colors.black : theme.colors.gray[400]}
              cursor="text"
              fontSize="sm"
              _hover={{
                borderRadius: theme.radii.sm,
                outline: '1px solid',
                outlineColor: theme.colors.gray[300],
                outlineOffset: '4px',
              }}
              style={{
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                wordBreak: 'break-word',
                whiteSpace: 'pre-wrap',
              }}
              date-testid="text-editor-preview"
            />
          )}
          <Box data-testid="text-editor" display={isEditing ? 'block' : 'none'}>
            <ReactQuill
              theme="snow"
              value={input}
              placeholder={placeholder}
              modules={modules}
              onChange={handleChange}
              onBlur={(_, source) => {
                if (source !== 'silent') setIsEditing(false);
              }} // Exit editing mode on blur
              ref={quillRef}
              style={{
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                wordBreak: 'break-word',
                whiteSpace: 'pre-wrap',
                borderRadius: theme.radii.md,
              }}
              onKeyDown={handleKeyDown}
            />
          </Box>

          <FormErrorMessage mt={0} fontSize="xs">
            {error?.message}
          </FormErrorMessage>
        </FormControl>
        {hideTextCounter ? null : (
          <Text fontSize="xs" color="#A0AEC0" className="text-counter">
            {input.length}/1000
          </Text>
        )}
      </HStack>
    </Box>
  );
};
export default TextSchemaBuilder;
