import React from 'react';
import { SchemaBuilderProps } from './formBuilderSchema';
import FormElementTextBuilder from './FormElementTextBuilder';
import { Box, Divider, HStack, Stack } from '@chakra-ui/core';
import LabelSchemaBuilder from './LabelSchemaBuilder';
import InputPreview from './InputPreview';
import InputMinMaxSelector from './InputMinMaxSelector';

export default function TextFieldSchemaBuilder({ ...props }: SchemaBuilderProps) {
  const textField = props?.defaultValue?.isInfo ?? false;

  return (
    <Stack>
      <HStack justify="space-between">
        <Box>{!textField && <LabelSchemaBuilder {...props} />}</Box>
        <HStack flexShrink={0}>
          <Divider orientation="vertical" />
          {props?.labelRightRenderer}
        </HStack>
      </HStack>

      <FormElementTextBuilder {...props} />
      {!textField && (
        <InputPreview
          value={props?.defaultValue?.previewPlaceholders?.placeholder ?? 'Input field'}
          rightContent={<InputMinMaxSelector {...props} />}
        />
      )}
    </Stack>
  );
}
