import React from 'react';
import { FormBuilderProps } from '../../formBuilder/FormBuilder';
import { FieldSchema, generateFormSchema, mapField, SchemaBuilderProps } from '../formBuilderSchema';
import { useHireFormContext } from '../../HireForm.context';
import DroppableElement from './DroppableElement';
import { Box, Button, HStack, keyframes } from '@chakra-ui/core';
import theme from '../../../../../styles/customTheme';
import { fieldLabel, formBuilderSchemas, schemaFieldsByType } from '../../formBuilderSchemas/formBuilder.schema';
import isArray from 'lodash/isArray';
import ElementSelectField from '../ElementSelectField';
import { HiTrash } from 'react-icons/hi';
import DeletePopover from '../advancedElements/DeletePopover';
import { useStoreActions, useStoreState } from '../../../../../models/hooks';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MdDragIndicator } from 'react-icons/md';
import isEqual from 'lodash/isEqual';

interface FormBuilderElementProps extends Omit<FormBuilderProps, 'templateName' | 'setTemplateName'> {
  field: FieldSchema;
  fieldIndex: number;
  sectionIndex: number;
  isAdvancedSection: boolean;
  isLastElement?: boolean;
}

export default function FormElementBuilder({
  field,
  sectionIndex,
  isAdvancedSection,
  isLastElement,
  fieldIndex,
}: FormBuilderElementProps) {
  const { formSchema, setFormSchema, language, draggedElement } = useHireFormContext();
  const { isLoading } = useStoreState((state) => state.hrFormTemplate);
  const elementRef = React.useRef<HTMLDivElement>(null);
  const { timestamp, inputType, hideFieldSelector } = field;
  const { component: Component } = formBuilderSchemas[inputType];

  React.useEffect(() => {
    if (elementRef.current && field && !isLoading) {
      const currentElement = elementRef.current;
      setTimeout(() => {
        if (Date.now() - field.timestamp < 1000) {
          currentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!Component) return null;

  const pathIndex = generateFormSchema(formSchema, language, true)?.findIndex((schema) => schema.id === field.id);

  const elementFields =
    schemaFieldsByType?.[field.field as keyof typeof schemaFieldsByType] || schemaFieldsByType?.[field.inputType];

  return (
    <DroppableElement
      index={fieldIndex}
      onDrop={(type, dropTarget) => {
        if (type !== 'section') {
          const { defaultValue } = formBuilderSchemas[type as keyof typeof formBuilderSchemas];
          const newFormSchema = [...formSchema]; // create a shallow copy of the formSchema
          const currentSection = { ...formSchema[sectionIndex] }; // Create a copy of the current section to avoid mutation

          let spliceIndex = fieldIndex;

          // Adjust spliceIndex based on dropTarget (top/bottom)
          if (dropTarget === 'bottom') {
            // Always add to the last position of the fields array
            spliceIndex = currentSection.fields.length; // Use length of the current fields array
          } else if (dropTarget === 'top') {
            spliceIndex = fieldIndex; // Add at the specified fieldIndex when target is 'top'
          }

          // If defaultValue is an array, we return early
          if (isArray(defaultValue)) return;

          // Create the new field
          const newField = { ...mapField(defaultValue) };

          // Insert the new field into currentSection's fields array at the correct position
          currentSection.fields = [
            ...currentSection.fields.slice(0, spliceIndex),
            newField,
            ...currentSection.fields.slice(spliceIndex),
          ];

          // Now, replace the currentSection in the newFormSchema
          newFormSchema[sectionIndex] = currentSection;

          // Update the state with the new form schema
          setFormSchema(newFormSchema);
        }
      }}
      sx={{
        '&:hover .hire-form-element-actions': { visibility: 'visible' },
        '&': { paddingX: theme.space[4], paddingTop: theme.space[4], paddingBottom: isLastElement ? theme.space[4] : 0 },
      }}
      rightContent={
        <FormElementActions isAdvancedSection={isAdvancedSection} sectionIndex={sectionIndex} fieldIndex={fieldIndex} />
      }
      isLastElement={isLastElement}
      isDisabled={isAdvancedSection}
      acceptedTypes={['basic']}
      currentType={draggedElement?.panel!}
    >
      <Box backgroundColor={theme.colors.white} ref={elementRef}>
        <HStack
          justify="space-between"
          align="flex-start"
          position="relative"
          _after={{
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            boxSizing: 'content-box',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            animation: timestamp > Date.now() - 1000 ? `${animation} 500ms ease-out` : '',
            zIndex: -10,
            borderRadius: theme.radii.md,
          }}
        >
          <Box flexGrow={1} transition={'flex-grow 0.2s ease-in-out'}>
            <MemoizedRenderElement
              field={field}
              onChange={(value) => {
                const newFormSchema = [...formSchema];
                newFormSchema[sectionIndex].fields[fieldIndex] = value;
                setFormSchema(newFormSchema);
              }}
              language={language}
              isAdvanced={isAdvancedSection}
              sectionIndex={sectionIndex}
              fieldIndex={fieldIndex}
              pathIndex={pathIndex}
              labelRightRenderer={
                ((elementFields && elementFields.length > 0) || !hideFieldSelector) &&
                !isAdvancedSection && (
                  <ElementSelectField
                    defaultValue={field}
                    options={elementFields ?? []}
                    onChange={(field) => {
                      const updatedFormSchema = [...formSchema];
                      const currentField = { ...updatedFormSchema[sectionIndex].fields[fieldIndex] };
                      updatedFormSchema[sectionIndex].fields[fieldIndex] = {
                        ...currentField,
                        field,
                        label: {
                          ...currentField.label!,
                          [language]: fieldLabel[field as keyof typeof fieldLabel],
                        },
                      };

                      setFormSchema(updatedFormSchema);
                    }}
                  />
                )
              }
            />
          </Box>
        </HStack>
      </Box>
    </DroppableElement>
  );
}

interface FormBuilderActionsProps {
  isAdvancedSection: boolean;
  sectionIndex: number;
  fieldIndex: number;
}

function FormElementActions({ isAdvancedSection, sectionIndex, fieldIndex }: FormBuilderActionsProps) {
  const { formSchema, setFormSchema } = useHireFormContext();
  const { setErrors } = useStoreActions((s) => s.hrFormTemplate);
  const { errors } = useStoreState((s) => s.hrFormTemplate);

  return (
    <HStack>
      {!isAdvancedSection && (
        <HStack flexShrink={0} marginTop="0 !important" visibility="hidden" className="hire-form-element-actions">
          <SortableAnchor />
          <DeletePopover
            popoverTrigger={
              <Button borderRadius="50%" colorScheme="red" style={{ aspectRatio: '1/1' }} padding={1} size="sm">
                <HiTrash />
              </Button>
            }
            onYes={() => {
              const newFormSchema = [...formSchema];
              newFormSchema[sectionIndex].fields.splice(fieldIndex, 1);
              setErrors([...errors]?.filter((e) => e.path[1] !== fieldIndex));
              setFormSchema(newFormSchema);
            }}
          />
        </HStack>
      )}
    </HStack>
  );
}

const SortableAnchor = SortableHandle(() => (
  <Button as="div" borderRadius="50%" cursor="grab" backgroundColor="gray" style={{ aspectRatio: '1/1' }} padding={1} size="sm">
    <MdDragIndicator />
  </Button>
));

function RenderElement({
  field,
  onChange,
  fieldIndex,
  pathIndex,
  ...rest
}: { field: FieldSchema; pathIndex: number } & Omit<SchemaBuilderProps, 'defaultValue' | 'path' | 'followUpLabel'>) {
  const { inputType } = field;

  const { component: Component } = formBuilderSchemas[inputType];
  if (!Component) return null;

  const path = ['questions', pathIndex];
  function handleOnChange(value: any) {
    onChange(value);
  }

  return (
    <Component onChange={handleOnChange} {...rest} defaultValue={field} path={path} fieldIndex={fieldIndex} followUpLabel={0} />
  );
}

const MemoizedRenderElement = React.memo(RenderElement, (prev, next) => {
  return (
    isEqual(prev.field, next.field) &&
    prev.language === next.language &&
    prev.fieldIndex === next.fieldIndex &&
    prev.sectionIndex === next.sectionIndex &&
    prev.pathIndex === next.pathIndex &&
    prev.onChange === next.onChange
  );
});

const animation = keyframes`
  0% {
    background-color: ${theme.colors.blue[100]};
    outline: 2px solid ${theme.colors.blue[100]};
    outline-offset: 2px;
    padding: 6px;
    z-index: 2;
  }
  60% {
    outline: 2px solid ${theme.colors.blue[50]};
    outline-offset: 7px;
  }
  100% {
    background-color: transparent;
    outline: 0px solid transparent;
    outline-offset: 10px;
    padding: 12px;
    z-index: -1;
  }
`;

export const SortableFormElementBuilder = SortableElement(FormElementBuilder);
