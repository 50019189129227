import {
  HStack,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  Spacer,
  Stack,
  NumberInput,
  theme,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  PopoverFooter,
  Button,
  ButtonGroup,
  Text,
  Box,
} from '@chakra-ui/core';
import React from 'react';
import { SchemaBuilderProps } from './formBuilderSchema';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { isEqual } from 'lodash';
interface MinMaxSelectorProps {
  minValue: number;
  maxValue: number;
  onChange: (value: { min: number; max: number }) => void;
}

const MinMaxSelector = React.memo(
  function ({ minValue = 0, maxValue = 10, onChange }: MinMaxSelectorProps) {
    const [max, setMax] = React.useState(maxValue);
    const [min, setMin] = React.useState(minValue);

    const hasValuesChanged = React.useMemo(() => {
      return max !== maxValue || min !== minValue;
      // eslint-disable-next-line
    }, [max, min]);

    React.useEffect(() => {
      setMin(minValue);
      // eslint-disable-next-line
    }, [minValue]);

    React.useEffect(() => {
      setMax(maxValue);
      // eslint-disable-next-line
    }, [, maxValue]);

    function handleClose(onClose: () => void) {
      onClose();
    }

    function handleSave(onClose: () => void) {
      onChange({ min, max });
      onClose();
    }

    return (
      <HStack>
        <Popover placement="bottom" closeOnBlur={false}>
          {({ onClose }) => (
            <>
              <PopoverTrigger>
                <Box backgroundColor="#E2E8F0" paddingX={theme.space[2]} paddingY={theme.space[1]} borderRadius={theme.radii.md}>
                  <HStack>
                    <Text fontSize="sm" cursor="pointer">
                      Character Limit ({minValue} - {maxValue})
                    </Text>
                    <MdKeyboardArrowDown size={20} />
                  </HStack>
                </Box>
              </PopoverTrigger>
              <Portal>
                <PopoverContent>
                  <PopoverHeader pt={4} fontWeight="bold" border="0">
                    Characters limits
                  </PopoverHeader>

                  <PopoverCloseButton disabled={hasValuesChanged} />
                  <PopoverBody>
                    <Text fontSize="sm">
                      Set the minimum and maximum number of characters allowed in this field. User must input between {min} and{' '}
                      {max} characters while answering this question.
                    </Text>
                    <Spacer marginY={3} />
                    <HStack>
                      <Stack flexShrink={0} flexGrow={1} spacing={1}>
                        <Text fontSize="xs" fontWeight="bold" whiteSpace="nowrap">
                          Min :
                        </Text>
                        <NumberInput
                          allowMouseWheel
                          step={10}
                          value={min || 0}
                          min={0}
                          max={max || 1000}
                          onChange={(e) => setMin(Number(e))}
                          borderRadius={theme.radii.md}
                          size="sm"
                          maxW={'8rem'}
                        >
                          <NumberInputField borderRadius={theme.radii.md} />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </Stack>

                      <Stack flexShrink={0} flexGrow={1} spacing={1}>
                        <Text fontSize="xs" fontWeight="bold" whiteSpace="nowrap">
                          Max :
                        </Text>
                        <NumberInput
                          allowMouseWheel
                          step={10}
                          value={max || 0}
                          min={min || 0}
                          max={1000}
                          maxW={'8rem'}
                          onChange={(e) => setMax(Number(e))}
                          borderRadius={theme.radii.md}
                          size="sm"
                        >
                          <NumberInputField borderRadius={theme.radii.md} />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </Stack>
                    </HStack>
                    <Spacer marginTop={3} />
                  </PopoverBody>
                  <PopoverFooter d="flex" alignItems="center" justifyContent="space-between" pb={4}>
                    <Button
                      colorScheme="gray"
                      variant="ghost"
                      onClick={() => {
                        setMax(maxValue);
                        setMin(minValue);
                      }}
                      disabled={!hasValuesChanged}
                      size="sm"
                    >
                      Reset
                    </Button>
                    <ButtonGroup size="sm">
                      <Button colorScheme="gray" variant="outline" onClick={() => handleClose(onClose)}>
                        Cancel
                      </Button>
                      <Button colorScheme="blue" onClick={() => handleSave(onClose)} disabled={!hasValuesChanged}>
                        Done
                      </Button>
                    </ButtonGroup>
                  </PopoverFooter>
                </PopoverContent>
              </Portal>
            </>
          )}
        </Popover>
      </HStack>
    );
  },
  (prev, next) => prev.minValue === next.minValue && prev.maxValue === next.maxValue && prev.onChange === next.onChange,
);

const InputMinMaxSelector = React.memo(
  ({ defaultValue, onChange }: Pick<SchemaBuilderProps, 'defaultValue' | 'onChange'>) => {
    function handleOnChange(val: { min: number; max: number }) {
      const newDate = { ...defaultValue, ...val };
      onChange(newDate);
    }
    return <MinMaxSelector minValue={defaultValue.min!} maxValue={defaultValue.max!} onChange={handleOnChange} />;
  },
  (prev, next) => isEqual(prev.defaultValue, next.defaultValue) && prev.onChange === next.onChange,
);
export default InputMinMaxSelector;
