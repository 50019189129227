import { Stack, Button, Text, HStack, Box, Flex, Input, FormControl, FormErrorMessage } from '@chakra-ui/core';
import React from 'react';
import { IoClose, IoRadioButtonOff } from 'react-icons/io5';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MdCheckBoxOutlineBlank, MdDragIndicator } from 'react-icons/md';
import theme from '../../../../styles/customTheme';
import { Language, OptionSchema } from './formBuilderSchema';
import { v4 as uuidv4 } from 'uuid';
import { useStoreState } from '../../../../models/hooks';
import isEqual from 'lodash/isEqual';
import Joi from 'joi';

const MAX_OPTIONS_COUNT = 20;

export function swap<T>(array: T[], indexA: number, indexB: number): T[] {
  const newArray = [...array];
  [newArray[indexA], newArray[indexB]] = [newArray[indexB], newArray[indexA]];
  return newArray;
}

interface OptionSchemaBuilderProps {
  options: OptionSchema[];
  handleChange: (options: OptionSchema[]) => void;
  language: Language;
  hideSelection?: boolean;
  type: 'radio' | 'checkbox';
  path: (string | number)[];
}

const OptionSchemaBuilder: React.FC<OptionSchemaBuilderProps> = ({
  options,
  handleChange,
  language,
  hideSelection,
  type,
  ...props
}) => {
  const path = [...props.path, 'optionList'];
  const handleOptionChange = (value: string, index: number) => {
    const updatedOptions = options.map((option, i) =>
      i === index ? { ...option, text: { ...option.text, [language]: value } } : option,
    );
    handleChange(updatedOptions);
  };

  const handleRemoveOption = (index: number) => {
    const updatedOptions = options.filter((_, i) => i !== index);
    handleChange(updatedOptions);
  };

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    handleChange(swap(options, oldIndex, newIndex));
  };

  return (
    <Stack align="flex-start" spacing={4}>
      <OptionList
        options={options}
        language={language}
        onSortEnd={onSortEnd}
        onOptionChange={handleOptionChange}
        onRemoveOption={handleRemoveOption}
        hideSelection={hideSelection}
        useDragHandle
        type={type}
        path={path}
      />
      {options.length < MAX_OPTIONS_COUNT && <AddOptionButton onClick={() => handleChange([...options, createNewOption()])} />}
    </Stack>
  );
};

const createNewOption = (): OptionSchema => ({
  id: uuidv4(),
  text: { en: '', fr: '' },
  key: 'a',
});

interface OptionListProps {
  options: OptionSchema[];
  language: Language;
  onSortEnd: ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => void;
  onOptionChange: (value: string, index: number) => void;
  onRemoveOption: (index: number) => void;
  hideSelection?: boolean;
  type: 'radio' | 'checkbox';
  path: (string | number)[];
}

const OptionList = SortableContainer(
  ({ options, onOptionChange, language, onRemoveOption, hideSelection, type, path }: OptionListProps) => (
    <Stack spacing={2} w="100%">
      {options.map((option, index) => {
        const startWithEditView = options.length - 1 === index && Boolean(!option.text[language]?.length);
        return (
          <SortableOption
            key={option.id}
            option={option}
            index={index}
            optionIndex={index}
            language={language}
            onOptionChange={onOptionChange}
            onRemoveOption={onRemoveOption}
            hideSelection={hideSelection}
            type={type}
            optionsCount={options.length ?? 0}
            startWithEditView={startWithEditView}
            path={path}
          />
        );
      })}
    </Stack>
  ),
);

interface SortableOptionProps {
  option: OptionSchema;
  optionIndex: number;
  language: Language;
  onOptionChange: (value: string, index: number) => void;
  onRemoveOption: (index: number) => void;
  hideSelection?: boolean;
  type: 'radio' | 'checkbox';
  optionsCount: number;
  startWithEditView?: boolean;
  path: (string | number)[];
}

const SortableHandleIcon = SortableHandle(() => <MdDragIndicator cursor="grab" color={theme.colors.gray[300]} />);

const SortableOption = SortableElement(
  ({
    option,
    optionIndex: index,
    language,
    onOptionChange,
    onRemoveOption,
    hideSelection,
    type,
    optionsCount,
    startWithEditView,
    ...props
  }: SortableOptionProps) => {
    const [isFocused, setIsFocused] = React.useState(false);
    const path = [...props.path, index, 'text', language];
    const { text } = option;
    const [value, setValue] = React.useState(text[language] || '');
    const inputRef = React.useRef<HTMLInputElement>(null);

    const { errors } = useStoreState((state) => state.hrFormTemplate);
    const [error, setError] = React.useState<Joi.ValidationErrorItem>();

    React.useEffect(() => {
      if (errors?.length) {
        const error = errors?.find((error) => isEqual(error.path, path));
        if (error && inputRef.current) {
          inputRef.current.focus();
        }
        setError(error);
      } else {
        setError(undefined);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    React.useEffect(() => {
      setValue(text[language] || '');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text, language]);

    React.useEffect(() => {
      if (inputRef.current && startWithEditView) {
        inputRef.current.focus();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startWithEditView]);

    React.useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
          onOptionChange(value, index);
        }
      };

      if (isFocused) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFocused, value]);

    return (
      <HStack
        w="100%"
        backgroundColor={theme.colors.white}
        sx={{ '&.is-dragging': { opacity: 0.5, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' } }}
      >
        <Flex h="inherit" alignItems="center">
          <SortableHandleIcon />
        </Flex>
        <HStack>
          <Text fontSize={theme.fontSizes.xs} fontWeight="bold">
            {type === 'radio' ? (
              <IoRadioButtonOff fontSize={theme.fontSizes.lg} />
            ) : (
              <MdCheckBoxOutlineBlank fontSize={theme.fontSizes.lg} />
            )}
          </Text>
        </HStack>

        <FormControl isInvalid={!!error}>
          <Input
            ref={inputRef}
            variant={!!error || isFocused ? 'outline' : 'unstyled'}
            flex={1}
            hideTextCounter
            size="sm"
            fontSize="sm"
            width="100%"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onFocus={() => setIsFocused(true)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onOptionChange(value, index);
              }
            }}
            onBlur={(e) => {
              onOptionChange(e.target.value, index);
              setIsFocused(false);
            }}
          />

          <FormErrorMessage marginTop={0} fontSize="xs">
            {error?.message}
          </FormErrorMessage>
        </FormControl>

        {!hideSelection && optionsCount > 2 && (
          <Box flexBasis="10%">
            <Button px={1} onClick={() => onRemoveOption(index)} variant="ghost" colorScheme="red" size="sm">
              <IoClose />
            </Button>
          </Box>
        )}
      </HStack>
    );
  },
);

interface AddOptionButtonProps {
  onClick: () => void;
}

const AddOptionButton: React.FC<AddOptionButtonProps> = ({ onClick }) => (
  <Button variant="link" fontSize={12} fontWeight="normal" onClick={onClick}>
    Add Option
  </Button>
);

export default OptionSchemaBuilder;
