import {
  ButtonGroup,
  Popover,
  PopoverTrigger,
  Button,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverFooter,
  useDisclosure,
  Text,
  useToast,
  Select,
} from '@chakra-ui/core';
import React from 'react';
import { generateFormSchema, HrFromCreateTemplateResponse, LabelSchema, Language } from '../formElements/formBuilderSchema';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { useCurrentUserProfile } from '../../../../app/hooks/useCurrentUserProfile';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useHireFormContext } from '../HireForm.context';
import { v4 as uuidv4 } from 'uuid';
import { TemplateSchema } from '../errorHandling/hireFormValidation.schema';
import theme from '../../../../styles/customTheme';
import SectionSortableDrawer from '../formElements/sectionSortableDrawer/SectionSortableDrawer';
import { PATH_HIRE_FORM_COPY, PATH_HIRE_FORM_EDIT } from '../../../../routes/constants';

interface TemplateActionsProps {
  templateName: LabelSchema;
}

const TemplateActions = React.memo((props: TemplateActionsProps) => {
  return (
    <ButtonGroup variant="outline" size="sm" data-testid="template-actions">
      <SectionSortableDrawer />
      <SelectLanguage />
      <ClearAllTemplate {...props} />
      <SaveTemplate {...props} />
    </ButtonGroup>
  );
});

const SelectLanguage = () => {
  const { setLanguage: onLanguageChange, language, languages } = useHireFormContext();
  return (
    <Select
      onChange={(e) => onLanguageChange(e.target.value as Language)}
      value={language}
      variant="outline"
      size="sm"
      colorScheme="blue[100]"
      borderRadius={theme.radii.md}
      maxWidth="fit-content"
    >
      {languages.map((lang) => (
        <option key={lang}>{lang}</option>
      ))}
    </Select>
  );
};

const SaveTemplate = React.memo(({ templateName }: Pick<TemplateActionsProps, 'templateName'>) => {
  const toast = useToast();
  const { formSchema, language } = useHireFormContext();

  const { isUpdating } = useStoreState((state) => state.hrFormTemplate);
  const { createTemplate, updateTemplate, setErrors } = useStoreActions((state) => state.hrFormTemplate);
  const { currentUserProfile } = useCurrentUserProfile();

  const templateId = useParams<{ templateId?: string }>()?.templateId ?? undefined;
  const isDuplicate = useRouteMatch(PATH_HIRE_FORM_COPY);
  const accountId = currentUserProfile?.account ?? '';
  const history = useHistory();

  async function handleSaveTemplate() {
    try {
      const questions = generateFormSchema(formSchema, language);
      const validation = TemplateSchema.validate(
        { name: templateName?.[language]!, questions: generateFormSchema(formSchema, language, true) },
        { abortEarly: false },
      );
      if (validation.error) {
        console.log('🚀 ~ handleSaveTemplate ~ validation.error.details:', validation);

        setErrors(validation.error.details);
      } else {
        let response: HrFromCreateTemplateResponse | undefined = undefined;
        if (templateId && !isDuplicate) {
          await updateTemplate({
            account: accountId,
            id: templateId,
            payload: { name: templateName?.[language]!, questions: questions },
          });
        } else {
          response = await createTemplate({
            account: accountId,
            payload: { name: templateName?.[language]!, questions: questions },
          });
        }

        toast({
          title: `Template ${templateId ? 'updated' : 'created'}`,
          status: 'success',
          isClosable: true,
        });

        setErrors([]);
        if (isDuplicate || !templateId) {
          history.replace(PATH_HIRE_FORM_EDIT.replace(':templateId', response?.data?.templateId ?? (templateId || '')));
        }
      }
    } catch (error) {
      toast({
        title: `Error ${templateId ? 'updating' : 'creating'} template`,
        status: 'error',
        isClosable: true,
      });
    }
  }
  return (
    <Button
      colorScheme="blue"
      isDisabled={
        formSchema.length === 0 || (formSchema.length === 1 && formSchema?.[0]?.fields?.length === 0) || isUpdating
        // TODO: update logic to clear error on save
        // errors.length > 0
      }
      onMouseDown={(e) => e.preventDefault()}
      isLoading={isUpdating}
      onClick={(e) => {
        e.preventDefault();
        setTimeout(() => {
          handleSaveTemplate();
        }, 0);
      }}
    >
      Save
    </Button>
  );
});

const ClearAllTemplate = React.memo(({ templateName }: Pick<TemplateActionsProps, 'templateName'>) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { isUpdating, isLoading } = useStoreState((state) => state.hrFormTemplate);
  const { setErrors, createTemplate, updateTemplate } = useStoreActions((state) => state.hrFormTemplate);
  const { formSchema, setFormSchema, language } = useHireFormContext();

  const { currentUserProfile } = useCurrentUserProfile();

  const templateId = useParams<{ templateId?: string }>()?.templateId ?? undefined;
  const isDuplicate = useRouteMatch(PATH_HIRE_FORM_COPY);
  const accountId = currentUserProfile?.account ?? '';
  const history = useHistory();

  async function handleClearAll() {
    let response: HrFromCreateTemplateResponse | undefined = undefined;
    if (templateId && !isDuplicate) {
      await updateTemplate({ account: accountId, id: templateId, payload: { name: templateName?.[language]!, questions: [] } });
    } else if (templateId && isDuplicate) {
      response = await createTemplate({ account: accountId, payload: { name: templateName?.[language]!, questions: [] } });
    }

    if (isDuplicate) {
      history.replace(PATH_HIRE_FORM_EDIT.replace(':templateId', response?.data?.templateId ?? (templateId || '')));
    }
    setFormSchema([{ section: 'section1', fields: [], id: uuidv4() }]);
    setErrors([]);
    onClose();
  }

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} closeOnBlur closeOnEsc>
      <PopoverTrigger>
        <Button
          colorScheme="red"
          isDisabled={
            formSchema.length === 0 ||
            (formSchema.length === 1 && formSchema?.[0]?.fields?.length === 0) ||
            isUpdating ||
            isLoading
          }
        >
          Clear All
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <Text>Are you sure you want to clear all form elements?</Text>
          </PopoverBody>
          <PopoverFooter display="flex" alignItems="center" justifyContent="flex-end">
            <ButtonGroup size="sm" variant="outline">
              <Button colorScheme="red" onClick={handleClearAll}>
                Yes
              </Button>
              <Button onClick={onClose}>No</Button>
            </ButtonGroup>
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  );
});

export default TemplateActions;
