/**
 * This is the official AppyHere Colour palette.
 * Some colors (*) will be matched to Chakra UI's colours. Therefore, there will be no BlueGray nor LightBlue
 * in the final ChakraUI's colours.
 */
const APPY_PALETTE = {
  ORANGE_800: '#E8822E',
  ORANGE_700: '#d25400',
  ORANGE_600: '#E35B00',
  ORANGE_400: 'rgb(255,159,96)',
  ORANGE_100: 'rgb(254, 235, 200)',
  RED_500: 'rgb(229, 62, 62)',
  RED_600: '#B33636',
  RED_900: '#b33636',
  LIGHT_BLUE_900: '#01579B', // *
  LIGHT_BLUE_800: '#3249b1', // *
  LIGHT_BLUE_400: '#29B6F6', // *
  ICON_BLUE: '#049BE5', // *
  LINK_BLUE: '#3182CE',
  GREEN_50: '#d3eadf',
  GREEN_600: '#5da55f',
  GREEN_500: '#5C995E',
  GREEN_400: '#48BB78',
  GREEN_A_200: '#69F0AE',
  SPECIAL_HIGHLIGHT_YELLOW: '#FFF176',
  SPECIAL_HIGHLIGHT_BLUE: '#b2ebf2', // *
  THUMB_GREEN: 'rgba(236,239,241,0.4)',
  BLACK: '#000000',
  LIGHT_GREY: '#979797',
  BRIGHT_GREY: '#f4f6f7',
  BLUE_GREY_50: '#ECEFF1', // *
  BLUE_GREY_100: '#CFD8DC', // *
  BLUE_GREY_300: '#90A4AE', // *
  BLUE_GREY_400: '#4A5568', // *
  BLUE_GREY_500: '#607D8B', // *
  BLUE_GREY_700: '#455A64', // *
  BLUE_GREY_900: '#263238', // *
  UNVERIFIED_STATUS_RED: '#B03737',
  GREY: '#666666',
  GREY_50: '#FAFAFA',
  GREY_100: '#F5F5F5',
  GREY_150: '#F5F8FA',
  GREY_400: '#A0AEC0',
  GREY_600: '#dedede',
  GREY_700: '#2D3748',
  WHITE: '#FFFFFF',
  SUBTLE_WHITE: 'rgba(255,255,255,0.87)',
  TRANSPARENT: 'rgba(0,0,0,0)',
  DIVIDER_GREY: 'rgba(151,151,151,0.32)',
  DISABLED_GREY: 'rgb(151,151,151)',
  TYPOGRAPHY_BLACK: 'rgba(0, 0, 0, 0.87)',
  LIME: '#F0F4C3',
  BRAND_COLOR: '#FF6600',
  INDUSTRY_CARD_BACKGROUND: '#884422',
  CYAN_100: '#B2EBF2',
  Light_Cyan: '#E1F4FD',
  AZURE: 'rgb(4,155,229)', // *
  DUCK_EGG_BLUE: 'rgb(211,234,223)',
  SLATE: '#44606B',
  CAROLINA_BLUE: '#A8B1E3',
  PERSIAN_BLUE: '#263DBA',
  GULL_GREY: '#93AEB9',
  LIGHT_RED: '#FF5247',
  LAVENDER_MIST: '#E2E8F0',
  SLATE_BLUE_DIM: '#7d50d533',
  DARK_BG: '#1A202C',
  DIM_WHITE: 'rgba(255, 255, 255, 0.04)',
  AERO_BLUE: '#C6F6D5',
  CALIFORNIA_GOLD: '##B7791F',
  BRIGHT_BLUE: '#2196f31a',
};

/**
 * Matching AppyHere Colour palette to Chakra UI color model.
 * Complete groups of colors will be finalized in theme config.
 */
const colors = {
  transparent: APPY_PALETTE.TRANSPARENT,
  black: APPY_PALETTE.BLACK,
  white: APPY_PALETTE.WHITE,
  blackAlpha: {
    300: 'rgba(0,0,0,0.3333)',
    500: 'rgba(4, 155, 229, 0.125)', // Azure almost transparent | bgHover
  },
  backgroundAdministration: '#F3F3F3',
  // GRAY (with “a”) will match AppyPalette BlueGrays
  // We need a complete colour range for ChakraUI
  gray: {
    50: APPY_PALETTE.BLUE_GREY_50,
    100: APPY_PALETTE.BLUE_GREY_100,
    150: APPY_PALETTE.GREY_150,
    200: '#a1b7c2',
    300: APPY_PALETTE.BLUE_GREY_300,
    400: '#A0AEC0',
    450: APPY_PALETTE.BLUE_GREY_400,
    500: APPY_PALETTE.BLUE_GREY_500,
    600: '#526b77',
    700: APPY_PALETTE.BLUE_GREY_700,
    800: '#36464e',
    900: APPY_PALETTE.BLUE_GREY_900,
    1000: '#757575',
  },
  // NeutralGray will match AppyPalette Greys (with “e”)
  neutralGray: {
    50: APPY_PALETTE.GREY_50,
    100: APPY_PALETTE.GREY_100,
    300: APPY_PALETTE.LIGHT_GREY,
    400: APPY_PALETTE.GREY_400,
    500: APPY_PALETTE.GREY,
    600: APPY_PALETTE.GREY_600,
    700: APPY_PALETTE.GREY_700,
    default: APPY_PALETTE.GREY,
  },
  red: {
    50: '#FFF5F5',
    100: '#FED7D7',
    200: '#FEB2B2',
    300: '#FC8181',
    400: '#F56565',
    500: '#E53E3E',
    600: '#C53030',
    700: '#9B2C2C',
    800: '#822727',
    900: '#63171B',
  },
  orange: {
    100: APPY_PALETTE.ORANGE_100,
    400: APPY_PALETTE.ORANGE_400,
    500: APPY_PALETTE.BRAND_COLOR,
    600: APPY_PALETTE.ORANGE_600,
    700: APPY_PALETTE.ORANGE_700,
    800: APPY_PALETTE.ORANGE_800,
  },
  // BLUE will match AppyPalette LightBlues and others
  // We need a complete colour range for ChakraUI
  blue: {
    default: '#1F3CBA', // default after rebranding
    a50: '#746491',
    20: '#e9ecf8',
    30: '#d2d8f1',
    40: '#ebedf8',
    50: '#f6f8fe',
    100: '#e9edfb',
    200: '#516be1',
    300: '#3b59de',
    400: '#2546da', // gradientStart
    // 500: APPY_PALETTE.AZURE, //initial default before rebranding
    500: '#1F3CBA', // default after rebranding
    600: '#1e38ae',
    700: '#12236d', // gradientEnd
    alpha700: 'rgba(54, 131, 179, 0.8)',
    800: '#0f1c57',
    900: '#0b1541',
    icon: APPY_PALETTE.ICON_BLUE,
  },
  lightBlue: {
    400: APPY_PALETTE.LIGHT_BLUE_400,
    800: APPY_PALETTE.LIGHT_BLUE_800,
    900: APPY_PALETTE.LIGHT_BLUE_900,
  },
  iconBlue: APPY_PALETTE.ICON_BLUE,
  linkBlue: APPY_PALETTE.LINK_BLUE,
  green: {
    50: APPY_PALETTE.GREEN_50,
    200: APPY_PALETTE.GREEN_A_200,
    a200: APPY_PALETTE.GREEN_A_200,
    500: APPY_PALETTE.GREEN_500,
    400: APPY_PALETTE.GREEN_400,
    600: APPY_PALETTE.GREEN_600,
    700: APPY_PALETTE.GREEN_600,
    800: APPY_PALETTE.GREEN_600,
  },
  yellow: {
    500: '#D69E2E',
  },
  specialHighlightYellow: APPY_PALETTE.SPECIAL_HIGHLIGHT_YELLOW,
  specialHighlightBlue: APPY_PALETTE.SPECIAL_HIGHLIGHT_BLUE,
  thumbGreen: APPY_PALETTE.THUMB_GREEN,
  lightGray: APPY_PALETTE.LIGHT_GREY,
  brightGray: APPY_PALETTE.BRIGHT_GREY,
  unverifiedStatusRed: APPY_PALETTE.UNVERIFIED_STATUS_RED,
  subtleWhite: APPY_PALETTE.SUBTLE_WHITE,
  dividerGray: APPY_PALETTE.DIVIDER_GREY,
  disabledGray: APPY_PALETTE.DISABLED_GREY,
  typographyBlack: APPY_PALETTE.TYPOGRAPHY_BLACK,
  lime: APPY_PALETTE.LIME,
  cyan: {
    100: APPY_PALETTE.CYAN_100,
    lightCyan: APPY_PALETTE.Light_Cyan,
  },
  brandColor: APPY_PALETTE.BRAND_COLOR,
  industryCardBackground: APPY_PALETTE.INDUSTRY_CARD_BACKGROUND,
  azure: APPY_PALETTE.AZURE,
  slate: APPY_PALETTE.SLATE,
  slateBlueDim: APPY_PALETTE.SLATE_BLUE_DIM,
  gullGrey: APPY_PALETTE.GULL_GREY,
  lightRed: APPY_PALETTE.LIGHT_RED,
  lavenderMist: APPY_PALETTE.LAVENDER_MIST,
  carolinaBlue: APPY_PALETTE.CAROLINA_BLUE,
  persianBlue: APPY_PALETTE.PERSIAN_BLUE,
  duckEggBlue: APPY_PALETTE.DUCK_EGG_BLUE,
  customRed: APPY_PALETTE.RED_900,
  profile: {
    profileBatchColor: APPY_PALETTE.GREY_150,
    cameraIconColor: '#6A8592',
  },
  seaGreen: '#2F855A',
  menu: {
    menuItemhoverColor: '#f4f5fc',
  },
  antiFlashWhite: '#F0F1F7',
  darkBg: APPY_PALETTE.DARK_BG,
  dimWhite: APPY_PALETTE.DIM_WHITE,
  neutral: {
    50: '#F9FAFB',
    100: '#F3F4F6',
    200: '#E5E7EB',
    300: '#D1D5DB',
    400: '#9CA3AF',
    500: '#6B7280',
    600: '#4B5563',
    700: '#374151',
    800: '#1F2937',
    900: '#111827',
    950: '#030712',
  },
  whiteAlpha700: ' rgba(255, 255, 255, 0.64)',
  shinyYellow: '#F6AD55',
  aeroBlue: APPY_PALETTE.AERO_BLUE,
  input: {
    background: '#EDF2F7',
    borderColor: '#E5E7EB',
  },
  californiaGold: APPY_PALETTE.CALIFORNIA_GOLD,
  brightBlue: APPY_PALETTE.BRIGHT_BLUE,
};
export type ColorsType = typeof colors;

export default colors;
