export default {
  formBuilder: {
    title: 'Constructeur de formulaire',
    save: 'Sauvegarder',
    clearAll: 'Effacer tout',
    cancel: 'Annuler',
    loadingText: 'Nous contruisons votre modèle...',
    templateNameInputPlaceholder: 'Entrer le nom du modèle',
    signatureSwapError: 'Le composant signature doit demeurer à la fin du modèle.',
    dragBoxInfo: 'Déplacez la souris ou cliquez sur le menu Composants à la gauche pour commencer à construire votre modèle.',
    sectionInputPlaceholder: 'Entrer le nom de la section',
    sectionNameExistsMessage: 'Ce nom de section existe déjà. Vous devez choisir un autre nom.',
    deletePopoverMessage: 'Êtes-vous certain de vouloir supprimer ceci?',
    oui: 'Oui',
    non: 'Non',
    selectAField: 'Choisir un champ',
    sectionSortDrawer: {
      title: 'Modifier la section',
      save: 'Sauvegarder',
      cancel: 'Annuler',
    },
    preview: {
      title: 'Aperçu du formulaire',
      publish: 'Publier',
      deviceView: "Vue dans l'appareil",
      listView: 'Vue en liste',
      mobilePreview: {
        newHire: 'Nouvelle embauche',
        help: 'Aide',
        getStarted: 'Commencer',
        chooseFile: 'Sélectionner un fichier',
        openCamera: 'Ouvrir la caméra',
        selectDate: 'Choisir une date',
        selectTime: 'Choisir une heure',
        reviewDocument: 'Consulter le document',
        signHere: 'Signer ici',
        showMe: 'Montre-moi',
        findAddress: "Trouver l'adresse",
      },
    },
    componentSidebar: {
      tabs: {
        basic: 'Simple',
        advanced: 'Advancé',
        tags: 'Étiquettes',
      },
      searchPlaceholder: 'Rechercher les composants',
      pageElement: 'Élements du formulaire',
      components: {
        text: 'Texte',
        info: 'Info',
        email: 'Courriel',
        phone: 'Téléphone',
        link: 'Lien',
        date: 'Date',
        time: 'Heure',
        number: 'Nombre',
        radio: 'Choix unique',
        checkbox: 'Choix multiple',
        gender: 'Genre',
        yesNo: 'Oui/Non',
        address: 'Adresse',
        image: 'Image',
        file: 'Fichier',
        document: 'Document',
        singleCheckbox: 'Case à cocher',
        button: 'Bouton',
        correspondenceLanguage: 'Langue de corréspondance',
        signature: 'Signature',
        section: 'Section',
        sin: 'NAS',
        ssn: 'NSS',
        finalSignature: 'Dernière signature',
        documentPreview: 'Aperçu de document',
        canadianBank: 'Détails de banque canadienne',
        americanBank: 'Détails de banque americaine',
        earliestStartDate: 'Première date de début',
        addressValidator: "Validateur d'addresse",
        emergencyContact: "Renseignements du contact d'urgence",
        miscellaneous: 'Divers',
        bankAccountNumber: 'Numéro de compte de banque',
        routingNumber: "Numéro d'acheminement",
        branchTransitNumber: "Numéro de l'unité de la succursale",
        financialInstitutionNumber: "Numéro d'institution financière",
        checkboxDisabled: 'Case à cocher désactivé',
      },
      advancedOnceInsertMessage: '{{component}} peut seulement être inséré une fois dans un modèle.',
      onlyOneBankInsertMessage: 'Seulement un de {{component1}} ou {{component2}} peut être inséré une fois dans un modèle.',
    },
  },
  templateListView: {
    title: 'Modèles de formulaire',
    showDefault: 'Afficher les modèles par défaut',
    addTemplate: 'Ajouter un nouveau modèle',
    tableHeaders: {
      name: 'Nom',
      createdDate: 'Date de création',
      editHistory: 'Dernière modification',
      actions: 'Actions',
    },
    tableActions: {
      edit: 'Modifier',
      delete: 'Supprimer',
      copy: 'Copier',
      deleteSuccessMessage: '{{template}} a été supprimé.',
      deleteErrorMessage: 'Une erreur est survenue en essayant de supprimer {{template}}. SVP essayez de nouveau.',
    },
    paginationText: {
      showingResult: 'Affiche présentement {{currentItems}} de {{totalItems}} modèles',
    },
  },
};
