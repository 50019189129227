import { Stack, HStack, Button, Box, useToast, Text } from '@chakra-ui/core';
import React from 'react';
import { HiTrash } from 'react-icons/hi';
import { fieldLabel, formBuilderSchemas, schemaFieldsByType } from '../../formBuilderSchemas/formBuilder.schema';
import DeletePopover from '../advancedElements/DeletePopover';
import { SchemaBuilderProps, FieldSchema, MAX_FOLLOW_UP_DEPTH, formBuilderType } from '../formBuilderSchema';
import FollowUpOptionSelect from './FollowUpOptionSelect';
import FollowUpEmptyComponent from './FollowUpEmptyComponent';
import theme from '../../../../../styles/customTheme';
import ElementSelectField from '../ElementSelectField';
import DroppableElement from '../formElementBuilder/DroppableElement';
import isArray from 'lodash/isArray';
import { v4 as uuidv4 } from 'uuid';
import { useHireFormContext } from '../../HireForm.context';

interface FollowUpQuestionSchemaBuilderProps extends Omit<SchemaBuilderProps, 'onChange'> {
  followUpOptionId: string;
  followUpOptions: string[];
  setFollowUpOptions: (followUps: string[]) => void;
  index: number;
  onChange: (field: FieldSchema) => void;
}

export default function FollowUpQuestionSchemaBuilder({
  defaultValue: parentDefaultValue,
  onChange,
  language,
  followUpDragOver,
  followUpOptionId,
  followUpOptions,
  setFollowUpOptions,
  path: parentPath,
  index,
  isAdvanced,
  sectionIndex,
  followUpLabel,
}: FollowUpQuestionSchemaBuilderProps) {
  const followUpQuestions = parentDefaultValue?.followUpQuestionList?.filter((value) => value?.optionId === followUpOptionId);

  const path = [...parentPath, index];
  return (
    <Stack
      key={followUpOptionId}
      sx={{
        '&:hover .follow-up-option': {
          display: 'inline-flex',
        },
        backgroundColor: '#f9fcff99',
        border: `1px solid #e5eef7`,
        padding: theme.space[4],
        borderRadius: theme.radii.md,
      }}
      className="follow-up-container"
    >
      <HStack justify="space-between">
        <HStack>
          <Text fontSize={12} fontWeight={'normal'}>
            Show next question on:
          </Text>
          <FollowUpOptionSelect
            followUpOptions={followUpOptions}
            parentDefaultValue={parentDefaultValue}
            onChange={onChange}
            followUpOptionId={followUpOptionId}
            setFollowUpOptions={setFollowUpOptions}
            isAdvanced={isAdvanced ?? false}
          />
        </HStack>
        <HStack>
          {!isAdvanced && (
            <DeletePopover
              popoverTrigger={
                <Button
                  colorScheme="red"
                  style={{ aspectRatio: '1/1' }}
                  padding={1}
                  size="sm"
                  variant="ghost"
                  className="follow-up-option"
                >
                  <HiTrash />
                </Button>
              }
              onYes={() => {
                const newFormSchema = { ...parentDefaultValue };
                newFormSchema.followUpQuestionList?.splice(index, 1);
                onChange(newFormSchema);

                setFollowUpOptions([...followUpOptions].filter((_, i) => i !== index));
              }}
            />
          )}
        </HStack>
      </HStack>

      {followUpQuestions?.map((item, idx) => (
        <FollowupElements
          key={item.id}
          item={item}
          onChange={onChange}
          parentDefaultValue={parentDefaultValue}
          index={index}
          followUpOptionId={followUpOptionId}
          language={language}
          followUpDragOver={followUpDragOver}
          path={path}
          sectionIndex={sectionIndex}
          fieldIndex={0}
          followUpLabel={followUpLabel}
          isAdvanced={isAdvanced}
          isLastElement={followUpQuestions?.length === idx + 1}
        />
      ))}
      {!isAdvanced && !followUpQuestions?.length && (
        <FollowUpEmptyComponent
          followUpOptionId={followUpOptionId}
          defaultValue={parentDefaultValue}
          onChange={onChange}
          language={language}
          followUpDragOver={followUpDragOver}
          path={path}
          sectionIndex={sectionIndex}
          fieldIndex={index}
          followUpLabel={followUpLabel}
        />
      )}
    </Stack>
  );
}

interface FollowupElementsProps extends Omit<SchemaBuilderProps, 'defaultValue'> {
  item: FieldSchema;
  parentDefaultValue: FieldSchema;
  index: number;
  followUpOptionId: string;
  isLastElement: boolean;
}
function FollowupElements({
  item,
  onChange,
  parentDefaultValue,
  index,
  language,
  followUpDragOver,
  followUpOptionId,
  path,
  sectionIndex,
  followUpLabel,
  isAdvanced,
}: FollowupElementsProps) {
  const Component = formBuilderSchemas?.[item?.inputType as keyof typeof formBuilderSchemas]?.component;
  const { draggedElement } = useHireFormContext();
  const toast = useToast();
  if (!Component) return null;

  const elementFields =
    schemaFieldsByType?.[item.field as keyof typeof schemaFieldsByType] || schemaFieldsByType?.[item.inputType];

  return (
    <DroppableElement
      index={index}
      onDrop={(type, dropTarget) => {
        if (followUpLabel >= MAX_FOLLOW_UP_DEPTH && type === formBuilderType.yesNo) {
          toast({
            description: `Only ${MAX_FOLLOW_UP_DEPTH} nested level of follow-ups question is allowed`,
            status: 'error',
            isClosable: true,
          });
          return;
        }
        if (type === formBuilderType.documentReview) {
          toast({
            description: 'Document review is not allowed in follow-up question',
            status: 'error',
            isClosable: true,
          });
          return;
        }
        const schema = formBuilderSchemas[type as keyof typeof formBuilderSchemas];
        if (schema.panel === 'advanced' || isArray(schema.defaultValue)) return;

        if (type !== 'section') {
          const followUpQuestions = [...(parentDefaultValue?.followUpQuestionList ?? [])];
          const defaultValue = schema.defaultValue || {};
          let spliceIndex = index;
          if (index === followUpQuestions.length - 1 && dropTarget === 'bottom') {
            spliceIndex = index + 1;
          } else {
            if (dropTarget === 'top') {
              spliceIndex = index;
            } else {
              spliceIndex = index;
            }
          }
          followUpQuestions?.splice(spliceIndex, 0, {
            ...defaultValue,
            id: uuidv4(),
            parent: parentDefaultValue.id,
            optionId: followUpOptionId,
            timestamp: Date.now(),
            isFollowUp: true,
          });

          const newSchema = {
            ...parentDefaultValue,
            followUpQuestionList: followUpQuestions,
          };

          onChange(newSchema);
        }
      }}
      isDisabled={isAdvanced}
      isLastElement
      acceptedTypes={['basic']}
      currentType={draggedElement?.panel!}
    >
      <Box position="relative">
        <Component
          defaultValue={item!}
          onChange={(value) => {
            const newFollowUpQuestion = [...(parentDefaultValue?.followUpQuestionList ?? [])];
            const index = newFollowUpQuestion?.findIndex((v) => v.id === item.id) ?? -1;
            if (index >= 0) {
              newFollowUpQuestion[index] = { ...newFollowUpQuestion[index], ...value };
            }

            const result = {
              ...parentDefaultValue,
              followUpQuestionList: newFollowUpQuestion,
            };
            onChange(result);
          }}
          language={language}
          followUpDragOver={followUpDragOver}
          path={path}
          sectionIndex={sectionIndex}
          fieldIndex={index}
          followUpLabel={followUpLabel}
          labelRightRenderer={
            <HStack>
              {((elementFields && elementFields.length > 0) || !item.hideFieldSelector) && !isAdvanced && (
                <ElementSelectField
                  defaultValue={item}
                  options={elementFields ?? []}
                  onChange={(field) => {
                    const newFollowUpQuestion = [...(parentDefaultValue?.followUpQuestionList ?? [])];
                    const ind = newFollowUpQuestion?.findIndex((v) => v.id === item.id) ?? -1;
                    const currentFollowUpQuestion = { ...newFollowUpQuestion[ind] };
                    if (ind >= 0) {
                      newFollowUpQuestion[ind] = {
                        ...currentFollowUpQuestion,
                        field,
                        label: {
                          ...currentFollowUpQuestion.label!,
                          [language]: fieldLabel[field as keyof typeof fieldLabel],
                        },
                      };
                    }

                    const result = {
                      ...parentDefaultValue,
                      followUpQuestionList: newFollowUpQuestion,
                    };
                    onChange(result);
                  }}
                />
              )}
              {!isAdvanced && (
                <DeletePopover
                  popoverTrigger={
                    <Button
                      colorScheme="red"
                      style={{ aspectRatio: '1/1' }}
                      padding={1}
                      size="sm"
                      variant="ghost"
                      className="follow-up-option"
                      zIndex={1}
                    >
                      <HiTrash />
                    </Button>
                  }
                  onYes={() => {
                    const newFormSchema = { ...parentDefaultValue };
                    const ind = newFormSchema.followUpQuestionList?.findIndex((v) => v.id === item.id) ?? -1;
                    newFormSchema.followUpQuestionList?.splice(ind, 1);

                    onChange(newFormSchema);
                  }}
                />
              )}
            </HStack>
          }
        />
      </Box>
    </DroppableElement>
  );
}
