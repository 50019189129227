import { Tabs, TabList, Tab, TabPanels, TabPanel, TabProps, TabPanelProps } from '@chakra-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import theme from '../../../styles/customTheme';
import FileUploadView from '../upload/FileUploadView';
import DocumentSettings from './DocumentSettings';
import { useStoreState } from '../../../models/hooks';

export default function CompanyManagementView() {
  const { t } = useTranslation('administration');
  const isHireFormTemplateEnabled = useStoreState((s) => s.app.accounts?.configuration?.hireFormTemplateEnabled) ?? false;

  return (
    <Tabs>
      <TabList
        style={{
          backgroundColor: theme.colors.white,
          borderRadius: theme.radii.md,
          fontSize: theme.fontSizes.sm,
          fontWeight: theme.fontWeights.bold,
          border: 'none',
          paddingLeft: theme.space[2],
          paddingRight: theme.space[2],
          height: '54px',
        }}
      >
        <ComponentTab>{t('companyResources.companyLogo')}</ComponentTab>
        {isHireFormTemplateEnabled && <ComponentTab>{t('companyResources.documentSettings')}</ComponentTab>}
      </TabList>
      <TabPanels marginTop={theme.space[4]}>
        <ComponentTabPanel>
          <FileUploadView />
        </ComponentTabPanel>
        <ComponentTabPanel style={{ backgroundColor: 'transparent' }}>
          <DocumentSettings />
        </ComponentTabPanel>
      </TabPanels>
    </Tabs>
  );
}

const ComponentTab = ({ style, ...props }: TabProps) => {
  return <Tab style={{ fontSize: 'inherit', fontWeight: 'inherit', ...style }} _focus={{ outline: 'none' }} {...props} />;
};

const ComponentTabPanel = ({ style, ...props }: TabPanelProps) => {
  return (
    <TabPanel
      style={{
        borderRadius: theme.radii.md,
        overflow: 'hidden',
        backgroundColor: theme.colors.white,
        padding: 0,
        ...style,
      }}
      {...props}
    />
  );
};
