import { Stack, theme, HStack, Button, InputGroup, Input, InputRightElement, Box } from '@chakra-ui/core';
import { debounce } from 'lodash';
import React from 'react';
import { IoSearch } from 'react-icons/io5';
import { MdChevronLeft } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import ComponentTabs from './ComponentTabs';
import { useTranslation } from 'react-i18next';

interface FormComponentContainer {}

const FormComponentContainer = React.memo(() => {
  const history: any = useHistory();
  const { t } = useTranslation('hrFormTemplate');
  const [search, setSearch] = React.useState<string>('');

  return (
    <Box
      flex="0 0 25%"
      spacing={theme.space[4]}
      backgroundColor={theme.colors.white}
      px={theme.space[4]}
      py={theme.space[6]}
      minHeight="0"
      height="100%"
      overflowY="auto"
      overflowX="hidden"
      data-testid="form-component-container"
    >
      <Stack overflowY="auto" overflowX="hidden">
        <HStack spacing={theme.space[4]}>
          <Button
            size="sm"
            variant="solid"
            paddingX={1}
            borderRadius="50%"
            colorScheme="gray"
            onClick={() => history.goBack()}
            data-testid="back-button"
          >
            <MdChevronLeft fontSize={24} />
          </Button>
          <InputGroup variant="outline">
            <Input
              type="search"
              placeholder={t('formBuilder.componentSidebar.searchPlaceholder')}
              onChange={(e) => {
                const value = e.target.value;
                const searchDebounce = debounce((val: string) => setSearch(val), 200);
                searchDebounce(value);
              }}
              data-testid="component-sidebar-search-input"
            />
            <InputRightElement paddingX={2} children={<IoSearch size={16} color="#2D3748" />} />
          </InputGroup>
        </HStack>
        <ComponentTabs search={search} />
      </Stack>
    </Box>
  );
});

export default FormComponentContainer;
