import React from 'react';
import { HStack, Stack } from '@chakra-ui/core';
import OptionSchemaBuilder from './OptionSchemaBuilder';
import { FieldSchema, SchemaBuilderProps } from './formBuilderSchema';
import FormElementTextBuilder from './FormElementTextBuilder';
import LabelSchemaBuilder from './LabelSchemaBuilder';

export default function RadioSchemaBuilder(props: SchemaBuilderProps) {
  const { onChange, defaultValue } = props;
  const handleChange = (key: keyof FieldSchema | keyof FieldSchema['validations'], value: any) => {
    onChange({ ...defaultValue, [key]: value });
  };

  return (
    <Stack>
      <HStack justify="space-between">
        <LabelSchemaBuilder {...props} />
        {props?.labelRightRenderer}
      </HStack>
      <FormElementTextBuilder {...props} placeholder="Enter text here for single choice input..." />
      <OptionSchemaBuilder
        options={defaultValue?.optionList || []}
        handleChange={(options) => handleChange('optionList', options)}
        language={props.language}
        type="radio"
        path={props.path}
      />
    </Stack>
  );
}
