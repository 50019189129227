import React, { useRef } from 'react';
import { Box, Container, TabPanels, Tabs } from '@chakra-ui/core';
import { Redirect, useParams } from 'react-router-dom';
import Information from './info/Information';
import { CertnIntegrationView } from './certn/CertnIntegrationView';
import { useStoreState } from '../../models/hooks';
import { RemoteConfigKey } from '../../firebase/remoteConfig';
import { LowAvailabilityView } from './lowAvailability/LowAvailabilityView';
import { ChangeActiveHandler, PositionManagementView } from './positionManagement/PositionManagementView';
import { RecruiterManagementView } from './recruiterManagement/RecruiterManagementView';
import { AdministrationTabsParams } from '../../routes/constants';
import { RecruiterManagementContextProvider } from './recruiterManagement/RecruiterManagementContext';
import UnresponsivePeriodView from './agedPeriod/UnresponsivePeriodView';
import { TeamView } from './team/TeamView';
import HireFormApiKeyView from './HireFormAPIKey/HireFormAPIKeyView';
import ModernHireConfigView from './ModernHireConfigView/ModernHireConfigView';
import AdministrationSidebar from './AdministrationSidebar';
import { AdministrationMenuItemEnum } from './AdministrationMenuItemEnum';
// import FileUploadView from './upload/FileUploadView';
// import LanguageEvaluationPhrasesView from './languageEvaluationPhrases/LanguageEvaluationPhrasesView';
import { VideoGalleryView } from './videoGallery/VideoGalleryView';
import { WebHookUrlView } from './webHookUrl/WebHookUrlView';
import ImageGalleryView from './imageGallery/ImageGalleryView';
import { LanguageEvaluationView } from './languageEvaluation/LanguageEvaluationView';
import CompanyManagementView from './companyManagement/CompanyManagementView';
import HRFormTemplateView from './hireForm/HRFormTemplateView';

export const AdministrationView = (): JSX.Element => {
  const { tab } = useParams<AdministrationTabsParams>();

  const isPositionManagementFeatureEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_POSITION_MANAGEMENT_FEATURE];
  });

  const isRecruiterManagementFeatureEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_RECRUITER_MANAGEMENT_FEATURE];
  });

  const isTeamAvailabilityFeatureEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_TEAM_AVAILABILITY];
  });

  const isTeamManagementFeatureEnabled = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_TEAM_MANAGEMENT_FEATURE];
  });

  const isVideoGalleryEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_VIDEO_GALLERY];
  });

  const isWebAppyEnabled = useStoreState((s) => s.app.accounts?.configuration?.webApplyEnabled);
  const isCertnEnabled = useStoreState((s) => s.app.accounts?.configuration?.certnEnabled);
  const isHireFormEnabled = useStoreState((s) => s.app.accounts?.configuration?.hireFormEnabled);
  const superOwners = useStoreState((s) => s.app.accounts?.superOwners);
  const userId = useStoreState((s) => s.app.user?.id);
  const isHireFormTemplateEnabled = useStoreState((s) => s.app.accounts?.configuration?.hireFormTemplateEnabled);

  const positionManagementRef = useRef<ChangeActiveHandler>(null);
  const administrationViewRef = useRef<null | HTMLDivElement>(null);
  const positionManagementTabRef = useRef<null | HTMLButtonElement>(null);

  const isSuperAdmin = (): boolean => {
    if (!superOwners || !userId) {
      return false;
    }
    return superOwners?.includes(userId);
  };

  const getPageComponent = (pathName: AdministrationMenuItemEnum) => {
    switch (pathName) {
      case AdministrationMenuItemEnum.RECRUITER_MANAGEMENT:
      case undefined:
        return isRecruiterManagementFeatureEnabled ? (
          <RecruiterManagementContextProvider>
            <RecruiterManagementView
              onSetActiveTab={() => {
                positionManagementTabRef?.current?.click();
                administrationViewRef?.current?.scrollTo(0, 0);
              }}
            />
          </RecruiterManagementContextProvider>
        ) : (
          ''
        );
      case AdministrationMenuItemEnum.TEAM_LOW_AVAILABILITY:
        return isTeamAvailabilityFeatureEnabled ? <LowAvailabilityView /> : '';
      case AdministrationMenuItemEnum.POSITION_MANAGEMENT:
        return isPositionManagementFeatureEnabled ? <PositionManagementView ref={positionManagementRef} /> : '';
      case AdministrationMenuItemEnum.TEAM_MANAGEMENT:
        return isTeamManagementFeatureEnabled ? <TeamView /> : '';
      case AdministrationMenuItemEnum.CERTN_INTEGRATION:
        return isCertnEnabled ? <CertnIntegrationView /> : '';
      case AdministrationMenuItemEnum.JOB_LISTING_URL:
        return isWebAppyEnabled ? <Information /> : '';
      // case AdministrationMenuItemEnum.UPLOAD_COMPANY_LOGO:
      //   return <FileUploadView />;
      case AdministrationMenuItemEnum.COMPANY_MANAGEMENT:
        return <CompanyManagementView />;
      case AdministrationMenuItemEnum.UNRESPONSIVE_PERIOD:
        return <UnresponsivePeriodView />;
      case AdministrationMenuItemEnum.HIRE_FORM_API_KEY:
        return isSuperAdmin() ? <HireFormApiKeyView /> : '';
      case AdministrationMenuItemEnum.MODERN_HIRE:
        return isSuperAdmin() ? <ModernHireConfigView /> : '';
      case AdministrationMenuItemEnum.TEMPLATES:
        return <HRFormTemplateView />;
      case AdministrationMenuItemEnum.LANGUAGE_EVALUATION:
        return <LanguageEvaluationView />;
      case AdministrationMenuItemEnum.VIDEO_GALLERY:
        return <VideoGalleryView addVideoToPosition={false} onSetVideo={/* istanbul ignore next */ () => {}} />;
      case AdministrationMenuItemEnum.IMAGE_GALLERY:
        return <ImageGalleryView />;
      case AdministrationMenuItemEnum.WEB_HOOK_URL:
        return <WebHookUrlView />;
      default:
        return '';
    }
  };

  if (!tab) {
    return <Redirect to={`administration/${AdministrationMenuItemEnum.RECRUITER_MANAGEMENT}`} />;
  }

  return (
    <Box py={8} backgroundColor="#F3F3F3" height="calc(100vh - 64px)" overflowY="auto" ref={administrationViewRef}>
      <Container maxW="98%">
        <Tabs d="flex" colorScheme="blue" data-testid="ChangeTabIndexBtn" orientation="vertical" isLazy>
          <AdministrationSidebar
            accountMenuItemEnabledCheck={{
              isRecruiterManagementFeatureEnabled,
              isPositionManagementFeatureEnabled,
              isTeamAvailabilityFeatureEnabled,
              isTeamManagementFeatureEnabled,
              isWebAppyEnabled: isWebAppyEnabled ?? false,
              isCertnEnabled: isCertnEnabled ?? false,
              isHireFormEnabled: isHireFormEnabled ?? false,
              isSuperAdmin: isSuperAdmin(),
              isVideoGalleryEnabled,
              isHireFormTemplateEnabled: isHireFormTemplateEnabled ?? false,
            }}
          />
          <TabPanels w="80%">{getPageComponent(tab)}</TabPanels>
        </Tabs>
      </Container>
    </Box>
  );
};
