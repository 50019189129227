import { Box, Button, Center, Divider, HStack, Stack, Text } from '@chakra-ui/core';
import React from 'react';
import theme from '../../../../styles/customTheme';
import { FieldSchema, formBuilderType, Language, SectionSchema } from './formBuilderSchema';
import { HiOutlineDeviceMobile, HiMenuAlt4 } from 'react-icons/hi';
import { MdOutlineCircle, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md';
import MobilePreviewView from '../mobilePreview/MobilePreviewView';
import { useHireFormContext } from '../HireForm.context';
import { useTranslation } from 'react-i18next';

const previewModes = {
  device: {
    icon: <HiOutlineDeviceMobile />,
    label: 'deviceView',
  },
  list: {
    icon: <HiMenuAlt4 />,
    label: 'listView',
  },
} as const;

export default function FormPreview() {
  const { t } = useTranslation('hrFormTemplate');
  const { formSchema: schema, language } = useHireFormContext();
  const [previewMode, setPreviewMode] = React.useState<keyof typeof previewModes>('device');
  return (
    <Box minHeight={0} height="100%" background={theme.colors.white} paddingY={theme.space[6]} paddingX={theme.space[4]}>
      <Stack minHeight={0} height="100%">
        <HStack justify="space-between">
          <Text fontSize="lg" fontWeight="bold">
            {t('formBuilder.preview.title')}
          </Text>
        </HStack>
        <HStack justify="flex-end">
          <Button
            size="sm"
            colorScheme="gray"
            rightIcon={previewModes[previewMode].icon}
            onClick={() => setPreviewMode((prev) => (prev === 'device' ? 'list' : 'device'))}
          >
            {t(`formBuilder.preview.${previewModes[previewMode].label}`)}
          </Button>
        </HStack>
        {previewMode === 'list' && (
          <Box flexGrow={1} overflowY={'auto'} overflowX={'hidden'}>
            <Stack
              sx={{
                '& ol, & ul': {
                  margin: 'revert',
                  padding: 'revert',
                },
              }}
              divider={<Divider />}
            >
              {schema.map((item, index) => (
                <SectionPreview key={index} schema={item} language={language} />
              ))}
            </Stack>
          </Box>
        )}
        {previewMode === 'device' && (
          <Center flexGrow={1}>
            <MobilePreviewView schema={schema} />
          </Center>
        )}
      </Stack>
    </Box>
  );
}

function SectionPreview({ schema, language }: { schema: SectionSchema; language: Language }) {
  return (
    <Box>
      <Text fontWeight="bold" fontSize="xs">
        {schema.section}
      </Text>
      <Stack spacing={2}>
        {schema.fields
          ?.filter((field) => !field?.isFrontEndOnly)
          .map((field) => (
            <ElementPreview key={field.id} schema={field} language={language} />
          ))}
      </Stack>
    </Box>
  );
}

function ElementPreview({ schema, language }: { schema: FieldSchema; language: Language }) {
  return (
    <Box
      borderRadius={theme.radii.md}
      fontSize={theme.fontSizes.sm}
      paddingLeft={2}
      sx={{
        span: {
          fontWeight: 'bold',
        },
      }}
    >
      <Text fontWeight="bold" fontSize="xs">
        {schema?.label?.[language]}
      </Text>
      <Text fontSize="xs" dangerouslySetInnerHTML={{ __html: schema?.text?.[language] ?? '' }} />
      <Box marginTop={1}>
        <Stack spacing={0.2}>
          {schema?.optionList?.map((option) => (
            <Stack spacing={1}>
              <HStack spacing={1} align={formBuilderType.yesNo === schema.inputType ? 'flex-start' : 'center'}>
                {formBuilderType.checkbox === schema.inputType && <MdOutlineCheckBoxOutlineBlank color="blue" />}
                {formBuilderType.radio === schema.inputType && <MdOutlineCircle color="blue" />}
                {formBuilderType.yesNo === schema.inputType && (
                  <>
                    <Box
                      border={'1px solid blue'}
                      lineHeight={1}
                      fontSize="xs"
                      fontWeight="bold"
                      paddingX={1}
                      paddingY={1}
                      borderRadius={theme.radii.md}
                    >
                      {option.key}
                    </Box>
                    <FollowUpPreview paddingLeft={1} key={option.id} optionId={option.id} schema={schema} language={language} />
                  </>
                )}
                {formBuilderType.yesNo !== schema.inputType && (
                  <Text fontSize="xs" key={option.id} dangerouslySetInnerHTML={{ __html: option.text?.[language] ?? '' }} />
                )}
              </HStack>
              {formBuilderType.yesNo !== schema.inputType && (
                <FollowUpPreview key={option.id} optionId={option.id} schema={schema} language={language} />
              )}
            </Stack>
          ))}
        </Stack>
      </Box>
    </Box>
  );
}

function FollowUpPreview({
  optionId,
  schema,
  language,
  paddingLeft = 8,
}: {
  optionId: string;
  schema: FieldSchema;
  language: Language;
  paddingLeft?: number;
}) {
  if (!schema?.followUpQuestionList?.length && schema.followUpQuestionList?.findIndex((v) => v.optionId === optionId) === -1)
    return null;
  const followUps = schema.followUpQuestionList?.filter((v) => v.optionId === optionId);
  return (
    <Box paddingLeft={paddingLeft}>
      {followUps?.map((followUp) => (
        <ElementPreview key={followUp.id} schema={followUp!} language={language} />
      ))}
    </Box>
  );
}
