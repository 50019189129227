export default {
  formBuilder: {
    title: 'Form Builder',
    save: 'Save',
    clearAll: 'Clear All',
    cancel: 'Cancel',
    loadingText: "We're building your template...",
    templateNameInputPlaceholder: 'Enter Template Name',
    signatureSwapError: 'The signature component must remain at the end of the template',
    dragBoxInfo: 'Drag or click on the Components menu to the left to begin building your template.',
    sectionInputPlaceholder: 'Enter Section Name',
    sectionNameExistsMessage: 'This section name already exists. You must choose a different name.',
    deletePopoverMessage: 'Are you sure you want to delete this?',
    yes: 'Yes',
    no: 'No',
    selectAField: 'Select a Field',
    sectionSortDrawer: {
      title: 'Edit Section',
      save: 'Save',
      cancel: 'Cancel',
    },
    preview: {
      title: 'Form Preview',
      publish: 'Publish',
      deviceView: 'Device View',
      listView: 'List View',
      mobilePreview: {
        newHire: 'New Hire',
        help: 'Help',
        getStarted: 'Get Started',
        chooseFile: 'Choose a File',
        openCamera: 'Open Camera',
        selectDate: 'Select Date',
        selectTime: 'Select Time',
        reviewDocument: 'Review Document',
        signHere: 'Sign Here',
        showMe: 'Show Me',
        findAddress: 'Find Address',
      },
    },
    componentSidebar: {
      tabs: {
        basic: 'Basic',
        advanced: 'Advanced',
        tags: 'Tags',
      },
      searchPlaceholder: 'Search Components',
      pageElement: 'Form Elements',
      components: {
        text: 'Text',
        info: 'Info',
        email: 'Email',
        phone: 'Phone',
        link: 'Link',
        date: 'Date',
        time: 'Time',
        number: 'Number',
        radio: 'Single Choice',
        checkbox: 'Multiple Choice',
        gender: 'Gender',
        yesNo: 'Yes/No',
        address: 'Address',
        image: 'Image',
        file: 'File',
        document: 'Document',
        singleCheckbox: 'Checkbox',
        button: 'Button',
        correspondenceLanguage: 'Correspondence Language',
        signature: 'Signature',
        section: 'Section',
        sin: 'SIN',
        ssn: 'SSN',
        finalSinature: 'Final Signature',
        documentPreview: 'Document Preview',
        canadianBank: 'Canadian Bank',
        americanBank: 'American Bank',
        earliestStartDate: 'Earliest Start Date',
        addressValidator: 'Address Validator',
        emergencyContact: 'Emergency Contact Details',
        miscellaneous: 'Miscellaneous',
        bankAccountNumber: 'Bank Account Number',
        routingNumber: 'Routing Number',
        branchTransitNumber: 'Branch Transit Number',
        financialInstitutionNumber: 'Financial Institution Number',
        checkboxDisabled: 'Checkbox Disabled',
      },
      advancedOnceInsertMessage: '{{component}} can only be inserted once time per template.',
      onlyOneBankInsertMessage: 'Only one of {{component1}} or {{component2}} can only be inserted once per template.',
    },
  },
  templateListView: {
    title: 'Form Templates',
    showDefault: 'Show Defaults',
    addTemplate: 'Add New Template',
    tableHeaders: {
      name: 'Name',
      createdDate: 'Created Date',
      editHistory: 'Last Edited',
      actions: 'Actions',
    },
    tableActions: {
      edit: 'Edit',
      delete: 'Delete',
      copy: 'Copy',
      deleteSuccessMessage: '{{template}} has been deleted.',
      deleteErrorMessage: 'Error deleting {{template}}. Please try again.',
    },
    paginationText: {
      showingResult: 'Showing {{currentItems}} of {{totalItems}} templates',
    },
  },
};
