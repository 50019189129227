import { BsInputCursorText } from 'react-icons/bs';
import {
  AdvancedFormBuilderTypes,
  BasicFormBuilderTypes,
  FieldSchema,
  FormBuilderSchema,
  formBuilderType,
  FormBuilderTypes,
  Sections,
} from '../formElements/formBuilderSchema';
import TextFieldSchemaBuilder from '../formElements/TextFieldSchemaBuilder';
import { v4 as uuidv4 } from 'uuid';
import {
  MdFiberPin,
  MdLink,
  MdMiscellaneousServices,
  MdOutlineAccessTime,
  MdOutlineArticle,
  MdOutlineBackup,
  MdOutlineCheckBox,
  MdOutlinePerson,
  MdOutlinePin,
  MdSmartButton,
  MdTextFields,
  MdViewAgenda,
  MdContactPhone,
} from 'react-icons/md';
import EmailSchemaBuilder from '../formElements/EmailSchemaBuilder';
import { IoCalendar, IoCall, IoCheckbox, IoImage, IoLocation, IoMail, IoRadioButtonOnOutline, IoToggle } from 'react-icons/io5';
import PhoneSchemaBuilder from '../formElements/PhoneSchemaBuilder';
import LinkSchemaBuilder from '../formElements/LinkSchemaBuilder';
import DatePickerSchemaBuilder from '../formElements/DatePickerSchemaBuilder';
import TimePickerSchemaBuilder from '../formElements/TimePickerSchemaBuilder';
import NumberSchemaBuilder from '../formElements/NumberSchemaBuilder';
import RadioSchemaBuilder from '../formElements/RadioSchemaBuilder';
import MCQSchemaBuilder from '../formElements/MCQSchemaBuilder';
import YesNoSchemaBuilder from '../formElements/YesNoSchemaBuilder';
import AddressSchemaBuilder from '../formElements/AddressSchemaBuilder';
import ImageSchemaBuilder from '../formElements/ImageSchemaBuilder';
import CheckBoxSchemaBuilder from '../formElements/CheckBoxSchemaBuilder';
import ButtonSchemaBuilder from '../formElements/ButtonSchemaBuilder';
import { FaLanguage, FaSignature } from 'react-icons/fa';
import SignatureSchemaBuilder from '../formElements/SignatureSchemaBuilder';
import { AiFillBank } from 'react-icons/ai';
import DocumentPreview from '../formElements/advancedElements/documentPreview';
import CheckboxDisable from '../formElements/CheckboxDisable';

export const fields = [
  'name',
  'email',
  'gender',
  'dateOfBirth',
  'correspondenceLanguage',
  'phoneNumber',
  'optionalPhoneNumber',
  'socialInsuranceNumber',
  'completeAddress',
  'postalCode',
  'apartment',
  'emergencyContact',
  'signatureImageUrl',
  'chequebookImageUrl',
  'workPermitImageUrl',
  'workPermitEndDate',
  'address',
  'documentIdImageUrl',
  'firstName',
  'lastName',
  'nickname',
  'bankAccountNumber',
  'routingNumber',
  'branchTransitNumber',
  'financialInstitutionNumber',
  'addressLineOne',
  'addressLineTwo',
  'city',
  'region',
  'postalCode',
  'country',
  'contractDetailsWordings',
  'emergencyContactFirstName',
  'emergencyContactLastName',
  'emergencyContactPhoneNumber',
  'codeofconductandethics',
  'privacynoticeforemployees',
  'electronicmonitoringpolicy',
];

export enum schemaFields {
  name = 'name',
  email = 'email',
  gender = 'gender',
  dateOfBirth = 'dateOfBirth',
  correspondenceLanguage = 'correspondenceLanguage',
  phoneNumber = 'phoneNumber',
  optionalPhoneNumber = 'optionalPhoneNumber',
  socialInsuranceNumber = 'socialInsuranceNumber',
  socialSecurityNumber = 'socialSecurityNumber',
  completeAddress = 'completeAddress',
  apartment = 'apartment',
  emergencyContact = 'emergencyContact',
  signatureImageUrl = 'signatureImageUrl',
  chequebookImageUrl = 'chequebookImageUrl',
  workPermitImageUrl = 'workPermitImageUrl',
  workPermitEndDate = 'workPermitEndDate',
  address = 'address',
  documentIdImageUrl = 'documentIdImageUrl',
  firstName = 'firstName',
  lastName = 'lastName',
  nickname = 'nickname',
  bankAccountNumber = 'bankAccountNumber',
  routingNumber = 'routingNumber',
  branchTransitNumber = 'branchTransitNumber',
  financialInstitutionNumber = 'financialInstitutionNumber',
  addressLineOne = 'addressLineOne',
  addressLineTwo = 'addressLineTwo',
  city = 'city',
  region = 'region',
  postalCode = 'postalCode',
  country = 'country',
  contractDetailsWordings = 'contractDetailsWordings',
  emergencyContactFirstName = 'emergencyContactFirstName',
  emergencyContactLastName = 'emergencyContactLastName',
  emergencyContactPhoneNumber = 'emergencyContactPhoneNumber',
  endDate = 'endDate',
  codeofconductandethics = 'codeofconductandethics',
  privacynoticeforemployees = 'privacynoticeforemployees',
  electronicmonitoringpolicy = 'electronicmonitoringpolicy',
}

export const inputFieldLabel: Record<BasicFormBuilderTypes, string> = {
  [formBuilderType.text]: 'Text',
  [formBuilderType.info]: 'Info',
  [formBuilderType.number]: 'Number',
  [formBuilderType.date]: 'Date',
  [formBuilderType.time]: 'Time',
  [formBuilderType.phone]: 'Phone',
  [formBuilderType.yesNo]: 'Yes/No',
  [formBuilderType.checkbox]: 'Multiple Choice',
  [formBuilderType.radio]: 'Single Choice',
  [formBuilderType.image]: 'Image',
  [formBuilderType.file]: 'File',
  [formBuilderType.link]: 'Link',
  [formBuilderType.button]: 'Button',
  [formBuilderType.singleCheckbox]: 'Checkbox',
  [formBuilderType.documentReview]: 'Document Review',
  [formBuilderType.checkboxDisable]: 'Checkbox Disable',
  [formBuilderType.sin]: 'Social Insurance Number',
  [formBuilderType.ssn]: 'Social Security Number',
  [formBuilderType.signature]: 'Signature',
  [schemaFields.bankAccountNumber]: 'Bank Account Number',
  [schemaFields.routingNumber]: 'Routing Number',
  [schemaFields.branchTransitNumber]: 'Branch Transit Number',
  [schemaFields.financialInstitutionNumber]: 'Financial Institution Number',
  [schemaFields.correspondenceLanguage]: 'Correspondence Language',
  [schemaFields.gender]: 'Gender',
  [schemaFields.email]: 'Email',
  [schemaFields.address]: 'Address',
};

export const defaultFieldLabel: Record<schemaFields, string> = {
  [schemaFields.name]: 'Name',
  [schemaFields.dateOfBirth]: 'Date of Birth',
  [schemaFields.phoneNumber]: 'Phone Number',
  [schemaFields.optionalPhoneNumber]: 'Optional Phone Number',
  [schemaFields.socialInsuranceNumber]: 'Social Insurance Number',
  [schemaFields.socialSecurityNumber]: 'Social Security Number',
  [schemaFields.completeAddress]: 'Complete Address',
  [schemaFields.apartment]: 'Apartment',
  [schemaFields.emergencyContact]: 'Emergency Contact',
  [schemaFields.signatureImageUrl]: 'Signature',
  [schemaFields.chequebookImageUrl]: 'Cheque',
  [schemaFields.workPermitImageUrl]: 'Work Permit',
  [schemaFields.workPermitEndDate]: 'Work Permit End Date',
  [schemaFields.documentIdImageUrl]: 'Document ID',
  [schemaFields.firstName]: 'First Name',
  [schemaFields.lastName]: 'Last Name',
  [schemaFields.nickname]: 'Nickname',
  [schemaFields.addressLineOne]: 'Address Line 1',
  [schemaFields.addressLineTwo]: 'Address Line 2',
  [schemaFields.city]: 'City',
  [schemaFields.region]: 'Region',
  [schemaFields.postalCode]: 'Postal Code',
  [schemaFields.country]: 'Country',
  [schemaFields.contractDetailsWordings]: 'Contract Details Wordings',
  [schemaFields.emergencyContactFirstName]: 'Emergency Contact First Name',
  [schemaFields.emergencyContactLastName]: 'Emergency Contact Last Name',
  [schemaFields.emergencyContactPhoneNumber]: 'Emergency Contact Phone Number',
  [schemaFields.endDate]: 'End Date',
  [schemaFields.codeofconductandethics]: 'Code of Conduct and Ethics',
  [schemaFields.privacynoticeforemployees]: 'Privacy Notice for Employees',
  [schemaFields.electronicmonitoringpolicy]: 'Electronic Monitoring Policy',
  [schemaFields.bankAccountNumber]: 'Bank Account Number',
  [schemaFields.routingNumber]: 'Routing Number',
  [schemaFields.branchTransitNumber]: 'Branch Transit Number',
  [schemaFields.financialInstitutionNumber]: 'Financial Institution Number',
  [schemaFields.correspondenceLanguage]: 'Correspondence Language',
  [schemaFields.gender]: 'Gender',
  [schemaFields.email]: 'Email',
  [schemaFields.address]: 'Address',
};

export const fieldLabel: Record<schemaFields | BasicFormBuilderTypes, string> = {
  ...defaultFieldLabel,
  ...inputFieldLabel,
} as const;

export const schemaFieldsByType: Record<FormBuilderTypes, string[] | null> = {
  [formBuilderType.text]: [
    formBuilderType.text,
    schemaFields.name,
    schemaFields.firstName,
    schemaFields.lastName,
    schemaFields.nickname,
    schemaFields.apartment,
  ],
  [formBuilderType.number]: [formBuilderType.number, schemaFields.postalCode],
  [formBuilderType.email]: [],
  [formBuilderType.phone]: [formBuilderType.phone, schemaFields.phoneNumber, schemaFields.optionalPhoneNumber],
  [formBuilderType.date]: [formBuilderType.date, schemaFields.dateOfBirth, schemaFields.endDate],
  [formBuilderType.radio]: [],
  [formBuilderType.address]: [],
  [formBuilderType.gender]: [],
  [formBuilderType.image]: [formBuilderType.image, schemaFields.documentIdImageUrl],
  [formBuilderType.file]: [],
  [formBuilderType.yesNo]: [],
  [formBuilderType.checkbox]: [],
  [formBuilderType.link]: [],
  [formBuilderType.button]: [],
  [schemaFields.bankAccountNumber]: [schemaFields.bankAccountNumber],
  [schemaFields.routingNumber]: [schemaFields.routingNumber],
  [schemaFields.branchTransitNumber]: [schemaFields.branchTransitNumber],
  [schemaFields.financialInstitutionNumber]: [schemaFields.financialInstitutionNumber],
  [formBuilderType.info]: [],
  [formBuilderType.singleCheckbox]: [],
  [formBuilderType.time]: [],
  [formBuilderType.documentReview]: [],
  [formBuilderType.checkboxDisable]: [],
  [formBuilderType.correspondenceLanguage]: [],
  [formBuilderType.sin]: [],
  [formBuilderType.ssn]: [],
  [formBuilderType.signature]: [],
  // ADVANCED
  [formBuilderType.sinAdvanced]: [],
  [formBuilderType.ssnAdvanced]: [],
  [formBuilderType.documentPreview]: [],
  [formBuilderType.canadianBank]: [],
  [formBuilderType.americanBank]: [],
  [formBuilderType.signatureAdvanced]: [],
  [formBuilderType.addressValidator]: [],
  // [formBuilderType.confirmation]: [],
  [formBuilderType.earliestStartDate]: [],
  [formBuilderType.emergencyContact]: [],
  [formBuilderType.miscellaneous]: [],
  // PAGE ELEMENTS
  [formBuilderType.section]: [],
};

type DefaultValueProps = Pick<
  FieldSchema,
  | 'text'
  | 'label'
  | 'isInfo'
  | 'link'
  | 'optionList'
  | 'validations'
  | 'previewPlaceholders'
  | 'documentReview'
  | 'disabled'
  | 'disabledId'
  | 'isFrontEndOnly'
  | 'hideFieldSelector'
  | 'followUpQuestionList'
  | 'min'
  | 'max'
  | 'enableWorkPermit'
> & {
  inputType?: Pick<FieldSchema, 'inputType'>['inputType'];
  isFollowUp?: Pick<FieldSchema, 'isFollowUp'>['isFollowUp'];
  field?: Pick<FieldSchema, 'field'>['field'];
} & { isFollowUp?: Pick<FieldSchema, 'isFollowUp'>['isFollowUp'] };

interface getSchemaValuesProps extends Pick<FormBuilderSchema, 'type' | 'label' | 'icon' | 'component' | 'hidden' | 'section'> {
  defaultValue: DefaultValueProps | DefaultValueProps[];
  panel?: Pick<FormBuilderSchema, 'panel'>['panel'];
}

function getSchemaValues({
  defaultValue = {},
  label: _label,
  icon,
  panel = 'basic',
  component,
  type,
  hidden = false,
  section,
}: getSchemaValuesProps): FormBuilderSchema {
  function getDefaultValue(value: DefaultValueProps) {
    const {
      text = { en: '', fr: '' },
      label,
      isInfo = false,
      optionList = null,
      validations,
      previewPlaceholders,
      inputType,
      isFollowUp = false,
      field,
      documentReview = null,
      disabled = false,
      disabledId,
      isFrontEndOnly = false,
      hideFieldSelector = false,
      followUpQuestionList = null,
      min = null,
      max = null,
      enableWorkPermit = null,
      link = null,
    } = value;

    return {
      timestamp: Date.now(),
      id: uuidv4(),
      required: true,
      order: 1,
      validations,
      isInfo,
      inputType: inputType ?? type,
      text,
      label: label ?? { en: _label, fr: '' },
      optionList,
      previewPlaceholders,
      section,
      isFollowUp,
      field: field ?? type,
      documentReview,
      disabled,
      disabledId,
      isFrontEndOnly,
      hideFieldSelector,
      followUpQuestionList,
      min,
      max,
      enableWorkPermit,
      link,
    };
  }

  const currentDefaultValues = Array.isArray(defaultValue) ? defaultValue.map(getDefaultValue) : getDefaultValue(defaultValue);

  return {
    type,
    component,
    props: {},
    defaultValue: currentDefaultValues,
    icon,
    label: _label,
    panel,
    hidden,
    section,
  };
}

export const basicFormBuilderSchemas: Record<BasicFormBuilderTypes, FormBuilderSchema> = {
  [formBuilderType.text]: getSchemaValues({
    type: formBuilderType.text,
    component: TextFieldSchemaBuilder,
    defaultValue: {
      min: 3,
      max: 500,
    },
    icon: BsInputCursorText,
    label: 'text',
  }),
  [formBuilderType.info]: getSchemaValues({
    type: formBuilderType.info,
    component: TextFieldSchemaBuilder,
    defaultValue: { isInfo: true },
    icon: MdTextFields,
    label: 'info',
  }),
  [formBuilderType.email]: getSchemaValues({
    type: formBuilderType.email,
    component: EmailSchemaBuilder,
    defaultValue: {
      text: {
        en: '<p>Enter your email address.</p>',
        fr: '<p>Entrez votre adresse e-mail.</p>',
      },
      min: 5,
      max: 100,
    },
    icon: IoMail,
    label: 'email',
  }),
  [formBuilderType.phone]: getSchemaValues({
    type: formBuilderType.phone,
    component: PhoneSchemaBuilder,
    defaultValue: {
      text: { en: '<p>What is your phone number?</p>', fr: '<p>Quel est votre numéro de téléphone?</p>' },
      min: 10,
      max: 20,
    },
    icon: IoCall,
    label: 'phone',
  }),
  [formBuilderType.link]: getSchemaValues({
    type: formBuilderType.link,
    component: LinkSchemaBuilder,
    defaultValue: { link: { en: '' } },
    icon: MdLink,
    label: 'link',
    hidden: true,
  }),
  [formBuilderType.date]: getSchemaValues({
    type: formBuilderType.date,
    component: DatePickerSchemaBuilder,
    defaultValue: {},
    icon: IoCalendar,
    label: 'date',
  }),
  [formBuilderType.time]: getSchemaValues({
    type: formBuilderType.time,
    component: TimePickerSchemaBuilder,
    defaultValue: {},
    icon: MdOutlineAccessTime,
    label: 'time',
    hidden: true,
  }),
  [formBuilderType.number]: getSchemaValues({
    type: formBuilderType.number,
    component: NumberSchemaBuilder,
    defaultValue: { min: 10, max: 100 },
    icon: MdOutlinePin,
    label: 'number',
  }),
  [formBuilderType.radio]: getSchemaValues({
    type: formBuilderType.radio,
    component: RadioSchemaBuilder,
    defaultValue: {
      optionList: [
        { key: 'A', id: uuidv4(), text: { en: 'Option 1', fr: 'Option 1' } },
        { key: 'B', id: uuidv4(), text: { en: 'Option 2', fr: 'Option 2' } },
      ],
    },
    icon: IoRadioButtonOnOutline,
    label: 'radio',
  }),
  [formBuilderType.checkbox]: getSchemaValues({
    type: formBuilderType.checkbox,
    component: MCQSchemaBuilder,
    defaultValue: {
      optionList: [
        { key: 'A', id: uuidv4(), text: { en: 'Option 1', fr: 'Option 1' } },
        { key: 'B', id: uuidv4(), text: { en: 'Option 2', fr: 'Option 2' } },
      ],
    },
    icon: IoCheckbox,
    label: 'checkbox',
  }),
  [formBuilderType.gender]: getSchemaValues({
    type: formBuilderType.gender,
    component: RadioSchemaBuilder,
    defaultValue: {
      inputType: formBuilderType.radio,
      text: { en: '<p>What is your gender?</p>', fr: '<p>Quel est votre sexe?</p>' },
      optionList: [
        { key: 'A', id: uuidv4(), text: { en: 'Female' } },
        { key: 'B', id: uuidv4(), text: { en: 'Male' } },
        { key: 'C', id: uuidv4(), text: { en: 'Non-Binary' } },
        { key: 'D', id: uuidv4(), text: { en: 'Undeclared' } },
      ],
      field: schemaFields.gender,
      hideFieldSelector: true,
    },
    icon: MdOutlinePerson,
    label: 'gender',
  }),
  [formBuilderType.yesNo]: getSchemaValues({
    type: formBuilderType.yesNo,
    component: YesNoSchemaBuilder,
    defaultValue: {
      optionList: [
        { key: 'Yes', id: uuidv4(), text: { en: 'Yes', fr: 'Oui' } },
        { key: 'No', id: uuidv4(), text: { en: 'No', fr: 'Non' } },
      ],
    },
    icon: IoToggle,
    label: 'yesNo',
  }),
  [formBuilderType.address]: getSchemaValues({
    type: formBuilderType.address,
    component: AddressSchemaBuilder,
    defaultValue: {
      text: {
        en: '<p>What is your current address? Use the button below to search addresses.</p>',
        fr: '<p>Quel est votre adresse actuelle? Utilisez le bouton ci-dessous pour rechercher des adresses.</p>',
      },
    },
    icon: IoLocation,
    label: 'address',
  }),
  [formBuilderType.image]: getSchemaValues({
    type: formBuilderType.image,
    component: ImageSchemaBuilder,
    defaultValue: {
      validations: {
        acceptType: ['image/jpg', 'image/jpeg', 'image/png'],
        maxFileSize: 2 * 1024 * 1024, // 2MB
      },
    },
    icon: IoImage,
    label: 'image',
  }),
  [formBuilderType.file]: getSchemaValues({
    type: formBuilderType.file,
    component: ImageSchemaBuilder,
    defaultValue: {
      validations: {
        acceptType: ['application/pdf'],
        maxFileSize: 2 * 1024 * 1024, // 2MB
      },
    },
    icon: MdOutlineBackup,
    label: 'file',
    hidden: true,
  }),
  [formBuilderType.documentReview]: getSchemaValues({
    type: formBuilderType.documentReview,
    component: DocumentPreview,
    defaultValue: {
      documentReview: {
        confirmText: { en: 'Confirm', fr: 'Confirmer' },
        description: {
          en: '<p>You need to consent to the document after you read it</p>',
          fr: '<p>Vous devez accepter le document après avoir lu</p>',
        },
        acknowledgeContent: {
          en: '<p>I acknowledge that I have uploaded all the required documents.</p>',
          fr: '<p>Je confirme avoir téléchargé tous les documents nécessaires.</p>',
        },
        url: { en: '' },
      },
    },
    icon: MdOutlineBackup,
    label: 'document',
  }),
  [formBuilderType.singleCheckbox]: getSchemaValues({
    type: formBuilderType.singleCheckbox,
    component: CheckBoxSchemaBuilder,
    defaultValue: {},
    icon: MdOutlineCheckBox,
    label: 'singleCheckbox',
    hidden: true,
  }),
  [formBuilderType.button]: getSchemaValues({
    type: formBuilderType.button,
    component: ButtonSchemaBuilder,
    defaultValue: {},
    icon: MdSmartButton,
    label: 'button',
    hidden: true,
  }),
  [formBuilderType.bankAccountNumber]: getSchemaValues({
    type: formBuilderType.bankAccountNumber,
    component: NumberSchemaBuilder,
    defaultValue: {
      text: {
        en:
          '<p><strong>Bank Account Number:</strong> The Bank Account Number is the group of 7 to 12 numbers after the Financial Institution Number at the bottom of your cheque. You can view our guide or enter the numbers directly.</p>',
      },
      previewPlaceholders: { placeholder: '00 0000', disableMinMax: true },
      field: schemaFields.bankAccountNumber,
    },
    icon: MdOutlinePin,
    label: 'bankAccountNumber',
    hidden: true,
  }),
  [formBuilderType.routingNumber]: getSchemaValues({
    type: formBuilderType.routingNumber,
    component: NumberSchemaBuilder,
    defaultValue: {
      text: {
        en:
          '<p><strong>Routing Number:</strong> The Routing Number is the first group of 9 numbers at the bottom of your cheque. You can view our guide or enter the numbers directly.</p>',
      },
      previewPlaceholders: { placeholder: '000000000', disableMinMax: true },
      field: schemaFields.routingNumber,
    },
    icon: MdOutlinePin,
    label: 'routingNumber',
    hidden: true,
  }),
  [formBuilderType.branchTransitNumber]: getSchemaValues({
    type: formBuilderType.branchTransitNumber,
    component: NumberSchemaBuilder,
    defaultValue: {
      text: {
        en:
          '<p><strong>Branch Transit Number:</strong> The Branch Transit Number is the first group of 5 numbers at the bottom of your cheque. You can view our guide or enter the numbers directly.</p>',
      },
      previewPlaceholders: { placeholder: '00000', disableMinMax: true },
      field: schemaFields.branchTransitNumber,
    },
    icon: MdOutlinePin,
    label: 'branchTransitNumber',
    hidden: true,
  }),
  [formBuilderType.financialInstitutionNumber]: getSchemaValues({
    type: formBuilderType.financialInstitutionNumber,
    component: NumberSchemaBuilder,
    defaultValue: {
      text: {
        en:
          '<p><strong>Financial Institution Number:</strong> The Financial Institution Number is the group of 3 numbers after the Branch Transit Number at the bottom of your cheque. You can view our guide or enter the numbers directly.</p>',
      },
      previewPlaceholders: { placeholder: '000', disableMinMax: true },
      field: schemaFields.financialInstitutionNumber,
    },
    icon: MdOutlinePin,
    label: 'financialInstitutionNumber',
    hidden: true,
  }),

  [formBuilderType.checkboxDisable]: getSchemaValues({
    type: formBuilderType.checkboxDisable,
    component: CheckboxDisable,
    defaultValue: {
      isFrontEndOnly: true,
    },
    icon: MdOutlinePin,
    label: 'checkboxDisabled',
    hidden: true,
  }),
  [formBuilderType.correspondenceLanguage]: getSchemaValues({
    type: formBuilderType.correspondenceLanguage,
    component: RadioSchemaBuilder,
    defaultValue: {
      inputType: formBuilderType.radio,
      text: { en: '<p>Please select a language.</p>', fr: '<p>Veuillez choisir une langue.</p>' },
      optionList: [
        { key: 'A', id: uuidv4(), text: { en: 'English', fr: 'Anglais' } },
        { key: 'B', id: uuidv4(), text: { en: 'French', fr: 'Français' } },
      ],
      field: schemaFields.correspondenceLanguage,
      hideFieldSelector: true,
    },
    icon: FaLanguage,
    label: 'correspondenceLanguage',
    section: 'signature',
  }),

  [formBuilderType.sin]: getSchemaValues({
    type: formBuilderType.sin,
    component: NumberSchemaBuilder,
    defaultValue: {
      text: { en: '<p>Social Insurance Number</p>', fr: '<p>Numéro de sécurité sociale</p>' },
      label: { en: fieldLabel[schemaFields.socialInsuranceNumber], fr: 'Numéro de sécurité sociale' },
      previewPlaceholders: { placeholder: '000-000-000', disableMinMax: true },
      field: schemaFields.socialInsuranceNumber,
    },
    icon: MdOutlinePin,
    label: 'sin',
    hidden: true,
  }),
  [formBuilderType.ssn]: getSchemaValues({
    type: formBuilderType.ssn,
    component: NumberSchemaBuilder,
    defaultValue: {
      text: { en: '<p>Social Security Number</p>', fr: '<p>Numéro de sécurité sociale</p>' },
      label: { en: fieldLabel[schemaFields.socialSecurityNumber], fr: 'Numéro de sécurité sociale' },
      previewPlaceholders: { placeholder: '000-000-000', disableMinMax: true },
      field: schemaFields.socialSecurityNumber,
    },
    icon: MdOutlinePin,
    label: 'ssn',
    hidden: true,
  }),
  [formBuilderType.signature]: getSchemaValues({
    type: formBuilderType.signature,
    component: SignatureSchemaBuilder,
    defaultValue: {
      text: { en: '<p>Signature</p>', fr: '<p>Signature</p>' },
      label: { en: 'Signature', fr: 'Signature' },
      field: schemaFields.signatureImageUrl,
    },
    icon: FaSignature,
    label: 'signature',
    hidden: false,
  }),
};

const disableIds = {
  sinWorkPermit: 'sinWorkPermit',
  bankVoidCheque: 'bankVoidCheque',
};

export const advancedFormBuilderSchemas: Record<AdvancedFormBuilderTypes, FormBuilderSchema> = {
  [formBuilderType.sinAdvanced]: getSchemaValues({
    type: formBuilderType.sinAdvanced,
    component: null,
    defaultValue: [
      {
        text: { en: '<p>Social Insurance Number</p>', fr: '<p>Numéro de sécurité sociale</p>' },
        ...basicFormBuilderSchemas.sin.defaultValue,
        enableWorkPermit: true,
      },
      {
        ...basicFormBuilderSchemas.checkboxDisable.defaultValue,
        text: {
          en: 'Enable work permit if it starts from 9',
          fr: 'Activer le permis de travail si il commence le 9',
        },
        disabledId: disableIds.sinWorkPermit,
      },
      {
        ...basicFormBuilderSchemas.date.defaultValue,
        text: { en: '<p>What is the end date of your work permit?</p>', fr: '<p>Quand finit votre permis de travail?</p>' },
        label: { en: 'Work permit end date', fr: 'Date de fin du permis de travail' },
        field: schemaFields.workPermitEndDate,
        disabledId: disableIds.sinWorkPermit,
      },
      {
        ...basicFormBuilderSchemas.info.defaultValue,
        text: {
          en: '<p>We will need a photo of your valid work permit.</p>',
          fr: '<p>Nous avons besoin d’une photo de votre permis de travail valide.</p>',
        },
        disabledId: disableIds.sinWorkPermit,
      },
      {
        ...basicFormBuilderSchemas.info.defaultValue,
        text: {
          en: '<p>Make sure you follow these instructions when taking your photo:</p>',
          fr: '<p>S’assurer que vous suiviez ces instructions lors de votre prise de photo:</p>',
        },
        disabledId: disableIds.sinWorkPermit,
      },
      {
        ...basicFormBuilderSchemas.image.defaultValue,
        text: {
          en:
            '<p>All of the pages of the work permit, and the work permit’s end date, must be clearly visible in the photo preview.</p>',
          fr:
            '<p>Toutes les pages du permis de travail, ainsi que la date de fin du permis de travail, doivent être clairement visibles dans la prévisualisation de la photo.</p>',
        },
        label: { en: 'Work permit photo', fr: 'Photo du permis de travail' },
        field: schemaFields.workPermitImageUrl,
        disabledId: disableIds.sinWorkPermit,
      },
    ],
    section: Sections.sin,
    icon: MdFiberPin,
    label: 'sin',
    panel: 'advanced',
  }),
  [formBuilderType.ssnAdvanced]: getSchemaValues({
    type: formBuilderType.ssnAdvanced,
    component: null,
    defaultValue: [
      {
        ...basicFormBuilderSchemas.ssn.defaultValue,
        text: { en: '<p>Social Security Number</p>', fr: '<p>Numéro de sécurité sociale</p>' },
      },
    ],
    section: Sections.ssn,
    icon: MdFiberPin,
    label: 'ssn',
    panel: 'advanced',
  }),
  [formBuilderType.signatureAdvanced]: getSchemaValues({
    type: formBuilderType.signatureAdvanced,
    component: null,
    defaultValue: [
      { ...basicFormBuilderSchemas.signature.defaultValue, text: { en: 'Final review signature', fr: 'Signature de fin' } },
    ],
    icon: FaSignature,
    label: 'finalSinature',
    panel: 'advanced',
    section: Sections.signature,
  }),
  [formBuilderType.documentPreview]: getSchemaValues({
    type: formBuilderType.documentPreview,
    component: null,
    defaultValue: [
      {
        ...{
          ...basicFormBuilderSchemas.documentReview.defaultValue,
          documentReview: {
            ...((basicFormBuilderSchemas.documentReview.defaultValue as any).documentReview ?? {}),

            description: {
              en: '<p>You need to consent to this Code after you have read it.</p>',
              fr: '<p>Vous devez accepter ce Code après avoir lu le Code.</p>',
            },
            acknowledgeContent: {
              en:
                '<p>I acknowledge that I have read and understood the <span data-tag="true">[business_name]</span> Code of Conduct and Ethics (the "Code") and signify that I will comply with the rules, policies and procedures set forth in the Code.</p>',
              fr:
                "<p> Je reconnais avoir lu et compris le Code de conduite et d'éthique de <span data-tag=\"true\">[business_name]</span> (le « Code ») et j'atteste que je m'engage à respecter toutes les règles, politiques et procédures énoncées dans le Code.</p>",
            },
          },
        },
        field: schemaFields.codeofconductandethics,
        text: {
          en:
            '<p>Please review the <span data-tag="true">[business_name]</span> code of Conduct and Ethics. It addresses various topics and includes the rules and guidelines for personal conduct and ethical decisions in the course of your work.</p>',
          fr: '<p>Veuillez examiner le Code de conduite et d’éthique de <span data-tag="true">[business_name]</span>.</p>',
        },
        label: {
          en: fieldLabel[schemaFields.codeofconductandethics],
          fr: 'Code de conduite et éthique',
        },
      },
      {
        ...{
          ...basicFormBuilderSchemas.documentReview.defaultValue,
          documentReview: {
            ...((basicFormBuilderSchemas.documentReview.defaultValue as any).documentReview ?? {}),

            description: {
              en: '<p>You need to consent to this Code after you have read it.</p>',
              fr: '<p>Vous devez accepter ce Code apres avoir lu le Code.</p>',
            },
            acknowledgeContent: {
              en:
                '<p>I acknowledge that I have read and understood the <span data-tag="true">[business_name]</span> Privacy Notice for Employees and signify that I will comply with the rules, policies and procedures set forth in this employee privacy policy.</p>',
              fr:
                '<p>Je reconnais avoir lu et compris le <span data-tag="true">[business_name]</span> Notice de Confidentialité des Employés et m’y engage.</p>',
            },
          },
        },
        field: schemaFields.privacynoticeforemployees,
        text: {
          en:
            '<p>Please review the <span data-tag="true">[business_name]</span> Privacy Notice for Employees that informs you on how your personal information is collected, for which purpose and how you may exercise certain privacy rights.</p>',
          fr:
            "<p>Je reconnais avoir lu et compris l'Avis sur la protection des renseignements personnels des employés et j'atteste que je m'engage à respecter toutes les règles, politiques et procédures énoncées dans cette politique de vie privée des employés</p>",
        },
        label: {
          en: fieldLabel[schemaFields.privacynoticeforemployees],
          fr: 'Notice de Confidentialité des Employés',
        },
      },
      {
        ...{
          ...basicFormBuilderSchemas.documentReview.defaultValue,
          documentReview: {
            ...((basicFormBuilderSchemas.documentReview.defaultValue as any).documentReview ?? {}),

            description: {
              en: '<p>You need to consent to this Employee Privacy Policy after you have read it.</p>',
              fr:
                '<p>Vous devez accepter cette Politique de Vie Privée des Employés apres avoir lu la Politique de Vie Privée des Employés.</p>',
            },
            acknowledgeContent: {
              en:
                '<p>I acknowledge that I have read and understood the Electronic Monitoring Policy and signify that I will comply with the rules, policies and procedures set forth in this employee privacy policy.</p>',
              fr:
                '<p>Je reconnais avoir lu et compris la Politique de surveillance électronique de <span data-tag="true">[business_name]</span> et j\'atteste que je m\'engage à respecter toutes les règles, politiques et procédures énoncées dans cette politique de vie privée des employés</p>',
            },
          },
        },
        field: schemaFields.electronicmonitoringpolicy,
        text: {
          en:
            '<p>Please review the Electronic Monitoring Policy that governs the use of <span data-tag="true">[business_name]</span> equipment and the tools used to monitor productive work.</p>',
          fr:
            '<p>Veuillez lire attentivement la <span data-tag="true">[business_name]</span> Politique de surveillance électronique.</p>',
        },
        label: {
          en: fieldLabel[schemaFields.electronicmonitoringpolicy],
          fr: 'Politique de surveillance électronique',
        },
      },
    ],
    section: 'documentPreview',
    icon: MdOutlineArticle,
    label: 'documentPreview',
    panel: 'advanced',
  }),
  [formBuilderType.canadianBank]: getSchemaValues({
    type: formBuilderType.canadianBank,
    component: null,
    defaultValue: [
      {
        ...basicFormBuilderSchemas.info.defaultValue,
        text: {
          en:
            '<p>In order to set you up in our system, we will need your banking information. These numbers can be found on a cheque or within your online banking portal.</p>',
          fr:
            '<p>Pour vous inscrire dans notre système, nous avons besoin de vos informations bancaires. Ces chiffres peuvent être trouvés sur un chèque ou dans le portail en ligne de votre banque.</p>',
        },
      },
      {
        ...basicFormBuilderSchemas.branchTransitNumber.defaultValue,
        label: { en: fieldLabel[schemaFields.branchTransitNumber], fr: 'Numéro de transit de la banque' },
      },
      {
        ...basicFormBuilderSchemas.financialInstitutionNumber.defaultValue,
        label: { en: fieldLabel[schemaFields.financialInstitutionNumber], fr: 'Numéro de l’institution financière' },
      },
      {
        ...basicFormBuilderSchemas.bankAccountNumber.defaultValue,
        label: { en: fieldLabel[schemaFields.bankAccountNumber], fr: 'Numéro de compte bancaire' },
      },
      {
        ...basicFormBuilderSchemas.checkboxDisable.defaultValue,
        text: { en: 'Enable void cheque image upload', fr: 'Activer l’upload de la photo du chèque vide' },
        disabledId: disableIds.bankVoidCheque,
      },
      {
        ...basicFormBuilderSchemas.info.defaultValue,
        text: {
          en: '<p>We now need a photo of your void cheque.</p>',
          fr: '<p>Nous avons maintenant besoin d’une photo de votre chèque vide.</p>',
        },
        disabledId: disableIds.bankVoidCheque,
      },
      {
        ...basicFormBuilderSchemas.info.defaultValue,
        text: {
          en: '<p>Make sure you follow these instructions when taking your photo:</p>',
          fr: '<p>Assurez-vous de suivre ces instructions lorsque vous prenez votre photo:</p>',
        },
        disabledId: disableIds.bankVoidCheque,
      },
      {
        ...basicFormBuilderSchemas.image.defaultValue,
        text: {
          en:
            '<p>SPECIMEN or VOID must be written on the cheque. Make sure all the numbers on the void cheque are clearly visible in the photo preview. Handwritten information is not accepted.</p>',
          fr:
            '<p>SPECIMEN ou VOID doit être écrit sur le chèque. Assurez-vous de voir clairement tous les nombres sur le chèque vide. Les informations manuscrites ne sont pas acceptées.</p>',
        },
        disabledId: disableIds.bankVoidCheque,
        label: { en: fieldLabel[schemaFields.chequebookImageUrl], fr: 'Photo du chèque vide' },
        field: schemaFields.chequebookImageUrl,
      },
    ],
    section: Sections.canadianBank,
    icon: AiFillBank,
    label: 'canadianBank',
    panel: 'advanced',
  }),
  [formBuilderType.americanBank]: getSchemaValues({
    type: formBuilderType.americanBank,
    component: null,
    defaultValue: [
      {
        ...basicFormBuilderSchemas.info.defaultValue,
        text: {
          en:
            '<p>In order to set you up in our system, we need your banking information. These numbers can be found on a cheque or within your bank’s online portal.</p>',
          fr:
            '<p>Pour vous inscrire dans notre système, nous avons besoin de vos informations bancaires. Ces chiffres peuvent être trouvés sur un chèque ou dans le portail en ligne de votre banque.</p>',
        },
      },
      {
        ...basicFormBuilderSchemas.routingNumber.defaultValue,
        label: { en: fieldLabel[schemaFields.routingNumber], fr: 'Numéro de routage' },
      },
      {
        ...basicFormBuilderSchemas.bankAccountNumber.defaultValue,
        label: { en: fieldLabel[schemaFields.bankAccountNumber], fr: 'Numéro de compte bancaire' },
        text: {
          en:
            '<p><strong>Bank Account Number:</strong> The Bank Account Number is the second group of 10-12 numbers at the bottom of your check. You can view our guide or enter the numbers directly.</p>',
          fr:
            '<p><strong>Bank Account Number:</strong> Le Numéro de compte bancaire est le deuxième groupe de 10-12 chiffres en bas de votre chèque. Vous pouvez consulter notre guide ou saisir les chiffres directement.</p>',
        },
      },
      {
        ...basicFormBuilderSchemas.checkboxDisable.defaultValue,
        text: { en: 'Enable void cheque image upload', fr: 'Activer l’upload de la photo du chèque vide' },
        disabledId: disableIds.bankVoidCheque,
      },
      {
        ...basicFormBuilderSchemas.info.defaultValue,
        text: {
          en: '<p>We now need a photo of your void cheque.</p>',
          fr: '<p>Nous avons maintenant besoin d’une photo de votre chèque vide.</p>',
        },
        disabledId: disableIds.bankVoidCheque,
      },
      {
        ...basicFormBuilderSchemas.info.defaultValue,
        text: {
          en: '<p>Make sure you follow these instructions when taking your photo:</p>',
          fr: '<p>Assurez-vous de suivre ces instructions lorsque vous prenez votre photo:</p>',
        },
        disabledId: disableIds.bankVoidCheque,
      },
      {
        ...basicFormBuilderSchemas.image.defaultValue,
        text: {
          en:
            '<p>SPECIMEN or VOID must be written on the cheque. Make sure all the numbers on the void cheque are clearly visible in the photo preview. Handwritten information is not accepted.</p>',
          fr:
            '<p>SPECIMEN ou VOID doit être écrit sur le chèque. Assurez-vous de voir clairement tous les nombres sur le chèque vide. Les informations manuscrites ne sont pas acceptées.</p>',
        },
        disabledId: disableIds.bankVoidCheque,
        label: { en: fieldLabel[schemaFields.chequebookImageUrl], fr: 'Photo du chèque vide' },
        field: schemaFields.chequebookImageUrl,
      },
    ],
    section: Sections.americanBank,
    icon: AiFillBank,
    label: 'americanBank',
    panel: 'advanced',
  }),
  [formBuilderType.earliestStartDate]: getSchemaValues({
    type: formBuilderType.earliestStartDate,
    component: null,
    defaultValue: [
      {
        ...basicFormBuilderSchemas.date.defaultValue,
        text: {
          en:
            '<p>To proceed, let us know the earliest date that you are available to work! This position can start as early as <span data-tag="true">[position_start_date]</span> </p>',
          fr:
            '<p>Pour continuer, nous voulons savoir la date la plus tardive que vous pouvez travailler! Cette position peut commencer comme tard que <span data-tag="true">[position_start_date]</span></p>',
        },
        label: { en: 'Earliest Start Date', fr: 'Date de commencement la plus tardive' },
      },
    ],
    section: 'earliest start date',
    icon: IoCalendar,
    label: 'earliestStartDate',
    panel: 'advanced',
  }),
  [formBuilderType.addressValidator]: getSchemaValues({
    type: formBuilderType.addressValidator,
    component: null,
    defaultValue: [
      {
        ...basicFormBuilderSchemas.address.defaultValue,
        text: {
          en: '<p>What is your current address? Use the button below to search addresses.</p>',
          fr: '<p>Quelle est votre adresse actuelle? Utilisez le bouton ci-dessous pour rechercher des adresses.</p>',
        },
        label: { en: 'Current Address' },
      },
      {
        ...basicFormBuilderSchemas.info.defaultValue,
        text: {
          en: '<p>Thank you for your patience. We have very few questions left.</p>',
          fr: '<p>Merci de votre patience. Nous avons encore quelques questions à poser.</p>',
        },
      },
      {
        ...basicFormBuilderSchemas.number.defaultValue,
        text: { en: '<p>Enter your postal code</p>', fr: '<p>Entrez votre code postal</p>' },
        previewPlaceholders: { placeholder: '000 000', disableMinMax: true },
        label: { en: fieldLabel[schemaFields.postalCode] },
        field: schemaFields.postalCode,
      },
      addressValidatorApartmentNumber(),
    ],
    section: 'address validator',
    icon: IoLocation,
    label: 'addressValidator',
    panel: 'advanced',
  }),
  [formBuilderType.emergencyContact]: getSchemaValues({
    type: formBuilderType.emergencyContact,
    component: null,
    defaultValue: [emergencyContact()],
    section: Sections.emergencyContact,
    icon: MdContactPhone,
    label: 'emergencyContact',
    panel: 'advanced',
  }),
  [formBuilderType.miscellaneous]: getSchemaValues({
    type: formBuilderType.miscellaneous,
    component: null,
    defaultValue: [
      {
        ...basicFormBuilderSchemas.text.defaultValue,
        text: { en: 'Any additional information you would like to share with us?' },
        field: schemaFields.contractDetailsWordings,
        label: { en: fieldLabel[schemaFields.contractDetailsWordings], fr: 'Informations sur le contrat' },
        previewPlaceholders: { placeholder: 'Enter text here', disableMinMax: true },
      },
    ],
    section: Sections.miscellaneous,
    icon: MdMiscellaneousServices,
    label: 'miscellaneous',
    panel: 'advanced',
  }),
};

export type FormBuilderSchemas = Record<FormBuilderTypes, FormBuilderSchema>;

export const formBuilderSchemas: FormBuilderSchemas = {
  // basic elements
  ...basicFormBuilderSchemas,

  // Page Elements
  [formBuilderType.section]: getSchemaValues({
    type: formBuilderType.section,
    component: null,
    defaultValue: {},
    icon: MdViewAgenda,
    label: 'section',
    panel: 'page-element',
  }),

  // Advanced elements
  ...advancedFormBuilderSchemas,
};

function addressValidatorApartmentNumber() {
  const parentID = uuidv4();
  const option1Id = uuidv4();
  const option2Id = uuidv4();
  const schema: FieldSchema = {
    timestamp: Date.now(),
    id: parentID,
    inputType: formBuilderType.yesNo,
    required: true,
    text: {
      en: 'Does your address have an apartment number?',
    },
    order: 13,
    section: 'address validator',
    optionList: [
      { key: 'Yes', id: option1Id, text: { en: 'Yes', fr: 'Oui' } },
      { key: 'No', id: option2Id, text: { en: 'No', fr: 'Non' } },
    ],
    isFollowUp: false,
    followUpQuestionList: [
      {
        timestamp: Date.now(),
        id: uuidv4(),
        inputType: formBuilderType.number,
        required: true,
        optionId: option1Id,
        text: { en: 'Enter your apartment number.' },
        parent: parentID,
        order: 1,
        label: { en: fieldLabel[schemaFields.apartment] },
        previewPlaceholders: {
          placeholder: 'Apartment Number',
          disableMinMax: true,
        },
        isFollowUp: true,
        field: schemaFields.apartment,
      },
    ],
    field: schemaFields.address,
  };
  return schema;
}

function emergencyContact() {
  const parentID = uuidv4();
  const option1Id = uuidv4();
  const option2Id = uuidv4();
  const schema: FieldSchema = {
    ...basicFormBuilderSchemas.yesNo.defaultValue,
    timestamp: Date.now(),
    id: parentID,
    inputType: formBuilderType.yesNo,
    text: {
      en: '<p>Do you have someone we can reach in case of an emergency?</p>',
      fr: '<p>Avez-vous quelqu’un que nous pouvons joindre en cas d’urgence?</p>',
    },
    order: 13,
    section: Sections.emergencyContact,
    optionList: [
      { key: 'Yes', id: option1Id, text: { en: 'Yes', fr: 'Oui' } },
      { key: 'No', id: option2Id, text: { en: 'No', fr: 'Non' } },
    ],
    isFollowUp: false,
    followUpQuestionList: [
      {
        ...basicFormBuilderSchemas.text.defaultValue,
        timestamp: Date.now(),
        id: uuidv4(),
        inputType: formBuilderType.text,
        optionId: option1Id,
        text: { en: '<p>What is their first name?</p>', fr: '<p>Quel est leur nom de famille?</p>' },
        parent: parentID,
        order: 1,
        label: { en: fieldLabel[schemaFields.emergencyContactFirstName], fr: "Contact d'urgence Prénom" },
        isFollowUp: true,
        field: schemaFields.emergencyContactFirstName,
      },
      {
        ...basicFormBuilderSchemas.text.defaultValue,
        timestamp: Date.now(),
        id: uuidv4(),
        inputType: formBuilderType.text,
        optionId: option1Id,
        text: { en: '<p>What is their last name?</p>', fr: '<p>Quel est leur prénom?</p>' },
        parent: parentID,
        order: 1,
        label: { en: fieldLabel[schemaFields.emergencyContactLastName], fr: 'Nom' },
        isFollowUp: true,
        field: schemaFields.emergencyContactLastName,
      },
      {
        ...basicFormBuilderSchemas.phone.defaultValue,
        timestamp: Date.now(),
        id: uuidv4(),
        inputType: formBuilderType.phone,
        required: true,
        optionId: option1Id,
        text: {
          en: '<p>What is their phone number? We will only contact them in case of an emergency.</p>',
          fr: '<p>Quel est leur numéro de téléphone? Nous ne nous contacterons que dans le cas d’urgence.</p>',
        },
        parent: parentID,
        order: 1,
        label: { en: fieldLabel[schemaFields.emergencyContactPhoneNumber], fr: 'Numéro de telephone' },
        isFollowUp: true,
        field: schemaFields.emergencyContactPhoneNumber,
      },
    ],
    field: schemaFields.emergencyContact,
  };
  return schema;
}
