/* istanbul ignore file */
import React, { Fragment, useCallback, useEffect, useState, useRef } from 'react';
import {
  Box,
  Button,
  useToast,
  ModalFooter,
  ModalOverlay,
  ModalBody,
  ModalContent,
  Modal,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/core';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _omit from 'lodash/omit';
import _uniqBy from 'lodash/uniqBy';
import _filter from 'lodash/filter';
import _findIndex from 'lodash/findIndex';
import Joi from 'joi';
import { useTranslation } from 'react-i18next';
import 'rc-steps/assets/index.css';
import _includes from 'lodash/includes';
import isUndefined from 'lodash/isUndefined';
import { useParams } from 'react-router';
import { AxiosError } from 'axios';
import axios from 'axios';
import isNil from 'lodash/isNil';
import { createJobPositionStyle } from './CreateJobPositionStyle';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import {
  FreeFormQuestion,
  IndustryType,
  Interview,
  JobPosition,
  JobType,
  JopPositionResponse,
  MultipleChoiceQuestion,
  OccupationType,
  Question,
  Schedule,
  SkillForPosition,
} from '../PositionType';
import { clearErrorMessage, errorFormat, FormattedError, updateObject } from '../../../../utils/FormErrorUtils';
import useBusinessLocations from '../useBusinessLocations';
import { JobPositionDetailCard } from './JobPositionDetailCard';
import { getTranslatedValue } from '../../../../utils/localizedString';
import { AdvanceFieldsCard } from './AdvanceFieldsCard';
import { AutomationCard } from './AutomationCard';
import { InterviewCard } from './InterviewCard';
import { AutomationTypes } from './AutomationTypes';
import { VideoDetailCard } from './VideoDetailCard';
import { RemoteConfigKey } from '../../../../firebase/remoteConfig';
import { usePositionManagementContext } from '../PositionManagementContext';
import { Business } from '../BusinessType';
import { ReviewCreateJobView } from './ReviewCreateJobView';
import { QuestionsView } from './QuestionsView';
import { SpecificQuestionsCard } from './SpecificQuestionsCard';
import { FreeFormQuestionsCard } from './FreeFormQuestionsCard';
import { CertnBackgroundCheckCard } from './CertnBackgroundCheckCard';
import { BackgroundChecks } from '../../../../firebase/firestore/documents/candidate';
import { LanguageEvaluationPhrases } from '../../../../firebase/firestore/documents/languageEvaluationPhrases';
import { DialogFlowAgent } from '../../../../firebase/firestore/documents/accounts';
import { AUDIO_EVALUATION_OPTIONS } from '../../../../firebase/firestore/constants';
import useLookupsByType from '../../../../app/hooks/useLookupsByType';
import useBackgroundChecks from '../../../../app/hooks/useBackgroundChecks';
import { PositionAvailabilityCard } from './PositionAvailabilityCard';
import useContactEmailForAccountAuthorisation from '../../../../app/hooks/useContactEmailForAccountAuthorisation';
import { ComplianceContext, PositionComplianceReminder } from '../../../../app/components/ComplianceReminder';

const initialBackgroundCheck = {
  request_instant_verify_education: false,
  request_instant_verify_employment: false,
  request_enhanced_identity_verification: false,
  request_criminal_record_check: false,
  request_enhanced_criminal_record_check: false,
  request_us_criminal_record_check_tier_1: false,
  request_us_criminal_record_check_tier_2: false,
  request_soquij: false,
};

const initialQuestion = {
  text: '',
  preferredAnswer: true,
  knockout: false,
};

const initialFreeFormQuestion = {
  text: '',
  isActive: true,
};

export type NewCreateJobsViewProps = {
  positionDataToEdit: JopPositionResponse | null;
  redirectToPositionAfterCreated: (jobPositionData: JobPosition) => void;
  selectedBusiness: Business | null;
  onClose: () => void;
  createNewPosition: boolean;
  updatePositionAutomation?: (
    givenPositionId: string,
    givenAutomationType: string | null,
    screeningTarget: number | undefined,
    shouldUpdateAutomationApiCalled?: boolean,
    schedulingVrEnabled?: boolean,
  ) => void;
  disablePositionCreation?: boolean;
  showPositionCreationOnPage?: boolean;
};

export const NewCreateJobsView = ({
  positionDataToEdit,
  redirectToPositionAfterCreated,
  selectedBusiness,
  onClose,
  createNewPosition,
  updatePositionAutomation,
  disablePositionCreation,
  showPositionCreationOnPage,
}: NewCreateJobsViewProps): JSX.Element => {
  const screeningOptions = useStoreState((state) => state.app.accounts?.screeningOptions);
  const initialSalaryData = {
    customUnit: null,
    maxValue: '',
    minValue: '',
    unit: 'hourly',
  };

  const initialInterviewData = {
    interviewType: 'video',
    interviewDuration: 30,
    interviewMessage: '',
    interviewLocation: '',
  };

  const initialWorkSchedule = {
    sun: { day: '', evening: '', night: '' },
    mon: { day: 'AM_PM', evening: '', night: '' },
    tue: { day: 'AM_PM', evening: '', night: '' },
    wed: { day: 'AM_PM', evening: '', night: '' },
    thu: { day: 'AM_PM', evening: '', night: '' },
    fri: { day: 'AM_PM', evening: '', night: '' },
    sat: { day: '', evening: '', night: '' },
  } as { [index: string]: Schedule };

  const DEFAULT_LANGUAGE_EVALUATION_PASS_THRESHOLD = 2;

  const backgroundChecks = useBackgroundChecks();

  const getScreeningOptionData = () => {
    const newOptions: BackgroundChecks = initialBackgroundCheck;
    backgroundChecks.forEach((bc) => {
      newOptions[bc.id as keyof BackgroundChecks] =
        !_isNil(screeningOptions) && !_isNil(positionDataToEdit) && !_isNil(positionDataToEdit?.screeningOptions)
          ? screeningOptions[bc.id as keyof BackgroundChecks] &&
            positionDataToEdit.screeningOptions[bc.id as keyof BackgroundChecks]
          : false;
    });
    return positionDataToEdit ? newOptions : screeningOptions;
  };

  const initialJobPositionData = {
    automated: false,
    automationType: null,
    industryName: '',
    industry: '',
    occupation: '',
    job: '',
    jobCustomName: '',
    business: '',
    businessName: '',
    businessAddress: '',
    noOfOpenings: null,
    proposedSalary: initialSalaryData,
    experienceFilterType: null,
    requiredExperience: null,
    jobDescription: '',
    recruiterNote: positionDataToEdit?.recruiterNotes
      ? positionDataToEdit?.recruiterNotes.filter((item) => item.id === 'default')[0]?.notes
      : undefined,
    fullTime: true,
    interview: initialInterviewData,
    specificQuestions: ([] as unknown) as Question[],
    freeFormQuestions: ([] as unknown) as FreeFormQuestion[],
    multipleChoiceQuestions: ([] as unknown) as MultipleChoiceQuestion[],
    schedule: initialWorkSchedule,
    skills: [],
    enableHireFormSolution: false,
    modernHireEnable: false,
    modernHirePassThreshold: 0,
    modernHireTemplateId: '',
    enableScheduleMatch: false,
    virtualRecruiterEnabled: false,
    screeningOptions: getScreeningOptionData(),
    enableScreeningOptions: positionDataToEdit?.enableScreeningOptions ?? true,
    languageEvaluationPassThreshold: positionDataToEdit?.languageEvaluationPassThreshold
      ? positionDataToEdit?.languageEvaluationPassThreshold
      : DEFAULT_LANGUAGE_EVALUATION_PASS_THRESHOLD,
    dialogFlowAgents: positionDataToEdit?.dialogFlowEnabled
      ? _uniqBy(
          _filter(
            positionDataToEdit?.dialogFlowAgents,
            (agent) => agent.defaultLanguageCode === 'fr' || agent.defaultLanguageCode === 'en',
          ),
          'defaultLanguageCode',
        ) ?? []
      : undefined,
    dialogFlowEnabled: Boolean(positionDataToEdit?.dialogFlowEnabled),
    resumeEnabled: Boolean(positionDataToEdit?.resumeEnabled),
  };

  const [industries, setIndustries] = useState<IndustryType[]>([]);
  const [jobList, setJobList] = useState<JobType[]>([]);
  const [occupationList, setOccupationList] = useState<OccupationType[]>([]);
  const [jobPositionData, setJobPositionData] = useState<JobPosition>(initialJobPositionData);
  const [isInterviewAndBusinessLocationSame, setIsInterviewAndBusinessLocationSame] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showFreeFormQuestion, setFreeFormQuestion] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<FormattedError[]>([]);
  const [salaryError, setSalaryError] = useState<FormattedError[]>([]);
  const jobPositionRef = useRef<null | HTMLDivElement>(null);
  const [overviewVideoFile, setOverviewVideoFile] = useState<File | null>(null);
  const [overviewThumbnailFile, setOverviewThumbnailFile] = useState<File | null>(null);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const enableMultipleChoiceFeature: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_MULTIPLE_CHOICE_FEATURE];
  });

  const { isOpen: isReviewOpened, onOpen: onOpenReview, onClose: onCloseReview } = useDisclosure();
  const { isOpen: isComplianceModalOpened, onOpen: onOpenComplianceModal, onClose: onCloseComplianceModal } = useDisclosure(); // useDisclosure for controlling modal state

  const { t, i18n } = useTranslation();
  const toast = useToast();
  const {
    getIndustries,
    getJobList,
    getOccupationList,
    createJobPosition,
    updateJobPosition,
    uploadOverviewVideo,
    uploadOverviewThumbnail,
    uploadOverviewVideoConfirmCall,
  } = useStoreActions((actions) => actions.positionManagement);
  const { user, accounts } = useStoreState((s) => s.app);
  const positionComplianceCheckedDate = useStoreState((s) => s.app.user?.positionComplianceCheckedDate);
  const hireFormEnabledPositions = useStoreState((s) => s.app.accounts?.configuration?.hireFormEnabledPositions);
  const isCertnBackgroundEnabled = useStoreState((s) => s.app.accounts?.configuration?.certnEnabled);
  const businessLocations = useBusinessLocations();
  const enableToggleOfBackgroundCheckOnPositionLevel: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_TOGGLE_OF_BACKGROUND_CHECK_ON_POSITION_LEVEL];
  });

  const { businessData, setPositionQueryParams, positionQueryParams } = usePositionManagementContext();
  const audioEvaluationOptions = useLookupsByType(AUDIO_EVALUATION_OPTIONS);

  const isSmbRecruiter = useStoreState((state) => state.app.accounts?.smb);
  const isRequisitionEnabled = useStoreState((state) => state.app.accounts?.configuration?.requisitionEnabled);

  const { businessId: businessIdFromParams } = useParams<{ businessId: string }>();

  const loadIndustriesJobsOccupation = useCallback(async () => {
    const industriesResponse = await getIndustries(null);
    const jobListResponse = await getJobList(null);
    const occupationListResponse = await getOccupationList(null);

    const allowedIndustryIds = [1, 2, 3, 9];
    const smbFilteredIndustries = industriesResponse.industries.filter((industry) => allowedIndustryIds.includes(industry.id));

    setIndustries(isSmbRecruiter ? smbFilteredIndustries : industriesResponse.industries);
    setOccupationList(occupationListResponse.occupations);
    setJobList(jobListResponse.jobs);
  }, [getIndustries, getJobList, getOccupationList, isSmbRecruiter]);

  const getIndustryName = (value: any) => {
    // eslint-disable-next-line radix
    return getTranslatedValue(industries.filter((industry) => industry.id === parseInt(value))[0].name, i18n.language);
  };

  const contactEmailForAuthorization = useContactEmailForAccountAuthorisation();

  const checkIfComplianceModalNeeded = (callback?: () => void) => {
    if (isNil(positionComplianceCheckedDate)) {
      onOpenComplianceModal();
      return;
    }

    if (callback) {
      callback();
    }
  };

  const interviewSchema = Joi.object().keys({
    interviewType: Joi.string()
      .required()
      .messages({
        'string.empty': t('createJobPosition:interviewTypeRequired'),
      }),
    interviewDuration: Joi.number()
      .required()
      .messages({
        'number.base': t('createJobPosition:interviewDurationRequired'),
      }),
    interviewMessage: Joi.string().trim(true).required().allow('').optional(),
    interviewLocation:
      jobPositionData.interview.interviewType === 'inPerson'
        ? Joi.string()
            .trim(true)
            .required()
            .messages({
              'string.empty': t('createJobPosition:interviewLocationRequired'),
            })
        : Joi.string().allow('').optional(),
  });

  const automationConfigurationSchema = Joi.object().keys({
    target: !_isNil(jobPositionData.automationConfiguration)
      ? Joi.number()
          .min(1)
          .required()
          .messages({
            'number.base': t('createJobPosition:automationConfigurationRequired'),
            'number.min': t('createJobPosition:automationConfigurationMinRequired'),
          })
      : Joi.number().allow(null).optional(),
  });

  const proposedSalarySchema = Joi.object()
    .optional()
    .keys({
      unit: Joi.string().optional(),
      minValue: Joi.string().allow(null).allow('').optional(),
      maxValue: Joi.string().allow(null).allow('').optional(),
      customUnit:
        jobPositionData.proposedSalary.unit === 'custom'
          ? Joi.string()
              .required()
              .messages({
                'string.empty': t('createJobPosition:unitRequired'),
              })
          : Joi.string().allow('').allow(null).optional(),
    });

  const smbProposedSalarySchema = Joi.object().keys({
    unit: Joi.string()
      .required()
      .messages({
        'string.empty': t('createJobPosition:salaryUnitRequired'),
      }),
    minValue: Joi.number()
      .required()
      .greater(0)
      .messages({
        'any.required': t('createJobPosition:minValueRequired'),
      }),
    maxValue: Joi.number()
      .required()
      .greater(Joi.ref('minValue'))
      .messages({
        'any.required': t('createJobPosition:maxValueRequired'),
      }),
    customUnit:
      jobPositionData.proposedSalary.unit === 'custom'
        ? Joi.string()
            .required()
            .messages({
              'string.empty': t('createJobPosition:unitRequired'),
            })
        : Joi.string().allow('').allow(null).optional(),
  });

  const smbJobDetailSchema = Joi.object()
    .options({ abortEarly: false })
    .keys({
      industry: Joi.string()
        .required()
        .messages({
          'string.empty': t('createJobPosition:industryRequired'),
        }),
      occupation: Joi.string()
        .required()
        .messages({
          'string.empty': t('createJobPosition:occupationRequired'),
        }),
      job: Joi.string()
        .required()
        .messages({
          'string.empty': t('createJobPosition:jobRequired'),
        }),
      jobCustomName: Joi.string()
        .trim(true)
        .required()
        .messages({
          'string.empty': t('createJobPosition:jobTitleRequired'),
        }),
      noOfOpenings: Joi.number()
        .required()
        .messages({
          'number.base': t('createJobPosition:noOfOpeningsRequired'),
        }),
      jobDescription: Joi.string()
        .trim(true)
        .required()
        .messages({
          'string.empty': t('createJobPosition:jobDescriptionRequired'),
        }),
      proposedSalary: smbProposedSalarySchema,
    })
    .unknown(true);

  const jobDetailSchema = Joi.object()
    .options({ abortEarly: false })
    .keys({
      industry: Joi.string()
        .required()
        .messages({
          'string.empty': t('createJobPosition:industryRequired'),
        }),
      occupation: Joi.string()
        .required()
        .messages({
          'string.empty': t('createJobPosition:occupationRequired'),
        }),
      job: Joi.string()
        .required()
        .messages({
          'string.empty': t('createJobPosition:jobRequired'),
        }),
      jobCustomName: Joi.string()
        .trim(true)
        .required()
        .messages({
          'string.empty': t('createJobPosition:jobTitleRequired'),
        }),
      noOfOpenings: isRequisitionEnabled
        ? Joi.number().allow(null).optional()
        : Joi.number()
            .required()
            .messages({
              'number.base': t('createJobPosition:noOfOpeningsRequired'),
            }),
      experienceFilterType: Joi.string().allow('').allow(null).optional(),
      requiredExperience: Joi.number().allow('').allow(null).optional(),
      jobDescription: Joi.string().allow('').optional(),
      modernHirePassThreshold: Joi.number().when('modernHireEnable', {
        is: Joi.boolean().valid(true),
        then: Joi.number()
          .min(1)
          .positive()
          .required()
          .messages({
            'string.empty': t('createJobPosition:modernHireThresholdValueNotEmptyError'),
            'any.required': t('createJobPosition:modernHireThresholdValueNotEmptyError'),
            'number.min': t('createJobPosition:modernHireThresholdValueNotZeorError'),
          }),
        otherwise: Joi.allow('').allow(null),
      }),

      languageEvaluationTemplates: Joi.array()
        .allow(null)
        .min(1)
        .messages({
          'array.min': t('createJobPosition:templateRequiredError'),
        }),

      dialogFlowAgents: Joi.array().when('dialogFlowEnabled', {
        is: Joi.boolean().valid(true),
        then: Joi.array()
          .min(2)
          .messages({
            'array.min': t('createJobPosition:languageAgentRequiredError'),
          }),
        otherwise: Joi.allow('').allow(null),
      }),

      modernHireTemplateId: Joi.string().when('modernHireEnable', {
        is: Joi.boolean().valid(true),
        then: Joi.string()
          .required()
          .messages({
            'string.empty': t('createJobPosition:modernHireTemplateIdError'),
            'any.required': t('createJobPosition:modernHireTemplateIdError'),
          }),
        otherwise: Joi.allow('').allow(null),
      }),
      proposedSalary: proposedSalarySchema,
      interview: interviewSchema,
      automationConfiguration: automationConfigurationSchema,
    })
    .unknown(true);

  const formValidation = (formData: any, scheme: Joi.ObjectSchema) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { error } = scheme.validate(formData);
    if (error) {
      console.error(error);
      setFormErrors(errorFormat(error.details));
      return false;
    }
    setFormErrors([]);
    return true;
  };

  const handleChange = (value: string | Date | number | boolean | null, fieldName: string, dataType = false) => {
    updateObject(jobPositionData, fieldName, value, dataType);

    if (fieldName === 'industry') {
      jobPositionData.occupation = '';
      jobPositionData.job = '';
      jobPositionData.industryName = getIndustryName(value);
    }

    if (fieldName === 'occupation') {
      jobPositionData.job = '';
    }

    if (fieldName === 'modernHireEnable') {
      setFormErrors([]);
    } else {
      setFormErrors(clearErrorMessage(formErrors, fieldName));
    }

    if (fieldName === 'proposedSalary.unit') {
      if (value !== 'custom') {
        jobPositionData.proposedSalary.customUnit = null;
      }
    }

    setJobPositionData({ ...jobPositionData });
  };

  const handleSalaryChange = (value: string, fieldName: string) => {
    const translated = !/^(?:\d*[.|,]\d{1,2}|\d+)$/.exec(value);
    let allowInput = true;

    const spliterator = value.includes('.') ? value.split('.') : value.split(',');
    if (spliterator[1]) {
      if (spliterator[1].length > 2) {
        allowInput = false;
      }
    }
    if (allowInput) {
      setJobPositionData((prevData) => ({
        ...prevData,
        proposedSalary: {
          ...prevData.proposedSalary,
          [fieldName]: value.toString().replace(',', '.'),
        },
      }));

      if (value === '' || !translated) {
        setSalaryError([]);
      } else {
        const hasAlreadyError = salaryError.filter((err) => err.label === fieldName);
        if (hasAlreadyError.length < 1) {
          setSalaryError((prevData) => [
            ...prevData,
            {
              label: fieldName,
              message: t('createJobPosition:invalidNumberFormat'),
            },
          ]);
        }
      }

      if (fieldName === 'maxValue') {
        if (
          jobPositionData.proposedSalary.minValue !== '' &&
          Number(jobPositionData.proposedSalary.minValue) > parseFloat(value)
        ) {
          setSalaryError((prevData) => [
            ...prevData,
            {
              label: fieldName,
              message: t('createJobPosition:maxValueShouldBeGreaterThanMinValue', {
                minValue: jobPositionData.proposedSalary.minValue,
              }),
            },
          ]);
        }
        if (jobPositionData.proposedSalary.minValue === '') {
          setSalaryError((prevData) => [
            ...prevData,
            {
              label: 'minValue',
              message: t('createJobPosition:minValueRequired'),
            },
          ]);
        }

        if (!/^(?:\d*[.|,]\d{1,2}|\d+)$/.exec(jobPositionData.proposedSalary.minValue.toString())) {
          setSalaryError((prevData) => [
            ...prevData,
            {
              label: 'minValue',
              message: t('createJobPosition:invalidNumberFormat'),
            },
          ]);
        }
        if (!_isNil(jobPositionData.proposedSalary.minValue) && value === '') {
          setSalaryError((prevData) => [
            ...prevData,
            {
              label: fieldName,
              message: t('createJobPosition:maxValueRequired'),
            },
          ]);
        }
        if (value === '') {
          setSalaryError([]);
        }
        if (jobPositionData.proposedSalary.minValue === '' && value === '') {
          setSalaryError([]);
        }
      }

      if (fieldName === 'minValue') {
        if (
          jobPositionData.proposedSalary.maxValue !== '' &&
          Number(jobPositionData.proposedSalary.maxValue) < parseFloat(value)
        ) {
          setSalaryError((prevData) => [
            ...prevData,
            {
              label: fieldName,
              message: t('createJobPosition:minValueShouldBeLowerThanMaxValue', {
                maxValue: jobPositionData.proposedSalary.maxValue,
              }),
            },
          ]);
        }
        if (jobPositionData.proposedSalary.maxValue !== '') {
          if (!/^(?:\d*[.|,]\d{1,2}|\d+)$/.exec(jobPositionData.proposedSalary.maxValue.toString())) {
            setSalaryError((prevData) => [
              ...prevData,
              {
                label: 'maxValue',
                message: t('createJobPosition:invalidNumberFormat'),
              },
            ]);
          }
        }

        if (!_isNil(jobPositionData.proposedSalary.maxValue) && value === '') {
          setSalaryError((prevData) => [
            ...prevData,
            {
              label: fieldName,
              message: t('createJobPosition:minValueRequired'),
            },
          ]);
        }
        if (jobPositionData.proposedSalary.maxValue === '' && value === '') {
          setSalaryError([]);
        }
      }
    }
  };

  const handleAutomationChange = (value: string | null, fieldName: string, dataType = false) => {
    updateObject(jobPositionData, fieldName, value, dataType);
    if (value === AutomationTypes.SCREENING) {
      jobPositionData.automationType = AutomationTypes.SCREENING;
      jobPositionData.automated = true;
      jobPositionData.automationConfiguration = { target: null };
    } else if (value === AutomationTypes.SCHEDULING) {
      jobPositionData.automationType = AutomationTypes.SCHEDULING;
      jobPositionData.automated = true;
      delete jobPositionData.automationConfiguration;
    } else {
      jobPositionData.automationType = null;
      jobPositionData.automated = false;
      delete jobPositionData.automationConfiguration;
    }
    setFormErrors(clearErrorMessage(formErrors, fieldName));
    setJobPositionData({ ...jobPositionData });
  };

  const handleJobChange = (value: string, fieldName: string) => {
    let skillList: SkillForPosition[] = [];
    /* istanbul ignore else */
    if (!_isEmpty(value)) {
      // eslint-disable-next-line radix
      const filterSkills = jobList.filter((job) => job.id === parseInt(value));
      skillList = filterSkills[0].skills.map((skill) => ({
        id: skill.id,
        value: 0.5,
      }));
    }

    setJobPositionData((prevData) => ({
      ...prevData,
      [fieldName]: value,
      skills: skillList,
    }));
    setFormErrors(clearErrorMessage(formErrors, fieldName));
  };

  const isOverviewVideoFeatureEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_OVERVIEW_VIDEO];
  });

  const handleAvailabilityChange = (availability: { [key: string]: { start_1: string; end_1: string } }) => {
    setJobPositionData((prevData) => ({
      ...prevData,
      newAvailabilities: availability,
    }));
  };

  const handleSkillChange = (value: number, fieldName: number) => {
    Object.assign(jobPositionData?.skills?.find((skill) => skill.id === fieldName) || {}, { value });

    setJobPositionData((prevData) => ({
      ...prevData,
      skills: jobPositionData.skills,
    }));
  };

  const handleLanguageEvaluationPhrase = (checked: boolean, language: string, phrase: LanguageEvaluationPhrases) => {
    const formatPhrase = {
      language,
      phrase: phrase.description || '',
      phraseId: phrase.id || '',
    };

    if (checked) {
      jobPositionData.languageEvaluationTemplates?.push(formatPhrase);
      if (jobPositionData.languageEvaluationTemplates?.length) {
        setFormErrors(clearErrorMessage(formErrors, 'languageEvaluationTemplates'));
      }
    } else {
      const indexOfItem = _findIndex(jobPositionData.languageEvaluationTemplates, { phraseId: phrase.id });
      if (indexOfItem > -1) {
        jobPositionData.languageEvaluationTemplates?.splice(indexOfItem, 1);
      }
    }

    setJobPositionData((prevData) => ({
      ...prevData,
      languageEvaluationTemplates: jobPositionData.languageEvaluationTemplates,
    }));
  };

  const handleDialogFlowAgents = (agent: DialogFlowAgent | undefined) => {
    if (agent) {
      const { location, defaultLanguageCode, agentId, displayName } = agent;
      const formatAgent = {
        location,
        defaultLanguageCode,
        agentId,
        displayName,
      };
      const indexOfItem = _findIndex(jobPositionData.dialogFlowAgents, { defaultLanguageCode: agent.defaultLanguageCode });
      if (indexOfItem > -1) {
        jobPositionData.dialogFlowAgents?.splice(indexOfItem, 1);
      }
      jobPositionData.dialogFlowAgents?.push(formatAgent);

      setJobPositionData((prevData) => ({
        ...prevData,
        dialogFlowAgents: jobPositionData.dialogFlowAgents,
      }));

      if (jobPositionData.dialogFlowAgents && jobPositionData.dialogFlowAgents?.length >= 2) {
        setFormErrors(clearErrorMessage(formErrors, 'dialogFlowAgents'));
      }
    }
  };

  const handleDialogFlowCheckbox = (checked: boolean) => {
    setJobPositionData((prevData) => ({
      ...prevData,
      dialogFlowEnabled: checked,
      dialogFlowAgents: checked ? [] : undefined,
    }));
  };

  const handleLanguageEvaluation = (checked: boolean) => {
    setJobPositionData((prevData) => ({
      ...prevData,
      languageEvaluationTemplates: checked ? [] : undefined,
      languageEvaluationPassThreshold: checked
        ? prevData.languageEvaluationPassThreshold || DEFAULT_LANGUAGE_EVALUATION_PASS_THRESHOLD
        : undefined,
    }));
  };

  const handleLanguageChange = () => {
    setJobPositionData((prevData) => ({
      ...prevData,
      languageEvaluationTemplates: [],
    }));
  };

  const handleChangeInterviewLocation = (isSameLocation: boolean) => {
    setJobPositionData((prevData) => ({
      ...prevData,
      interview: {
        ...prevData.interview,
        interviewLocation: isSameLocation ? jobPositionData.businessAddress : '',
      },
    }));
    setIsInterviewAndBusinessLocationSame(isSameLocation);
    setFormErrors(clearErrorMessage(formErrors, 'interview.interviewLocation'));
  };

  const deleteQuestion = (questionId: number | undefined) => {
    setJobPositionData((prevData) => ({
      ...prevData,
      specificQuestions: jobPositionData.specificQuestions.filter((sQue, index) => index !== questionId),
    }));
  };

  // eslint-disable-next-line consistent-return
  const addQuestion = (question: Question, questionEditMode: number | undefined) => {
    if (_isNil(questionEditMode)) {
      setJobPositionData((prevData) => ({
        ...prevData,
        specificQuestions: [...prevData.specificQuestions, { ...question }],
      }));
    } else {
      if (jobPositionData.specificQuestions.length > 0) {
        Object.assign(jobPositionData?.specificQuestions.find((ques, index) => index === questionEditMode) || {}, {
          ...question,
        });
      } else {
        jobPositionData.specificQuestions.push(question);
      }
      setJobPositionData((prevData) => ({
        ...prevData,
        specificQuestions: jobPositionData.specificQuestions,
      }));
    }
  };

  const deleteFreeFormQuestion = (questionId: number | undefined) => {
    setJobPositionData((prevData) => ({
      ...prevData,
      freeFormQuestions: jobPositionData.freeFormQuestions.filter((sQue, index) => index !== questionId),
    }));
  };

  const deleteMultipleQuestionsQuestion = (questionId: number | null) => {
    setJobPositionData((prevData) => ({
      ...prevData,
      multipleChoiceQuestions: jobPositionData.multipleChoiceQuestions.filter((sQue, index) => index !== questionId),
    }));
  };

  // eslint-disable-next-line consistent-return
  const addFreeFormQuestion = (freeFormQuestion: FreeFormQuestion, freeFormQuestionEditMode: number | undefined) => {
    if (_isNil(freeFormQuestionEditMode)) {
      setJobPositionData((prevData) => ({
        ...prevData,
        freeFormQuestions: [...prevData.freeFormQuestions, { ...freeFormQuestion }],
      }));
    } else {
      if (jobPositionData.freeFormQuestions.length > 0) {
        Object.assign(jobPositionData?.freeFormQuestions?.find((ques, index) => index === freeFormQuestionEditMode) || {}, {
          ...freeFormQuestion,
        });
      } else {
        jobPositionData.freeFormQuestions.push(freeFormQuestion);
      }
      setJobPositionData((prevData) => ({
        ...prevData,
        freeFormQuestions: jobPositionData.freeFormQuestions,
      }));
    }
  };
  const addMultipleChoiceQuestion = (
    multipleChoiceQuestions: MultipleChoiceQuestion,
    multipleQuestionEditMode: number | undefined,
  ) => {
    if (_isNil(multipleQuestionEditMode)) {
      setJobPositionData((prevData) => ({
        ...prevData,
        multipleChoiceQuestions: [...prevData.multipleChoiceQuestions, { ...multipleChoiceQuestions }],
      }));
    } else {
      if (jobPositionData.multipleChoiceQuestions.length > 0) {
        Object.assign(jobPositionData?.multipleChoiceQuestions?.find((ques, index) => index === multipleQuestionEditMode) || {}, {
          ...multipleChoiceQuestions,
        });
      } else {
        jobPositionData.multipleChoiceQuestions.push(multipleChoiceQuestions);
      }
      setJobPositionData((prevData) => ({
        ...prevData,
        multipleChoiceQuestions: jobPositionData.multipleChoiceQuestions,
      }));
    }
  };

  const handleOverviewVideoUpload = async ({
    userId,
    positionId,
    overviewVideo,
    overviewThumbnail,
  }: {
    userId: string;
    positionId: string;
    overviewVideo: File;
    overviewThumbnail: File;
  }) => {
    await uploadOverviewVideo({ userId, positionId, overviewVideo });
    await uploadOverviewThumbnail({ userId, positionId, overviewThumbnail });
    await uploadOverviewVideoConfirmCall({ positionId });
  };

  const saveJobPosition = async () => {
    onCloseReview();
    if (user) {
      setIsLoading(true);
      if (_isNil(accounts?.configuration?.certnEnabled) || !accounts?.configuration?.certnEnabled) {
        jobPositionData.screeningOptions = undefined;
      }

      if (!enableToggleOfBackgroundCheckOnPositionLevel) {
        jobPositionData.enableScreeningOptions = undefined;
      }

      if (_isNil(jobPositionData.languageEvaluationTemplates) || !jobPositionData.languageEvaluationTemplates.length) {
        jobPositionData.languageEvaluationPassThreshold = undefined;
      }

      if (
        jobPositionData?.screeningOptions &&
        !Object.keys(Object.fromEntries(Object.entries(jobPositionData?.screeningOptions).filter(([, v]) => v))).length
      ) {
        jobPositionData.screeningOptions = _isNil(positionDataToEdit) ? undefined : null;
      }
      if (_isNil(positionDataToEdit)) {
        if (selectedBusiness) {
          jobPositionData.business = selectedBusiness.id;
          jobPositionData.businessAddress = selectedBusiness.completeAddress;
          jobPositionData.businessName = selectedBusiness.name;
        }
        if (isSmbRecruiter && businessIdFromParams) {
          jobPositionData.business = businessIdFromParams;
        }
        if (!accounts?.subscription?.automation) {
          jobPositionData.automationType = null;
          jobPositionData.automated = false;
        }
        const newPostition = await createJobPosition({
          accountId: user.account,
          jobPositionData,
        })
          .then((res) => res)
          .catch((error) => {
            let defaultMessage = t('createJobPosition:errorPositionCreation');
            const newError = error as AxiosError<{ message: string }>;
            if (axios.isAxiosError(newError)) {
              defaultMessage = newError.response?.data?.message || defaultMessage;
            }
            toast({
              description: defaultMessage,
              status: 'error',
              duration: 6000,
              isClosable: true,
            });
            throw error;
          })
          .finally(() => {
            if (!(overviewVideoFile && overviewThumbnailFile && isOverviewVideoFeatureEnabled)) setIsLoading(false);
          });
        jobPositionData.positionId = newPostition.positionId;
        if (overviewVideoFile && overviewThumbnailFile && isOverviewVideoFeatureEnabled) {
          await handleOverviewVideoUpload({
            userId: user.id,
            positionId: newPostition.positionId,
            overviewVideo: overviewVideoFile,
            overviewThumbnail: overviewThumbnailFile,
          });
        }
        setIsLoading(false);
        setJobPositionData(initialJobPositionData);
        toast({
          title: t('createJobPosition:jobCreatedSuccessMessage'),
          status: 'info',
          duration: 6000,
          isClosable: true,
        });
        const currentBusinessLocation = businessData.find((business) => business.id === jobPositionData.business);
        if (currentBusinessLocation) {
          const newpositionQueryParams = positionQueryParams;
          newpositionQueryParams.positionStatus = 'closed';
          setPositionQueryParams({ ...newpositionQueryParams });
        }
      } else {
        await updateJobPosition({
          accountId: user.account,
          jobPositionData,
          positionId: positionDataToEdit?.id,
        })
          .then(async () => {
            if (overviewVideoFile && overviewThumbnailFile && isOverviewVideoFeatureEnabled) {
              // Put video upload logic here
              await handleOverviewVideoUpload({
                userId: user.id,
                positionId: positionDataToEdit.id,
                overviewVideo: overviewVideoFile,
                overviewThumbnail: overviewThumbnailFile,
              });
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
        setTimeout(() => {
          setJobPositionData(initialJobPositionData);
          updatePositionAutomation?.(
            positionDataToEdit?.id,
            isUndefined(jobPositionData?.automationType) ? null : jobPositionData?.automationType,
            jobPositionData?.automationConfiguration?.target ? jobPositionData?.automationConfiguration?.target : undefined,
            false,
            jobPositionData.virtualRecruiterEnabled,
          );
        }, 2000);
        toast({
          title: t('createJobPosition:jobUpdatedSuccessMessage'),
          status: 'info',
          duration: 6000,
          isClosable: true,
        });
      }
      redirectToPositionAfterCreated(jobPositionData);
    }
  };

  const checkIfSalaryExists = (givenJobPositionData: JobPosition) => {
    if (givenJobPositionData.proposedSalary.minValue === '') {
      setSalaryError((prevData) => [
        ...prevData,
        {
          label: 'minValue',
          message: t('createJobPosition:minValueRequired'),
        },
      ]);
    }
    if (givenJobPositionData.proposedSalary.maxValue === '') {
      setSalaryError((prevData) => [
        ...prevData,
        {
          label: 'maxValue',
          message: t('createJobPosition:maxValueRequired'),
        },
      ]);
    }
  };

  const onSmbPositionSave = () => {
    const validate = formValidation(jobPositionData, smbJobDetailSchema);
    checkIfSalaryExists(jobPositionData);

    if (validate) {
      checkIfComplianceModalNeeded();
    }
  };

  const getInterviewData = (interview: Interview) => {
    return {
      interviewType: interview.interviewType ? interview.interviewType : 'video',
      interviewDuration: interview.interviewDuration ? interview.interviewDuration : 30,
      interviewMessage: interview.interviewMessage ? interview.interviewMessage : '',
      interviewLocation: interview.interviewLocation ? interview.interviewLocation : '',
    } as Interview;
  };

  const loadSkillsOfJobType = (jobId: string): SkillForPosition[] => {
    const { skills } = jobList.filter((job) => job.id.toString() === jobId)[0];
    if (skills.length > 0) {
      return skills.map((skill) => ({
        id: skill.id,
        value: skill.value,
      }));
    }
    return [];
  };

  const getBusinessAddress = (businessId: string) => {
    const selectedBusinessLocation = businessLocations.find((businessLocation) => businessLocation.id === businessId);

    if (selectedBusinessLocation) {
      return selectedBusinessLocation.label;
    }
    return '';
  };

  useEffect(() => {
    const isFreeFormQuestionEnabled = accounts?.configuration?.freeFormQuestionEnabled === true;
    setFreeFormQuestion(isFreeFormQuestionEnabled);
  }, [accounts]);

  useEffect(() => {
    jobPositionRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    loadIndustriesJobsOccupation().catch(/* istanbul ignore next */ (err) => console.log(err));
  }, [loadIndustriesJobsOccupation]);

  useEffect(() => {
    if (!_isNil(positionDataToEdit) && jobList.length > 0) {
      const jobTypeId = positionDataToEdit?.job;
      const industryId = jobList.filter((jobItem) => jobItem.id.toString() === jobTypeId)[0].industry;
      const occupationId = jobList.filter((jobItem) => jobItem.id.toString() === jobTypeId)[0].occupation;
      const proposedSalary = positionDataToEdit?.proposedSalary
        ? {
            ...positionDataToEdit?.proposedSalary,
            minValue: positionDataToEdit?.proposedSalary.minValue
              ? (positionDataToEdit?.proposedSalary.minValue / 100).toString()
              : '',
            maxValue: positionDataToEdit?.proposedSalary.maxValue
              ? (positionDataToEdit?.proposedSalary.maxValue / 100).toString()
              : '',
            unit: positionDataToEdit?.proposedSalary.unit ? positionDataToEdit?.proposedSalary.unit : 'hourly',
            customUnit: positionDataToEdit?.proposedSalary.customUnit ? positionDataToEdit?.proposedSalary.customUnit : null,
          }
        : initialSalaryData;
      const multipleChoiceQuestions: MultipleChoiceQuestion[] = [];
      if (positionDataToEdit.multipleChoiceQuestions) {
        positionDataToEdit?.multipleChoiceQuestions.forEach((que) => {
          const newQues = que;
          const newOptions: {
            text: string;
            isPreferredAnswer?: boolean | undefined;
            priority: number;
            id?: string | undefined;
          }[] = [];
          que.options.forEach((opt: { text: string; isPreferredAnswer?: boolean; priority: number; id?: string }) => {
            // eslint-disable-next-line no-param-reassign
            opt.isPreferredAnswer = que.preferredAnswers?.includes(opt.id ? opt.id : '');
            newOptions.push(opt);
          });
          newQues.options = newOptions;
          multipleChoiceQuestions.push(_omit(newQues, 'preferredAnswers'));
        });
      }

      setJobPositionData((prevData) => ({
        ...prevData,
        industryName: getTranslatedValue(industries.filter((industry) => industry?.id === industryId)[0]?.name, i18n.language),
        industry: industryId.toString(),
        occupation: occupationId.toString(),
        job: positionDataToEdit?.job,
        jobCustomName: positionDataToEdit?.customName ? positionDataToEdit?.customName : positionDataToEdit?.jobCustomName,
        business: positionDataToEdit?.business,
        businessName: positionDataToEdit?.businessName,
        businessAddress: positionDataToEdit.business ? getBusinessAddress(positionDataToEdit?.business) : '',
        noOfOpenings: positionDataToEdit.noOfOpenings ? positionDataToEdit.noOfOpenings : null,
        proposedSalary,
        experienceFilterType: positionDataToEdit.experienceFilterType ? positionDataToEdit.experienceFilterType : null,
        requiredExperience: positionDataToEdit.requiredExperience ? positionDataToEdit.requiredExperience : null,
        jobDescription: positionDataToEdit.description ? positionDataToEdit.description : '',
        fullTime: positionDataToEdit.fullTime ? positionDataToEdit?.fullTime : false,
        interview: positionDataToEdit.interview ? getInterviewData(positionDataToEdit.interview) : initialInterviewData,
        specificQuestions: positionDataToEdit?.specificQuestions ? positionDataToEdit.specificQuestions : [],
        freeFormQuestions: positionDataToEdit?.freeFormQuestions ? positionDataToEdit.freeFormQuestions : [],
        multipleChoiceQuestions,
        schedule: positionDataToEdit?.schedule ? positionDataToEdit?.schedule : initialWorkSchedule,
        skills: positionDataToEdit?.skills ? positionDataToEdit?.skills : loadSkillsOfJobType(positionDataToEdit?.job),
        automationType: positionDataToEdit.automationType ? positionDataToEdit.automationType : null,
        automated: positionDataToEdit.automated ? positionDataToEdit.automated : false,
        enableHireFormSolution: hireFormEnabledPositions ? _includes(hireFormEnabledPositions, positionDataToEdit.id) : false,
        modernHireEnable: positionDataToEdit?.modernHireEnable ? positionDataToEdit?.modernHireEnable : false,
        enableScheduleMatch: positionDataToEdit?.enableScheduleMatch ? positionDataToEdit?.enableScheduleMatch : false,
        virtualRecruiterEnabled: positionDataToEdit?.virtualRecruiterEnabled
          ? positionDataToEdit?.virtualRecruiterEnabled
          : false,
        modernHirePassThreshold: positionDataToEdit?.modernHirePassThreshold,
        modernHireTemplateId: positionDataToEdit?.modernHireTemplateId,
        automationConfiguration:
          positionDataToEdit.automationType === 'screening' && positionDataToEdit.automationConfiguration
            ? { target: positionDataToEdit.automationConfiguration.target }
            : undefined,
        languageEvaluationTemplates: positionDataToEdit?.languageEvaluationTemplates,
        videoUrl: positionDataToEdit?.videoUrl,
        videoUrlThumbnail: positionDataToEdit?.videoUrlThumbnail,
        videoUrlId: positionDataToEdit?.videoUrlId,
        languageEvaluationPassThreshold: !_isNil(positionDataToEdit.languageEvaluationTemplates)
          ? positionDataToEdit?.languageEvaluationPassThreshold || DEFAULT_LANGUAGE_EVALUATION_PASS_THRESHOLD
          : undefined,
        enableScreeningOptions: positionDataToEdit?.enableScreeningOptions,
        screeningOptions: positionDataToEdit?.enableScreeningOptions
          ? positionDataToEdit?.screeningOptions
          : initialBackgroundCheck,
        newAvailabilities: positionDataToEdit?.newAvailabilities,
      }));
    } else {
      setJobPositionData(initialJobPositionData);
      if (selectedBusiness) {
        setJobPositionData((prevData) => ({
          ...prevData,
          business: selectedBusiness.id,
          businessName: selectedBusiness.name,
          businessAddress: selectedBusiness.completeAddress,
          languageEvaluationPassThreshold: !_isNil(jobPositionData.languageEvaluationTemplates)
            ? positionDataToEdit?.languageEvaluationPassThreshold || DEFAULT_LANGUAGE_EVALUATION_PASS_THRESHOLD
            : undefined,
        }));
      }
    }
    if (accounts?.automated) {
      setJobPositionData((prevData) => ({
        ...prevData,
        automationType: AutomationTypes.SCHEDULING,
        automated: true,
      }));
    }

    setFormErrors([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobList, positionDataToEdit]);

  const onOpenReviewModal = () => {
    const validate = formValidation(jobPositionData, isSmbRecruiter ? smbJobDetailSchema : jobDetailSchema);
    if (validate) {
      checkIfComplianceModalNeeded(onOpenReview);
    }
  };

  const reOrderedMultipleChoiceQuestions = (orderedMultipleChoiceQuestions: MultipleChoiceQuestion[]) => {
    setJobPositionData((prevData) => ({
      ...prevData,
      multipleChoiceQuestions: orderedMultipleChoiceQuestions,
    }));
  };

  const reOrderSpecificQuestions = (orderedSpecificQuestions: Question[]) => {
    setJobPositionData((prevData) => ({
      ...prevData,
      specificQuestions: orderedSpecificQuestions,
    }));
  };

  const reOrderFreeFormQuestions = (orderedFreeFormQuestions: FreeFormQuestion[]) => {
    setJobPositionData((prevData) => ({
      ...prevData,
      freeFormQuestions: orderedFreeFormQuestions,
    }));
  };

  const handleEnableCertnBackgroundCheck = (checked: boolean) => {
    setJobPositionData((prevData) => ({
      ...prevData,
      enableScreeningOptions: checked,
    }));
  };

  const setBackgroundCheckValue = (checked: boolean, field: string) => {
    if (!_isNil(jobPositionData.screeningOptions)) {
      let selectedValues: BackgroundChecks = { ...jobPositionData.screeningOptions };

      if (field === 'request_criminal_record_check') {
        selectedValues = {
          ...selectedValues,
          request_enhanced_identity_verification: true,
          request_enhanced_criminal_record_check: false,
        };
      } else if (field === 'request_enhanced_criminal_record_check') {
        selectedValues = {
          ...selectedValues,
          request_enhanced_identity_verification: true,
          request_criminal_record_check: false,
        };
      } else if (field === 'request_enhanced_identity_verification') {
        selectedValues = {
          ...selectedValues,
          request_criminal_record_check: false,
          request_enhanced_criminal_record_check: false,
        };
      } else if (field === 'request_us_criminal_record_check_tier_1') {
        selectedValues = {
          ...selectedValues,
          request_us_criminal_record_check_tier_2: false,
        };
      } else if (field === 'request_us_criminal_record_check_tier_2') {
        selectedValues = {
          ...selectedValues,
          request_us_criminal_record_check_tier_1: false,
        };
      }

      selectedValues = {
        ...selectedValues,
        [field]: checked,
      };

      setJobPositionData((prevData) => ({
        ...prevData,
        screeningOptions: selectedValues,
      }));
    }
  };

  const onAddVideoToPosition = (
    videoUrlId: string | undefined,
    newVideoUrl: string | undefined,
    videoUrlThumbnail: string | undefined,
  ) => {
    setJobPositionData((prevData) => ({
      ...prevData,
      videoUrl: newVideoUrl,
      videoUrlId,
      videoUrlThumbnail,
    }));
  };

  const showDetailedPositionCreationFields = () => (
    <Fragment>
      {enableMultipleChoiceFeature ? (
        <Fragment>
          <QuestionsView
            jobPositionData={jobPositionData}
            deleteQuestion={deleteQuestion}
            addQuestion={addQuestion}
            showQuestionForm={showFreeFormQuestion}
            addFreeFormQuestion={addFreeFormQuestion}
            addMultipleChoiceQuestion={addMultipleChoiceQuestion}
            deleteFreeFormQuestion={deleteFreeFormQuestion}
            deleteMultipleQuestionsQuestion={deleteMultipleQuestionsQuestion}
            reOrderedMultipleChoiceQuestions={reOrderedMultipleChoiceQuestions}
            reOrderSpecificQuestions={reOrderSpecificQuestions}
            reOrderFreeFormQuestions={reOrderFreeFormQuestions}
          />
        </Fragment>
      ) : (
        <Fragment>
          <Box mb={showFreeFormQuestion ? 0 : 6}>
            <SpecificQuestionsCard
              jobPositionData={jobPositionData}
              deleteQuestion={deleteQuestion}
              addQuestion={addQuestion}
              showQuestionForm={false}
              questionToEdit={initialQuestion}
              questionToEditMode={undefined}
              onRefreshState={() => {}}
            />
          </Box>
          {showFreeFormQuestion && (
            <FreeFormQuestionsCard
              jobPositionData={jobPositionData}
              deleteFreeFormQuestion={deleteFreeFormQuestion}
              addFreeFormQuestion={addFreeFormQuestion}
              showQuestionForm={false}
              questionToEdit={initialFreeFormQuestion}
              questionToEditMode={undefined}
              onRefreshState={() => {}}
            />
          )}
        </Fragment>
      )}
      {accounts?.subscription?.automation && (
        <Fragment>
          <AutomationCard
            jobPositionData={jobPositionData}
            formErrors={formErrors}
            isInterviewAndBusinessLocationSame={isInterviewAndBusinessLocationSame}
            handleChange={handleChange}
            handleAutomationChange={handleAutomationChange}
            handleChangeInterviewLocation={handleChangeInterviewLocation}
            removePadding
          />
        </Fragment>
      )}
      {jobPositionData.automationType !== AutomationTypes.SCHEDULING && (
        <Fragment>
          <InterviewCard
            jobPositionData={jobPositionData}
            formErrors={formErrors}
            isInterviewAndBusinessLocationSame={isInterviewAndBusinessLocationSame}
            handleChange={handleChange}
            handleChangeInterviewLocation={handleChangeInterviewLocation}
          />
        </Fragment>
      )}
      {isCertnBackgroundEnabled && (
        <Fragment>
          <CertnBackgroundCheckCard
            jobPositionData={jobPositionData}
            formErrors={formErrors}
            setBackgroundCheckValue={setBackgroundCheckValue}
            screeningOptions={screeningOptions}
            setEnableCertnBackgroundCheck={handleEnableCertnBackgroundCheck}
          />
        </Fragment>
      )}

      {isOverviewVideoFeatureEnabled && (
        <Fragment>
          <VideoDetailCard
            positionId={_isNil(positionDataToEdit) ? null : positionDataToEdit.id}
            changeOverviewVideo={(fileToUpload: File | null, thumbnail?: File) => {
              setOverviewVideoFile(fileToUpload);
              if (thumbnail) setOverviewThumbnailFile(thumbnail);
            }}
            saveVideoUrl={(url: string | null) => {
              setVideoUrl(url);
            }}
            jobPositionData={jobPositionData}
            onAddVideoToPosition={onAddVideoToPosition}
          />
        </Fragment>
      )}

      <AdvanceFieldsCard jobPositionData={jobPositionData} jobList={jobList} handleSkillChange={handleSkillChange} />
      <PositionAvailabilityCard
        jobPositionData={jobPositionData}
        handleAvailabilityChange={handleAvailabilityChange}
        handleChange={handleChange}
      />
    </Fragment>
  );

  const positionCreationBodyContent = () => (
    <Box css={createJobPositionStyle} ref={jobPositionRef}>
      <Fragment>
        <JobPositionDetailCard
          jobPositionData={jobPositionData}
          industries={industries}
          occupationList={occupationList}
          jobList={jobList}
          formErrors={formErrors}
          salaryErrors={salaryError}
          positionDataToEdit={!!positionDataToEdit}
          handleJobChange={handleJobChange}
          handleChange={handleChange}
          handleSalaryChange={handleSalaryChange}
          handleLanguageEvaluationPhrase={handleLanguageEvaluationPhrase}
          handleLanguageEvaluation={handleLanguageEvaluation}
          handleLanguageChange={handleLanguageChange}
          handleDialogFlowAgents={handleDialogFlowAgents}
          audioEvaluationOptions={audioEvaluationOptions}
          handleDialogFlowCheckbox={handleDialogFlowCheckbox}
          isSmbRecruiter={isSmbRecruiter}
        />

        {isSmbRecruiter && showPositionCreationOnPage && (
          <Box width="100%" display="flex" justifyContent="flex-end" pb={6} pt={6} borderTop="1px solid #eee">
            <Tooltip
              label={`${t('jobPosition:contactToEnableAuthorization', { configurableEmail: contactEmailForAuthorization })}`}
              shouldWrapChildren
              isDisabled={!disablePositionCreation}
            >
              <Button
                colorScheme="blue"
                onClick={() => onSmbPositionSave()}
                disabled={isLoading || disablePositionCreation}
                isLoading={isLoading}
              >
                {t('createJobPosition:next')}
              </Button>
            </Tooltip>
          </Box>
        )}
      </Fragment>

      {!isSmbRecruiter && showDetailedPositionCreationFields()}
    </Box>
  );

  const loadSubmitLabel = () => {
    if (_isNil(positionDataToEdit)) {
      return t('createJobPosition:stepsNav.submit');
    }

    return t('createJobPosition:stepsNav.update');
  };

  return (
    <>
      {isReviewOpened && (
        <ReviewCreateJobView
          isNewPosition={_isNil(positionDataToEdit)}
          onClose={() => onCloseReview()}
          onSave={() => saveJobPosition()}
          reviewPosition={isReviewOpened}
          jobPositionData={jobPositionData}
          occupationList={occupationList}
          jobList={jobList}
          isCopyPostion={false}
          videoUrl={videoUrl}
          isVideoFeatureEnabled={isOverviewVideoFeatureEnabled}
          audioEvaluationOptions={audioEvaluationOptions}
        />
      )}
      {((!isReviewOpened && !isSmbRecruiter) || !showPositionCreationOnPage) && (
        <Modal
          closeOnOverlayClick={false}
          isOpen={createNewPosition}
          size="5xl"
          scrollBehavior="inside"
          onClose={() => onClose()}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalBody>{positionCreationBodyContent()}</ModalBody>
            <ModalFooter>
              <Button colorScheme="red" mr={3} onClick={() => onClose()} disabled={isLoading}>
                {t('common:button.cancel')}
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => (isSmbRecruiter ? onSmbPositionSave() : onOpenReviewModal())}
                isLoading={isLoading}
              >
                {isSmbRecruiter ? loadSubmitLabel() : t('createJobPosition:reviewPositionBtn')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      {!isReviewOpened && isSmbRecruiter && showPositionCreationOnPage && <Box>{positionCreationBodyContent()}</Box>}

      {isNil(positionComplianceCheckedDate) && (
        <PositionComplianceReminder
          isOpen={isComplianceModalOpened}
          onClose={onCloseComplianceModal}
          context={ComplianceContext.POSITION}
          onConfirm={() => (isSmbRecruiter ? saveJobPosition() : onOpenReview())}
        />
      )}
    </>
  );
};
