import React from 'react';
import { Box, Tab, TabList, Tabs, TabPanels, TabPanel } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import LanguageEvaluationPhrasesView from '../languageEvaluationPhrases/LanguageEvaluationPhrasesView';
import { LanguageEvaluationTemplateView } from './languageEvaluationTemplate/LanguageEvaluationTemplateView';

export enum LanguageEvaluationTabItems {
  LanguageEvaluationPhrase = 'LanguageEvaluationPhrase',
  LanguageEvaluationTemplate = 'LanguageEvaluationTemplate',
}
export const LanguageEvaluationView = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = React.useState<LanguageEvaluationTabItems>(
    LanguageEvaluationTabItems.LanguageEvaluationPhrase,
  );

  const onPhraseTabClick = () => {
    setSelectedTab(LanguageEvaluationTabItems.LanguageEvaluationPhrase);
  };

  return (
    <Box minHeight="644px">
      <Tabs colorScheme="blue">
        <TabList borderWidth={0} px={2} borderBottomWidth="0.5px" borderBottomColor="#ECEFF1" backgroundColor="white">
          <Tab
            onClick={() => onPhraseTabClick()}
            data-testid="phraseTab"
            py={3}
            fontSize="sm"
            _selected={{ fontWeight: '700', borderColor: 'blue.default', color: 'blue.default' }}
            _focus={{ boxShadow: 'none' }}
          >
            {t('administration:languageEvaluation.phrases')}
          </Tab>
          <Tab
            onClick={() => setSelectedTab(LanguageEvaluationTabItems.LanguageEvaluationTemplate)}
            data-testid="templateTab"
            p={0}
            fontSize="sm"
            _selected={{ fontWeight: '700', borderColor: 'blue.default', color: 'blue.default' }}
            _focus={{ boxShadow: 'none' }}
          >
            {t('administration:languageEvaluation.template')}
          </Tab>
        </TabList>

        <TabPanels borderRadius={5}>
          <TabPanel p={0}>
            <Box>{selectedTab === LanguageEvaluationTabItems.LanguageEvaluationPhrase && <LanguageEvaluationPhrasesView />}</Box>
          </TabPanel>
          <TabPanel p={0}>
            <Box>
              {selectedTab === LanguageEvaluationTabItems.LanguageEvaluationTemplate && <LanguageEvaluationTemplateView />}
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
