import { Input, FormControl, FormErrorMessage } from '@chakra-ui/core';
import React from 'react';
import theme from '../../../../../styles/customTheme';
import { SectionSchema } from '../formBuilderSchema';
import ErrorWrapper from '../../errorHandling/ErrorWrapper';
import useErrorHandling from '../../useErrorHandling';
import { useTranslation } from 'react-i18next';

interface SectionNameInputProps {
  name: string;
  sectionIndex: number;
  isAdvancedSection: boolean;
  setFormSchema: (value: React.SetStateAction<SectionSchema[]>) => void;
  path: (string | number)[];
}

const SectionNameInput = React.memo(({ name, sectionIndex, isAdvancedSection, setFormSchema, path }: SectionNameInputProps) => {
  const { t } = useTranslation('hrFormTemplate');
  const [sectionName, setSectionName] = React.useState(name);
  const [isSectionNameAlreadyAvailable, setIsSectionNameAlreadyAvailable] = React.useState(false);

  React.useEffect(() => {
    setSectionName(name);
  }, [name]);

  const { validateError, error, hasError, inputRef } = useErrorHandling({
    path,
    valdationFor: 'section_name',
    onValidation: (value) => value,
  });

  function handleSectionNameChange(newSectionName: string) {
    if (name === newSectionName) return;
    setSectionName(newSectionName);
    setFormSchema((prev) => {
      const isDuplicateSectionName = prev?.some((section, index) => section.section === newSectionName && index !== sectionIndex);
      setIsSectionNameAlreadyAvailable(isDuplicateSectionName);

      return prev;
    });
  }

  function handleSectionNameBlur() {
    if (name === sectionName) return;

    validateError(sectionName, (value) => {
      setFormSchema((prev) => {
        if (isSectionNameAlreadyAvailable) return prev;

        return [...prev]?.map((section, index) => {
          if (sectionIndex === index) {
            return {
              ...section,
              fields: section.fields?.map((field) => ({ ...field, section: value as string })),
              section: sectionName,
            };
          }
          return section;
        });
      });
    });
  }

  const formatLabel = (field: string): string => {
    // if (isAdvancedSection) return field;
    const words = field?.match(/[A-Z]?[a-z]+|[0-9]+/g);
    return (
      words
        ?.map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word.toLowerCase()))
        .join(' ') ?? ''
    );
  };

  return (
    <FormControl isInvalid={isSectionNameAlreadyAvailable}>
      <ErrorWrapper ref={inputRef} error={error?.message || ''} hasError={isSectionNameAlreadyAvailable || hasError}>
        <Input
          variant="unstyled"
          size="sm"
          fontWeight="bold"
          borderRadius={theme.radii.md}
          paddingX={1}
          paddingY={0}
          placeholder={t('formBuilder.sectionInputPlaceholder')}
          color={isSectionNameAlreadyAvailable ? theme.colors.red[500] : '#4A5568'}
          isDisabled={isAdvancedSection}
          value={isAdvancedSection ? formatLabel(sectionName) : sectionName}
          onChange={(e) => handleSectionNameChange(e.target.value)}
          onBlur={handleSectionNameBlur}
          width="100%"
          isTruncated
          ref={inputRef}
          _focus={{
            fontWeight: 'normal',
          }}
        />
      </ErrorWrapper>
      <FormErrorMessage marginTop={0} fontSize="xs">
        {t('formBuilder.sectionNameExistsMessage')}
      </FormErrorMessage>
    </FormControl>
  );
});

export default SectionNameInput;
